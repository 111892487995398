<div id="main_view">
  <app-ashkenazim *ngIf="pageName === 'ashkenazim'" [isLang]="isLang"></app-ashkenazim>
  <app-feminist *ngIf="pageName === 'feminist'" [isLang]="isLang"></app-feminist>
  <app-liberal *ngIf="pageName === 'liberal'" [isLang]="isLang"></app-liberal>
  <app-mizrahim *ngIf="pageName === 'mizrahim'" [isLang]="isLang"></app-mizrahim>
  <app-orthodox *ngIf="pageName === 'orthodox'" [isLang]="isLang"></app-orthodox>
  <app-romaniotes *ngIf="pageName === 'romaniotes'" [isLang]="isLang"></app-romaniotes>
  <app-sephardim *ngIf="pageName === 'sephardim'" [isLang]="isLang"></app-sephardim>
</div>


