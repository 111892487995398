<div id="search-result-view">
  <div id="search-result-container">
    <div id="search-result-list">
      <a (click)="back()">{{'BACK_TO_SEARCH'|transloco}}</a>
      <h1>{{'SEARCHRESULTS' | transloco}}</h1>
      <span *ngIf="param && hasFilter" style="margin: 0;">Filter:
        <span *ngFor="let map of param.filterMap; let $index=index;">
          <span *ngFor="let field of map;let $index=index;" style="margin: 0;">{{field | transloco}}
            <span *ngIf="$index < map.length -1" style="margin: 0;">/
            </span>
          </span>
        </span>
      </span>

      <h2 style="padding: 0">
        <span *ngIf="param && param.firstSearchInputValue">{{param.firstSearchInputValue}}</span>
        <span *ngIf="param && param.secondSearchInputValue && operator == '+'"> & </span>
        <span *ngIf="param && param.secondSearchInputValue && operator == '|'"> <> </span>
        <span *ngIf="param && param.secondSearchInputValue && operator == null">&nbsp;</span>
        <span *ngIf="param && param.secondSearchInputValue">{{param.secondSearchInputValue}}</span>
      </h2>

      <!--- No Search Results -->
      <div *ngIf="0 === mynSearchResultList.length">
        {{'NO_SEARCHRESULTS' | transloco}}
      </div>

      <!--- Search Results -->
      <ul>
        <li *ngFor="let myn of mynSearchResultList;let $index=index;">

          <!-- list item header -->
          <a class="default-pointer list-item-link"
             [routerLink]="['/myn-detail']"
             [queryParams]=" {tab:'masterdata', identifier: myn.identifier}">
            <span class="default-pointer list-item-header">{{$index + 1 }}. {{myn.displayName}}</span>
          </a>
          <div class="clear"></div>

          <!-- highlighted search result fragments -->
          <ul *ngFor="let map of myn.highlightMap;let $index=index;">
            <span (click)="openTabViaProperty(myn, map.name)"
                  class="result-property-label"
                  *ngIf="map.name !== 'VALUELIST' ||
                         (map.name === 'VALUELIST' &&
                            null != map.valueList &&
                            0    !== map.valueList.length &&
                            '.'  !== map.valueList[0] )">
                {{ map.name | transloco }}:
            </span>
            <span class="result-property-value">
              <li *ngFor="let valueString of map.valueList; let $index=index;">
                  <span [innerHtml]="valueString"></span>
              </li>
            </span>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
