import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import { WaiSettingsService } from "../../../components/wai-menu/wai-settings.service";
import {ActivatedRoute, Router, Scroll} from "@angular/router";
import { MynService } from "../../../service/myn/myn-service";
import { MynDetailViewmodel } from "./myn-detail-viewmodel";
import { environment } from "../../../../environments/environment";
import {filter} from "rxjs/operators";

@Component({
  selector: 'myn-person-detail',
  templateUrl: './myn-detail.component.html',
  styleUrls: ['./myn-detail.component.css']
})
export class MynDetailComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @ViewChild('masterTabContent') masterTab: ElementRef | undefined;
  @ViewChild('mediaTabContent') mediaTab: ElementRef | undefined;

  img: any;
  mynContainer: any;
  audioList: any;
  currentTrack: any;
  selectedTab: string;
  storageUrl = environment.storageUrl;
  imageUrl: string = environment.imageUrl;
  previousDictKey: string | undefined;
  params: any;
  constructor(private mynService: MynService,
              private mynDetailViewModel: MynDetailViewmodel,
              private settingsService: WaiSettingsService,
              private router: Router,
              private renderer: Renderer2,
              private activatedRoute: ActivatedRoute) {
    let settings = this.settingsService.getSettings();
    this.settingsService.setSettings(settings);

    this.params = this.activatedRoute.snapshot.queryParams;
    let id = this.params.identifier;
    this.selectedTab = this.params.tab;
    let selectedMynContainer = this.mynDetailViewModel.getSelectedMynContainer();
    if(selectedMynContainer && id === selectedMynContainer.identifier) {
      this.mynContainer = selectedMynContainer;
    }
    let that = this;
    this.mynService.getMynContainerViaIdentifier(this.params.identifier, function (result: any) {
      for(let i = 0; i < result.length; i++){
        if(result[i].identifier === id){
          that.mynContainer = result[i];
          const lastName = that.mynContainer.cantor.lastName
          that.previousDictKey = lastName[0];
          console.log(that.previousDictKey);
          that.mynDetailViewModel.setSelectedMynContainer(that.mynContainer);
        }
      }
    });
  }

  scrollWhenAppeared(anchor: string, scrollContainer: any, scrollOffsetFound: boolean): void {
    if(!scrollOffsetFound){
      let elm = document.getElementById(anchor)
      if (elm instanceof HTMLDivElement || elm instanceof HTMLParagraphElement){
        let textFieldChildList = Array.from(elm.querySelectorAll('span, li'));
        let label = elm.getElementsByTagName('span')[0];

        scrollOffsetFound =true;
        scrollContainer.scrollTop = elm.offsetTop + 250;
        setTimeout(() => {
          label.setAttribute("class", "colored-label " + label.getAttribute("class"));

          for (let textField of textFieldChildList) {
            if(this.params.s1 && textField.innerHTML && textField.innerHTML.toLowerCase().includes(this.params.s1.toLowerCase())){
              const startIdx = textField.innerHTML.toLowerCase().indexOf(this.params.s1.toLowerCase());
              const highlightTerm = textField.innerHTML.substring(startIdx, startIdx + this.params.s1.length);
              textField.innerHTML = textField.innerHTML.replace(highlightTerm, '<em>' + highlightTerm + '</em>');
            }
            if(this.params.s2 && textField.innerHTML && textField.innerHTML.toLowerCase().includes(this.params.s2.toLowerCase())){
              const startIdx = textField.innerHTML.toLowerCase().indexOf(this.params.s2.toLowerCase());
              const highlightTerm = textField.innerHTML.substring(startIdx, startIdx + this.params.s2.length);
              textField.innerHTML = textField.innerHTML.replace(highlightTerm, '<em>' + highlightTerm + '</em>');
            }
          }
        });
      } else{
        setTimeout(() => {
          console.log(elm);
          this.scrollWhenAppeared(anchor, scrollContainer, scrollOffsetFound);
        }, 10);
      }
    }

  }

  ngAfterViewChecked(): void {
  }

  ngAfterViewInit(): void {
    let scrollOffsetFound = false
    const scrollContainer = document.getElementById('person_view');
    this.router.events
        .pipe(filter((e: any) => e instanceof Scroll && null != e.anchor))
        .subscribe((e: Scroll) => {
          if (null != e.anchor) {
            const anchor = e.anchor;
            setTimeout(() => {
              this.scrollWhenAppeared(anchor, scrollContainer, scrollOffsetFound);
            });

          }
        });
  }

  ngOnInit(): void {
  }

  getLabelClassViaPropertyValue(value: string) {
    if(this.notEmptyOrNull(value)){
      return 'label';
    }
    else{
      return 'inactive';
    }
  }

  notEmptyOrNull(value: any) {
    let result = true;
    if(!(typeof value !== 'undefined' && value))
    {
      result = false;
    }
    else {
      if(Array.isArray(value) && value.length === 0){
        result = false;
      }
      if(typeof value == 'string' && value.trim().length === 0){
        result = false;
      }
    }
    return  result;
  }

  putIntoBasket(mynContainer: any) {
    this.mynService.putIntoBasket(mynContainer.identifier);
  }

  showTabContent(tabContentId: string) {
    this.mynDetailViewModel.setSelectedTab(tabContentId);
    let tabId = tabContentId.split('-')[0];
    this.selectedTab = tabId;
    this.router.navigate(['/myn-detail'], { queryParams: {identifier: this.mynContainer.identifier,  tab: tabId }});
  }

  // endregion public functions

  showCtx($event: any): boolean|any {
    if('nodownload' === $event.currentTarget.controlsList || 'nodownload' === $event.currentTarget.controlsList.value){
      return false;
    }
    return $event;
  }
}
