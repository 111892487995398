<div id="enzyklopedia_view">
  <div id="enzyklopedia-data">

    <!-- DE -->
    <div *ngIf="isLang('de')">
      <h2>Sephardim</h2>
      <p>
        Sepharad (ספרד) ist die hebräische Bezeichnung für die Iberische Halbinsel.
        Als Sephardim werden dementsprechend die Jüdinnen und Juden und ihre Nachkommen bezeichnet,
        die vor ihrer Vertreibung ab 1492 auf der Iberischen Halbinsel lebten. Die sephardische Traditionslinie umfasst kulturelle,
        ideelle, religionsgesetzliche, soziale, liturgische und religionspraktische Merkmale.
        Der Begriff „sephardisch“ wird oft irrtümlich auch für andere nichtaschkenasische jüdische Gruppen (Mizrahim) verwendet.
      </p><p>
      Das Judentum der iberischen Halbinsel erlebte bis zum 12. Jahrhundert unter der arabischen Herrschaft seine Blüte.
      Infolge der Vertreibung der Juden aus Spanien und Portugal durch die Spanische Inquisition ab 1492 floh eine große Zahl
      von Sephardim in den Mittelmeerraum, das Osmanische Reich, nach Nordafrika sowie Richtung Norden nach Frankreich und in die Niederlande.
      Mit ihnen gelangte ihre Sprache, das Judenspanische, ebenfalls in die Emigration.
    </p><p>
      Zu den musikalischen Traditionen des sephardischen Judentums gehören beispielsweise in syrischen,
      marokkanischen und türkischen Gemeinden die Bakkashot, das Singen von Hymnen und Gebeten am Schabbat nach Mitternacht.
      In der sephardischen Tradition ist der Übergang vom Säkularen zum Sakralen fließend. Beispielsweise gingen Melodien einiger judenspanischer
      weltlicher Gesänge (›Coplas‹ und ›Canticas‹) in den synagogalen Gesang ein. Auch die Übernahme säkularer Melodien aus dem nichtjüdischen Umfeld
      für religiöse Gesänge war und ist eine übliche Praxis.
      In den jüdischen Gemeinden in muslimischen Ländern folgen die Synagogengesänge in der Melodik dem ›Makam‹-System, den Modi der arabischen und türkischen Musik.
    </p><p>
      Autorin: Martha Stellmacher
    </p>
      <h5>Literatur</h5>
      <p>Georg Bossong: Die Sepharden. Geschichte und Kultur der spanischen Juden, München : C. H. Beck 2008.</p>
      <h5>Audio</h5>
    </div>

    <!-- EN -->
    <div *ngIf="isLang('en')">
      <h2>Sephardi Jews</h2>
      <p>Sepharad (ספרד) is the Hebrew name for the Iberian Peninsula.
        Accordingly, the Jews and their descendants who lived on the Iberian Peninsula before their expulsion from 1492 are called Sephardim.
        The Sephardic tradition includes cultural, ideological, religious-legal, social, liturgical and religious-practical characteristics.
        The term "Sephardic" is often mistakenly used for other non-Ashkenazi Jewish groups (Mizrahim).
      </p><p>
      Judaism in the Iberian Peninsula flourished under Arab rule until the 12th century.
      As a result of the expulsion of the Jews from Spain and Portugal by the Spanish Inquisition in 1492, a large number of Sephardim fled to
      other Mediterranean countries, such as the Ottoman Empire, North African countries, to the north of France and to the Netherlands.
      With them, they took their language, the Spanish of the Jews.
    </p><p>
      The musical traditions of Sephardic Jewry in Syrian, Moroccan and Turkish communities include the Bakkashot,
      singing hymns and prayers on Shabbat after midnight. In the Sephardic tradition, the transition from the secular to the sacred is fluid.
      For example, melodies of some Jewish Spanish secular songs ('Coplas' and 'Canticas') were incorporated into synagogue singing.
      The adoption of secular melodies from the non-Jewish environment for religious chants was and is also a common practice.
      In the Jewish communities in Muslim countries, the melodic synagogue chants follow the 'Makam' system, e.g. the modes of Arab and Turkish music.
    </p><p>
      Author: Martha Stellmacher
    </p>
      <h5>Literature:</h5>
      <p>Georg Bossong: Die Sepharden. Geschichte und Kultur der spanischen Juden, München : C. H. Beck 2008.</p>
      <h5>Audio:</h5>
    </div>
  </div>
</div>
