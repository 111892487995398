<div id="main_view" class="fade-in-up">

  <div id="search_view">
    <div id="search-data">
      <h1 class="index-title">Adding our voices (AOV)</h1>
      <h6 class="subtitle">Jewish Feminist and Gender-Inclusive Music Archive</h6>
      <div id="tab-container">
        <div class="tab link-box" style="float: left;">
          <button class="tab-button"
                  (click)="showTab('aovidx-tab-content')"
                  [routerLink]="['/aov']"
                  [queryParams]="{tab:'aovidx', key: 'A'}" queryParamsHandling="merge">
            <span [ngClass]="[
                    tabID === 'aovidx' ? 'tab selected-tab' : 'tab',
                    settingsService.settings.arrowOption === 'ARROW_OPTION_WHITE' ? '.custom-pointer-white' : settingsService.settings.arrowOption === 'ARROW_OPTION_BLACK' ? 'custom-pointer-black' : ''
                  ]"
                  id="persondict">
              {{'INDEX' | transloco}}
            </span>
          </button>
        </div>
        <span class="index-separator">|</span>
        <div class="tab link-box" style="float: left;padding-left: 20px;">
          <button class="tab-button" (click)="showTab('simplesearch-tab-content')" [routerLink]="['/aov']" [queryParams]="{tab: 'simplesearch'}" queryParamsHandling="merge">
            <span id="simplesearch"
                  [ngClass]="tabID === 'simplesearch' ? 'tab selected-tab' : 'tab'">{{'SIMPLE_SEARCH' | transloco}}</span>
          </button>
        </div>
        <span class="index-separator expertsearch">|</span>
        <div class="tab link-box expertsearch" style="float: left;">
          <button class="tab-button" (click)="showTab('expertsearch-tab-content')" [routerLink]="['/aov']" [queryParams]="{tab: 'expertsearch'}" queryParamsHandling="merge">
            <span id="expertsearch" [ngClass]="tabID === 'expertsearch' ? 'tab selected-tab' : 'tab'">{{'EXTENDED_SEARCH' | transloco}}</span>
          </button>
        </div>
        <div class="clear"></div>
      </div>

      <div id="content-tabs">
        <div id="simplesearch-tab-content" *ngIf="'simplesearch-tab-content' === tabContentID">
          <aov-simple-search [setSearchResult]="setSearchResult"></aov-simple-search>
        </div>
        <div id="expertsearch-tab-content" *ngIf="'expertsearch-tab-content' === tabContentID">
          <aov-expert-search [setSearchResult]="setSearchResult"></aov-expert-search>
          <div class="clear"></div>
        </div>
        <div id="persondict-tab-content" *ngIf="'aovidx-tab-content' === tabContentID">
          <aov-index [isLang]="isLang" ></aov-index>
        </div>
      </div>
    </div>
  </div>

</div>


