import {Component, Input, OnInit} from '@angular/core';
import {EncyclopediaComponent} from "../../encyclopedia.component";

@Component({
  selector: 'app-sephardim',
  templateUrl: './sephardim.component.html',
  styleUrls: ['./sephardim.component.css']
})
export class SephardimComponent implements OnInit {

  @Input() isLang!: Function;
  constructor() {}

  ngOnInit(): void {
  }

}
