<div id="enzyklopedia_view" ng-controller="SearchController as search">
  <div id="enzyklopedia-data">
    <div *ngIf="isLang('de')">
      <h2>Liberales Judentum</h2>
      <p>
        „Liberal“ ist die Bezeichnung für eine Strömung des Judentums, die in den USA auch progressive Judaism (progressives Judentum)
        oder Reform Judaism (Reformjudentum) genannt wird. Den unterschiedlichen Gruppen ist die Einstellung gemein, dass religiöser
        Wandel legitim ist und Glaubensüberzeugungen und –praxis an an die Bedürfnisse der Gläubigen zeitgemäß angepasst werden können.
      </p><p>
      Das liberale Judentum entstand vor dem Hintergrund der Emanzipation, der Aufklärung und zunehmenden Säkularisierung im 18. und 19. Jahrhundert in Europa,
      die die jüdischen Gemeinden umwälzten.
      Die Reformideen gehen unter anderem auf die Rabbiner Abraham Geiger (1810-1874)
      und Samuel Holdheim (1806-1860) zurück und führten zu großen innerjüdischen Konflikten und ab der zweiten Hälfte
      des 19. Jahrhunderts zur institutionellen Spaltung von jüdischen Gemeinden.
    </p><p>
      Zu den liturgischen Reformen gehörte die Kürzung des Gottesdienstes, die Einführung einer Predigt und einiger Gebete in der Landessprache,
      Gebetbücher waren nicht mehr nur Hebräisch, sondern boten eine Übersetzung der Gebete. Außerdem wurde die Orgel zur Begleitung des Gesangs
      in der Synagoge befürwortet, der Gemeindegesang teilweise durch den Gesang von Knaben- oder gemischten Chören ersetzt.
      Zu den Komponisten, die im 19. Jahrhundert Musik für die veränderten Bedürfnisse der Synagoge im zeitgenössischen Stil komponierten,
      gehörten beispielsweise Salomon Sulzer (Wien), Louis Lewandowski (Berlin), Samuel Naumbourg (Paris), Moritz Deutsch (Breslau/Wrocław) und David Rubin (Prag).
    </p><p>
      Autorin: Martha Stellmacher
    </p>
      <h5>Literatur:</h5>
      <p>Michael A. Meyer: Antwort auf die Moderne. Geschichte der Reformbewegung im Judentum, Wien u.a.: Böhlau, 2000.</p>

      <h5>Audio:</h5>
      <p>CD Die Musiktradition der jüdischen Reform-Gemeinde zu Berlin, Tel Aviv: Feher Jewish Music Center, Beth Hatefutsoth 1997</p>
    </div>
    <div *ngIf="isLang('en')">
      <h2>Liberal Judaism</h2>
      <p>
        "Liberal" is the term for a trend of Judaism, which in the USA is also called progressive Judaism or Reform Judaism.
        The different groups share the attitude that religious change is legitimate and that beliefs and practices can be
        adapted to the needs of the faithful in a contemporary manner.
      </p><p>
      Liberal Judaism emerged against the background of emancipation, the Enlightenment and increasing secularisation in Europe in the 18th and 19th centuries,
      which circulated between and inside of the Jewish communities. Among others, the reform ideas go back to the
      rabbis Abraham Geiger (1810-1874) and Samuel Holdheim (1806-1860) and led to great internal Jewish conflicts
      and from the second half of the 19th century to the institutional division of Jewish communities.
    </p><p>
      Among the liturgical reforms were the shortening of the service, the introduction of a sermon and some prayers in the national language.
      Prayer books were no longer written only in Hebrew, but also offered a translation of the prayers.
      In addition, the organ was advocated to accompany the singing in the synagogue,
      the parish singing was partially replaced by the singing of boys' or mixed choirs.
      To meet the changing needs of the synagogue in the contemporary style of the 19th century,
      composers wrote music in line with demand.
      Among these composers were Salomon Sulzer (Vienna), Louis Lewandowski (Berlin), Samuel Naumbourg (Paris), Moritz Deutsch (Breslau/Wrocław) and David Rubin (Prague).
    </p><p>
      Author: Martha Stellmacher
    </p>
      <h5>Literature:</h5>
      <p>Michael A. Meyer: Antwort auf die Moderne. Geschichte der Reformbewegung im Judentum, Wien u.a.: Böhlau, 2000.</p>

      <h5>Music:</h5>
      <p>CD Die Musiktradition der jüdischen Reform-Gemeinde zu Berlin, Tel Aviv: Feher Jewish Music Center, Beth Hatefutsoth 1997</p>
    </div>
  </div>
</div>
