import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit {

  pageName: string;
  selectedLanguage: string;
  isLang: Function;
  constructor(private route: ActivatedRoute, private translocoService: TranslocoService) {
    this.selectedLanguage = this.translocoService.getActiveLang();
    this.pageName = this.route.snapshot.data['page'];

    this.isLang = (code: string) => {
      let lang = this.translocoService.getActiveLang();
      return lang === code;
    };
  }

  ngOnInit(): void {}
}
