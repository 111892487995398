<!-- BEGIN RIGHT -->
<div id="right-sidebar" class="">
  <div id="de-button-box" class="right-sidebar-button" [class.active-lang-button]="isLang('de')">
    <div id="de-button-container">
      <a id="de-button" class="r-btn" (click)="changeLanguage('de')">DE
      </a>
    </div>
  </div>

  <div id="en-button-box" class="right-sidebar-button" [class.active-lang-button]="isLang('en')">
    <div id="en-button-container">
      <a id="en-button" class="r-btn" (click)="changeLanguage('en')">EN
      </a>
    </div>
  </div>
</div>
<!-- END RIGHT -->
