import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ApplicationStateService} from "../../../service/application-state.service";
import {WaiSettingsService} from "../../wai-menu/wai-settings.service";
import {Settings} from "../../../model/Settings";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.css']
})
export class LeftSidebarComponent implements OnInit, OnDestroy {
  public routeUrl: string | undefined;
  public settings: Settings;

  private _subscription_route: Subscription;

  @Input() lang!: string;
  constructor(private appContext: ApplicationStateService, private settingsService: WaiSettingsService) {
  this.settings = settingsService.getSettings();
  this._subscription_route = this.appContext.execRouteChange.subscribe((value) => {
    this.routeUrl = value;
    console.log(value);
  });

}
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._subscription_route.unsubscribe();
  }

}
