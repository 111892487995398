import { Component } from '@angular/core';
import {TranslocoService} from "@ngneat/transloco";
import {ActivatedRoute, Params, Router} from "@angular/router";

@Component({
  selector: 'aov-landing',
  templateUrl: './aov-landing.component.html',
  styleUrls: ['./aov-landing.component.css']
})
export class AovLandingComponent {

  isLang!: Function;
  selectedLanguage: string;
  constructor(
      private translocoService: TranslocoService,
      private activatedRoute: ActivatedRoute,
      private router: Router
  ) {
    this.selectedLanguage = this.translocoService.getActiveLang();
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const parameter = params['lang'];
      if (!parameter && this.selectedLanguage) {
        const queryParams: any = {};
        queryParams['lang'] = this.selectedLanguage;
        this.router.navigate([], {
          queryParams: queryParams,
          queryParamsHandling: 'merge'
        });
      }
    });

    this.isLang = (code: string) => {
      this.selectedLanguage = this.translocoService.getActiveLang();
      return this.selectedLanguage === code;
    };
  }
}
