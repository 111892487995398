import { Component, OnInit } from '@angular/core';
import { TranslocoService } from "@ngneat/transloco";
import { ActivatedRoute, Params, Router } from "@angular/router";

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.css']
})
export class ImprintComponent implements OnInit {

  language: string;
  constructor(
      private translocoService: TranslocoService,
      private activatedRoute: ActivatedRoute,
      private router: Router
  ) {
    this.language = this.translocoService.getActiveLang();

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const parameter = params['lang'];
      if (!parameter && this.language) {
        const queryParams: any = {};
        queryParams['lang'] = this.language;
        this.router.navigate([], {
          queryParams: queryParams,
          queryParamsHandling: 'merge'
        });
      }});
  }

  ngOnInit(): void {
  }

  isLang(lang: string){
    return lang === this.translocoService.getActiveLang();
  }

  openMail() {
    window.location.href = 'mailto:ezjm@hmtm-hannover.de';
  }


}
