<div id="terms_view">
    <h3>{{'TERMS' | transloco}}</h3>
    <div class="terms-data" *ngIf="isLang('de')">

        <p>Die Initiative 'Adding Our Voices' fördert die Verwendung dieser Musik mit folgendem Vorbehalt:
            Eine vorherige Genehmigung der Künstler:in/Komponist:in/Texter:in/Liturgist:in, ihres:seines Nachlasses,
            ihres:seines Managers oder ihres:seines Musikverlags ist erforderlich, um diese Musik in irgendeiner
            Form auf dieser Website für kommerzielle Zwecke zu verwenden. Weder 'Reclaiming Judaism' noch
            die Hochschule für Musik, Theater und Medien Hannover haben das Recht, eine solche Nutzung zu genehmigen.
            Dieser Vorbehalt umfasst Online- oder persönliche Aufführungen mit Eintrittskarten, Veröffentlichungen, Lehrpläne,
            Filme oder Dokumentationen, ist aber nicht auf diese beschränkt. Bei Nichteinhaltung dieses Vorbehaltes sind
            die eingenommenen Gelder innerhalb von 10 Tagen nach Erhalt an die jeweilige
            verantwortliche Partei (Künstler, Komponist, Texter, Liturgen, deren Nachlass, Manager, Musikverlag) zu zahlen.
        </p>

        <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen,
            soweit nicht anders gekennzeichnet, der Lizenz Creative Commons 4.0 BY-NC-SA
            (<a href="https://creativecommons.org/licenses/by-nc-sa/4.0/deed.de">
                https://creativecommons.org/licenses/by-nc-sa/4.0/deed.de
            </a>).
            Beiträge dürfen weiterverwendet werden,
            müssen aber namentlich gekennzeichnet werden, eine Weitergabe darf nur unter denselben Bedingungen erfolgen.
            Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
            der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
            Zustimmung der:des jeweiligen Autor:in:s bzw. Ersteller:in:s. Downloads und Kopien dieser
            Seite sind im Rahmen der CC-Lizenz, nicht aber für den kommerziellen Gebrauch gestattet.
        </p>

        <p>Die Betreiber:innen der Seiten sind bemüht, stets die Urheberrechte anderer zu beachten bzw. auf selbst erstellte sowie lizenzfreie Werke zurückzugreifen.
        </p>


    </div>
    <div id="imprint-data" *ngIf="isLang('en')">
        <p>The Adding Our Voices initiative encourages the use of this music with this caveat:
            Prior authorization from the artist/composer/lyricist/ liturgist, their estate, manager,
            or music publication firm is required to use this music in any of its forms on this site for any income-generating purpose. Neither Reclaiming Judaism nor Hanover University for Music, Drama and Media have the right to authorize such use. This caveat includes but is not limited to ticketed online or in-person performances, publications, curricula, films or documentaries.
            If not adhered to, any funds earned/raised are due within 10 days of receipt to the relevant responsible
            party (artist, composer, lyricist, liturgist, their estate, manager, music publication firm) above.</p>

        <p>The content and works on these pages created by the site operators are subject to the Creative Commons 4.0
            BY-NC-SA license (<a href="https://creativecommons.org/licenses/by-nc-sa/4.0/deed.en">https://creativecommons.org/licenses/by-nc-sa/4.0/deed.en</a>), unless stated otherwise.
            Contributions may be reused, but must be identified by name and may only be passed on under the same conditions.
            Third party contributions are marked as such. Reproduction, processing, distribution and any kind of
            exploitation outside the limits of copyright law require the written consent of the respective author or
            creator. Downloads and copies of this page are permitted under the CC license, but not for commercial use.
        </p>
        <p>The operators of the pages strive to always respect the copyrights of others and to use self-created and license-free works.
        </p>
    </div>
</div>
