import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-romaniotes',
  templateUrl: './romaniotes.component.html',
  styleUrls: ['./romaniotes.component.css']
})
export class RomaniotesComponent implements OnInit {

  @Input() isLang!: Function;
  constructor() { }

  ngOnInit(): void {
  }

}
