<div id="search-result-view">
  <div id="search-result-container">
    <div id="search-result-list">
      <a (click)="back()">{{'BACK_TO_SEARCH'|transloco}}</a>
      <h1>{{'SEARCHRESULTS' | transloco}}</h1>
      <span *ngIf="param && hasFilter" style="margin: 0;">Filter:
        <span *ngFor="let map of param.filterMap; let $index=index;">
          <span *ngFor="let field of map;let $index=index;" style="margin: 0;">{{field | transloco}}
            <span *ngIf="$index < map.length -1" style="margin: 0;">/
            </span>
          </span>
        </span>
      </span>

      <h2 style="padding: 0">
        <span *ngIf="param && param.firstSearchInputValue">{{param.firstSearchInputValue}}</span>
        <span *ngIf="param && param.secondSearchInputValue && operator == '+'"> & </span>
        <span *ngIf="param && param.secondSearchInputValue && operator == '|'"> <> </span>
        <span *ngIf="param && param.secondSearchInputValue && operator == null">&nbsp;</span>
        <span *ngIf="param && param.secondSearchInputValue">{{param.secondSearchInputValue}}</span>
      </h2>

      <!--- No Search Results -->
      <div *ngIf="0 === aovSearchResultList.length">
        {{'NO_SEARCHRESULTS' | transloco}}
      </div>

      <!--- Search Results -->
      <ul>
        <li *ngFor="let aov of aovSearchResultList;let $index=index;">

          <!-- list item header -->
          <a class="default-pointer list-item-link"
             [routerLink]="['/aov-detail']"
             [queryParams]=" {tab:'masterdata', identifier: aov.identifier}" queryParamsHandling="merge">
            <span class="default-pointer list-item-header">{{$index + 1 }}. {{aov.displayName}}</span>
          </a>

          <!-- add to basket button -->
<!--          <div class="tooltip">-->
<!--            <a class="basket-button" (click)="putIntoBasket(this.aov)">-->
<!--              <svg class="svg-btn basket-icon" viewBox="0 0 24 24">-->
<!--                <path d="M3,2H6V5H3V2M6,7H9V10H6V7M8,2H11V5H8V2M17,11L12,6H15V2H19V6H22L17,11M7.5,22C6.72,22 6.04,21.55 5.71,20.9V20.9L3.1,13.44L3,13A1,1 0 0,1 4,12H20A1,1 0 0,1 21,13L20.96,13.29L18.29,20.9C17.96,21.55 17.28,22 16.5,22H7.5M7.61,20H16.39L18.57,14H5.42L7.61,20Z" />-->
<!--              </svg>-->
<!--              <span class="tooltiptext" style="left: 42px;">add to basket</span>-->
<!--            </a>-->
<!--          </div>-->

          <div class="clear"></div>

          <!-- highlighted search result fragments -->
          <ul *ngFor="let map of aov.highlightMap;let $index=index;">
            <span (click)="openTabViaProperty(aov, map.name)"
                  class="result-property-label"
                  *ngIf="map.name !== 'VALUELIST' ||
                         (map.name === 'VALUELIST' &&
                            null != map.valueList &&
                            0    !== map.valueList.length &&
                            '.'  !== map.valueList[0] )">
                {{ map.name | transloco }}:
            </span>
            <span class="result-property-value">
              <li *ngFor="let valueString of map.valueList; let $index=index;">
                  <span [innerHtml]="valueString"></span>
              </li>
            </span>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
