<div id="contact_page">

  <h1>{{'CONTACT' | transloco}}</h1>
  <p *ngIf="isLang('de')" style="position:relative;">
    Haben Sie Fragen, Anregungen oder Bitten? Kontaktieren Sie und über dieses Formular.
    Bitte nennen Sie Ihren Namen, eine Emailadresse und den Grund Ihres Anliegens, damit wir Ihre Anfrage möglichst rasch bearbeiten können.
    Vielen Dank!
  </p>
  <p *ngIf="isLang('en')" style="position:relative;">
    Do you have any questions, suggestions or requests? Contact us using this form.
    Please state your name, an email address and the purpose of your request so that we can process your inquiry as quickly as possible.
    Thank you very much!
  </p>
  <form [formGroup]="contactForm" (submit)="submit()" id="contact-form" style="position: relative;">

    <div>
      <input id="contact_name" class="input required" type="text" placeholder="{{'NAME' | transloco}}"
             formControlName="name"
             [ngClass]="settingsService.settings.backgroundColorOption === 'BACKGROUND_OPTION_BLACK' ? 'theme-color-dark' : 'theme-color-light'"
             required>
    </div>
    <div>
      <input id="contact_email" class="input required"
             formControlName="email"
             placeholder="{{'EMAIL' | transloco}}"
             [ngClass]="settingsService.settings.backgroundColorOption === 'BACKGROUND_OPTION_BLACK' ? 'theme-color-dark' : 'theme-color-light'"
             name="Email"
             required>
    </div>
    <div>
      <input id="contact_subject" class="input required" placeholder="{{ 'SUBJECT' | transloco}}"
             formControlName="subject"
             [ngClass]="settingsService.settings.backgroundColorOption === 'BACKGROUND_OPTION_BLACK' ? 'theme-color-dark' : 'theme-color-light'"
             name="Subject"
             required>
    </div>
    <div>
      <textarea id="blogInfo" class="textarea" placeholder="{{'CONTENT' | transloco}}"
                rows="4"
                name="Content"
                formControlName="content"
                [ngClass]="settingsService.settings.backgroundColorOption === 'BACKGROUND_OPTION_BLACK' ? 'theme-color-dark' : 'theme-color-light'">
      </textarea>
    </div>

    <button id="contact_send_button"
            class="send-btn border-dark default-pointer"
            [ngClass]="settingsService.settings.backgroundColorOption === 'BACKGROUND_OPTION_BLACK' ? 'theme-color-dark' : 'theme-color-light'"
            mat-flat-button
            color="primary"
            (click)="submit()">
      <span class="button_label">{{ 'SEND' | transloco}}</span>
    </button><div class="clean"></div>

  </form>

</div>
