import { Component, OnInit } from '@angular/core';
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-tradition',
  templateUrl: './tradition.component.html',
  styleUrls: ['./tradition.component.css']
})
export class TraditionComponent implements OnInit {

  language: string;
  constructor(private translocoService: TranslocoService) {
    this.language = this.translocoService.getActiveLang();
  }

  isLang(lang: string){
    return lang === this.translocoService.getActiveLang();
  }

  ngOnInit(): void {
  }

}
