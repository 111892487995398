<div id="tradition_view">
  <h1>Carry on Tradition</h1>

  <iframe width="800"
          height="450"
          src="https://www.youtube-nocookie.com/embed/LyneiwJNcFw?rel=0"
          frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
  <!--<video width="800" controls poster="../../content/images/videoplaceholder.png">-->
  <!--<source src="#" type="video/mp4">-->
  <!--Your browser does not support the video tag.-->
  <!--</video><br/>-->
  <!--<span style="font-size: 0.75rem;font-weight: 600">{{'TRADITION_VIDEO_DESCRIPTION'|translate}}</span><br/>-->
  <div *ngIf="isLang('de')">
    <br /><br />
    <p>Bitte helfen Sie uns mit weiteren Informationen und Materialien für Soundscape Synagoge:
      Besitzen Sie materielle oder immaterielle Zeugnisse von jüdisch-liturgischer/synagogaler Musik und/oder zu Kantoren und Kantorinnen,
      die Sie uns für die Datenbank zur Verfügung stellen möchten? Dazu zählen beispielsweise:</p>
    <ul class="carry-on-list">
      <li>Nachlässe</li>
      <li>Noten</li>
      <li>Briefe</li>
      <li>Zeitungsartikel</li>
      <li>(historische) Klangaufnahmen</li>
      <li>Fotografien</li>
      <li>Erinnerungen: Erinnern Sie sich an Melodien, die einst in Ihrer Gemeinde gesungen wurden,
        oder an eine/n Kantor/in in Ihrer Gemeinde? Wir freuen uns, wenn Sie diese Erinnerungen mit uns teilen.
      </li>
    </ul><br>
    <p>
      Oder können Sie Hinweise auf andere Personen oder Lagerorte geben, an die wir uns wenden können?
      Dann möchten wir Sie bitten, sich mit uns über das
      <a [routerLink]="'contact'">Kontaktformular</a> in Verbindung zu setzen.
    </p>
  </div>
  <div *ngIf="isLang('en')">
    <br /><br />
    <p>Please help us with further information and materials for Soundscape Synagoge:
      Do you have material or immaterial testimonies of Jewish-liturgical/synagogical music and/or of cantors
      you would like to make available to us for the database? These include, for example:</p>
    <ul class="carry-on-list">
      <li>estates</li>
      <li>scores / sheet music</li>
      <li>letters</li>
      <li>newspaper articles</li>
      <li>(historical) sound recordings</li>
      <li>photographs</li>
      <li>Memories: Do you remember melodies that were once sung in your community or a cantor in your community? We would be pleased if you shared these memories with us.
      </li>
    </ul><br>
    <p>Or can you provide information about other persons or storage locations to which we can turn? Then we would like to ask you to
      <a [routerLink]="'contact'">contact us</a>  via the contact form.</p>
  </div>
</div>
