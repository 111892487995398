<!-- BEGIN TOP -->
<div id="topbar" class="topbar" >
<div id="branding" class="branding link-box">
    <a [routerLink]="['/landing']">
      <h2>{{ 'HEADER' | transloco }}</h2>
    </a>
  </div>
  <div id="main_nav" class="">
    <div class="link-box link-box-left">
      <a [class.active]="routeUrl&&routeUrl.startsWith('/aov')"
         [routerLink]="['/aov-landing']">{{ 'AOV' | transloco}}
      </a>
    </div>
    <div *ngIf="showMyNusach" class="link-box link-box-left">
      <a [class.active]="routeUrl&&routeUrl.startsWith('/sosy')"
         [routerLink]="['/myn-landing']">{{ 'MYNUSACH' | transloco}}
      </a>
    </div>
    <div class="link-box link-box-left" *ngIf="routeUrl !== 'map'">
      <div class="link-box link-box-right">
        <a [class.active]="" [routerLink]="" class="svg-btn"
           [class.site-bg-dark-svg-btn]="settings.backgroundColorOption==='BACKGROUND_OPTION_BLACK'"
           (click)="showSettings()" style="top:4px;cursor:pointer;"><mat-icon>settings_accessibility</mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>
<app-wai-menu></app-wai-menu>
<!-- END TOP -->
