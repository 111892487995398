import { Component, OnInit } from '@angular/core';
import {TranslocoService} from "@ngneat/transloco";
import {ActivatedRoute, Params, Router} from "@angular/router";

@Component({
  selector: 'app-howto',
  templateUrl: './howto.component.html',
  styleUrls: ['./howto.component.css']
})
export class HowtoComponent implements OnInit {

  language: string;
  constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private translocoService: TranslocoService
  ) {
    this.language = this.translocoService.getActiveLang();
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const parameter = params['lang'];
      if (!parameter && this.language) {
        const queryParams: any = {};
        queryParams['lang'] = this.language;
        this.router.navigate([], {
          queryParams: queryParams,
          queryParamsHandling: 'merge'
        });
      }
    });
  }

  isLang(lang: string){
    return lang === this.translocoService.getActiveLang();
  }

  ngOnInit(): void {
  }

}
