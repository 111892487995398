<div id="enzyklopedia_view">
  <div id="enzyklopedia-data">
    <div *ngIf="isLang('de')">
      <h2>Aschkenasim</h2>
      <p>
        Aschkenas ((אשכנז bezeichnet die frühen Siedlungsgebiete von Juden in Nordwesteuropa, ursprünglich im Rheinland; später setzte sich die Verwendung des Begriffs
        als Bezeichnung für Deutschland durch. Als Aschkenasim werden die Jüdinnen und Juden bezeichnet,
        deren religiöse und kulturelle Wurzeln auf diese Siedlungen im deutschsprachigen Raum zurückgehen.
        Die aschkenasische Traditionslinie umfasst kulturelle, ideelle, religionsgesetzliche, soziale, liturgische und religionspraktische Merkmale.
      </p><p>
      Seit dem 11. Jahrhundert führten Verfolgungen zur Migration von aschkenasischen Juden in Richtung Osteuropa.
      Diese nahmen auch die mittelhochdeutsche Sprache mit, aus der sich dann die verschiedenen Dialekte des Jiddischen entwickelten.
      Durch Migrationsbewegungen ist das aschkenasische Judentum heute in Europa, Nord- und Südamerika, Israel, Australien und Südafrika verbreitet.</p>
      <p>
        Eine Besonderheit des aschkenasischen Synagogengesangs sind beispielsweise die „Misinai“-Melodien. Dabei handelt es sich um überlieferte Melodien,
        die zu feierlichen Ereignissen, vor allem zu den Hohen Feiertagen gesungen werden. Wahrscheinlich sind diese Weisen im Rheinland oder in Nordfrankreich
        zwischen dem 12. und 16. Jahrhundert entstanden, Aufzeichnungen finden sich jedoch erst sehr viel später.
        Zu den Misinai-Melodien gehören z.B. die Melodie des Kol nidre zum Versöhnungstag, dessen früheste bekannte Notation
        aus dem 18. Jahrhundert stammt, und die Melodie des Schlussgebets Alenu.
      </p><p>
      Autorin: Martha Stellmacher
    </p>

      <h5>Literatur:</h5>
      <p>
        Shira Shoenberg: Ashkenazim, in: Jewish Virtual Library <a href="https://www.jewishvirtuallibrary.org/ashkenazim">https://www.jewishvirtuallibrary.org/ashkenazim</a>.
      </p>
      <p>Pedrag Bukovec: Aschkenasische Juden im Europa der Frühen Neuzeit, Europäische Geschichte Online,
        <a href="http://ieg-ego.eu/de/threads/europa-unterwegs/juedische-migration/aschkenasische-juden-im-europa-der-fruehen-neuzeit/predrag-bukovec-aschkenasische-juden-im-europa-der-fruehen-neuzeit">
          http://ieg-ego.eu/de/threads/europa-unterwegs/juedische-migration/aschkenasische-juden-im-europa-der-fruehen-neuzeit/predrag-bukovec-aschkenasische-juden-im-europa-der-fruehen-neuzeit
        </a>, 3.12.2010
      </p><p>
      Eric Werner: A Voice Still Heard. The Sacred Songs of the Ashkenazic Jews, New York: Leo Baeck Institute, 1976.
    </p>

      <h6>Noten:</h6>
      <p>Philip V. Bohlman: The Folk Songs of Ashkenaz, Middleton, Wis.: A-R Editions 2001</p>
    </div>
    <div *ngIf="isLang('en')">
      <h2>Ashkenazim</h2>
      <p>
        Ashkenas ((אשכנז denotes the early settlement areas of Jews in northwestern Europe, originally in the Rhineland;
        later the use of the term became established as a term for Germany. Ashkenazim is the name given to Jews whose religious and cultural roots go back to these settlements in German-speaking countries.
        The Ashkenazic tradition includes cultural, ideological, religious-legal, social, liturgical and religious-practical characteristics.
      </p><p>
      Since the 11th century persecutions led to the migration of Ashkenazic Jews to Eastern Europe.
      They also took the Middle High German language with them, from which the various dialects of Yiddish developed.
      As a result of migration movements, Ashkenazic Judaism is now widespread in Europe, North and South America, Israel, Australia and South Africa.
    </p><p>
      A special feature of Ashkenazic synagogue singing are, for example, the "Misinai" melodies.
      These are traditional melodies that are sung at festive events, especially on High Holidays.
      These tunes probably originated in the Rhineland or in northern France between the 12th and 16th centuries, but records can only be found much later. Among the Misinai melodies are,
      for example, the melody of Kol nidre for the Day of Atonement, whose earliest known notation dates from the 18th century, and the melody of the final prayer Alenu.
    </p><p>
      Author: Martha Stellmacher
    </p>
      <h5>Literature:</h5>
      <p>
        Shira Shoenberg: Ashkenazim, in: Jewish Virtual Library <a href="https://www.jewishvirtuallibrary.org/ashkenazim">https://www.jewishvirtuallibrary.org/ashkenazim</a>.
      </p>
      <p>
        Pedrag Bukovec: Aschkenasische Juden im Europa der Frühen Neuzeit, Europäische Geschichte Online,
        <a href="http://ieg-ego.eu/de/threads/europa-unterwegs/juedische-migration/aschkenasische-juden-im-europa-der-fruehen-neuzeit/predrag-bukovec-aschkenasische-juden-im-europa-der-fruehen-neuzeit">
          http://ieg-ego.eu/de/threads/europa-unterwegs/juedische-migration/aschkenasische-juden-im-europa-der-fruehen-neuzeit/predrag-bukovec-aschkenasische-juden-im-europa-der-fruehen-neuzeit
        </a>, 3.12.2010
      </p><p>
      Eric Werner: A Voice Still Heard. The Sacred Songs of the Ashkenazic Jews, New York: Leo Baeck Institute, 1976.
    </p>

      <h6>Sheet Music:</h6>
      <p>Philip V. Bohlman: The Folk Songs of Ashkenaz, Middleton, Wis.: A-R Editions 2001</p>
    </div>
  </div>
</div>
