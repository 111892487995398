import { Injectable } from '@angular/core';
import { LocalStorageRefService } from "./local-storage-ref.service";
import { BehaviorSubject } from "rxjs";
import { Settings } from "../model/Settings";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private LS_SETTINGS_KEY: string = 'SOSY_SETTINGS';
  private _localStorage: Storage;

  // private _settings$ = new BehaviorSubject<Settings|null>(null)
  // public settings$ = this._settings$.asObservable()
  private LOCALSTORAGE_LANGUAGE_KEY: string = "SOSY_LANG";
  private LOCALSTORAGE_BASKET_KEY: string = 'basket';
  private LOCALSTORAGE_SHOW_MY_NUSACH: string = 'SOSY_SHOW_MYNUSACH';

  constructor(private _localStorageRefService: LocalStorageRefService) {
    this._localStorage = _localStorageRefService.localStorage
  }

  clearAllLocalStorage() {
    this._localStorage.clear()
    //this._settings$.next(null)
  }

  clearSettings() {
    this._localStorage.removeItem(this.LS_SETTINGS_KEY)
    //this._settings$.next(null)
  }

  getLanguage() {
    return this._localStorage.getItem(this.LOCALSTORAGE_LANGUAGE_KEY);
  }

  getBasket(): any[]|null {
    let _basket = null;
    let loadedBasket = this._localStorage.getItem(this.LOCALSTORAGE_BASKET_KEY);
    if(loadedBasket){
      _basket = JSON.parse(loadedBasket);
    }
    return _basket;
  }

  loadSettings() : Settings | null {
    let settings = null;
    const reloadedSettingsJson = this._localStorage.getItem(this.LS_SETTINGS_KEY);
    if(reloadedSettingsJson){
       settings = JSON.parse(reloadedSettingsJson);
    }
    return settings;
  }

  setBasket(val: any) {
    this._localStorage.setItem(this.LOCALSTORAGE_BASKET_KEY, JSON.stringify(val));
  }

  setSettings(settings: Settings) : void {
    const jsonData = JSON.stringify(settings)
    this._localStorage.setItem(this.LS_SETTINGS_KEY, jsonData)
  }

  setLanguage(language: string) {
    this._localStorage.setItem(this.LOCALSTORAGE_LANGUAGE_KEY, language);
  }

  getShowMyNusach(): string | null {
    return this._localStorage.getItem(this.LOCALSTORAGE_SHOW_MY_NUSACH);
  }
}
