<div id="detail-view">
  <div *ngIf="isLang('de')" class="detail-data">
      <h2>Adding Our Voices (AOV)</h2>
      <h6 class="subtitle">Jewish Feminist and Gender-Inclusive Music Archive</h6>
    <a class="brand-link-magenta default-pointer" [routerLink]="['/aov']" [queryParams]="{tab:'aovidx', key: 'A'}" queryParamsHandling="merge">Zum AOV-Archiv</a>
    <p>
        Das <b>"Jewish Feminist and Gender-Inclusive Music Archive: ADDING OUR VOICES" (AOV)</b> bietet freien Zugang zu
        hunderten ausgewählten beispielhaften musikalischen Liturgien, musikalischen Midrash und religiösen Liedern zur
        Verwendung im gesamten Spektrum des jüdischen Lebens. Das Europäische Zentrum für jüdische Musik der Hochschule
        für Musik, Theater und Medien Hannover dokumentiert in Zusammenarbeit mit der AOV-Initiative die Künstler:innen
        und deren musikalischen Aktivitäten, die sich um Fragen sozialer Gerechtigkeit und Gleichberechtigung drehen.
        Fachleute der gemeinnützigen Organisation "Reclaiming Judaism" arbeiteten mit Künstler:innen zusammen, und
        konnten dabei Spenden für die Archivierung von Musiken, Tondateien, Fotos und umfangreichen biografischen
        Informationen sicherstellen.
        Mitarbeiter:innen des Europäischen Zentrums für jüdische Musik an der Hochschule für Musik, Theater und Medien
        Hannover entwickelten, hosten und verwalten die technische Infrastruktur des Archivs, die Website und sorgen für
        deren Gestaltung.
    </p>
    <p>
        Feminismus im Sinne der AOV-Aktivist:innen bedeutet das Streben nach der Sicherung und Verwirklichung von
        Inklusion, Gleichheit, Freiheit, Gerechtigkeit für alle – angefangen bei denen, die sich als Frauen und Mädchen
        identifizieren, und nicht nur dort. Dementsprechend zielt die Musiksammlung der AOV-Initiative darauf ab,
        Gemeinschaften zu helfen, die geschlechtersensible, expansive und integrative jüdische Musik einladender und
        gerechter zu gestalten.
        Darüber hinaus richtet sich die Sammlung an Pädagog:innen, Wissenschaftler:innen und Künstler:innen, die sich
        mit der Geschichte jüdischer feministischer Musik befassen und jüdische Kultur, Religion, Musik, Geschichte und Gegenwart lehren.
    </p>
    <p>
        Inspiriert von der Frauenrechtsbewegung entstand die AOV-Initiative in den 1960er Jahren.
        Seitdem schaffen Künstler:innen kontinuerlich Musik, die eine transformative Kraft zur Überwindung der
        patriarchalen Hegemonie im Judentum und im jüdischen Leben sein will. Der Name „Adding Our Voices“ ist eine
        Reaktion auf die Inspiration der Künstler:innen, sich mit dem problematischen Prinzip von „Kolisha“
        auseinanderzusetzen, der Vorstellung, dass die weibliche Singstimme verführerisch sei, und von jüdischen Männern
        nicht gehört werden dürfe – ein Brauch, der den Zugang von Frauen stark einschränkte.
        Jüdische Frauen wurden dadurch vom Diskurs ausgeschlossen, und durften kaum kommunale und religiöse
        Führungspositionen bekleiden.
    </p>
    <p>
        Die Künstler:innen kommen aus allen Bereichen des jüdischen Lebens und sind Laien- und professionelle
        Komponist:innen, Liturgiker:innen und darstellende Künstler:innen; Unter ihnen sind Kantor:innen, Rabbiner:innen
        und andere jüdische Geistliche, Pädagog:innen und verschiedene Einzelpersonen, die Aspekte ihres persönlichen
        Lebens aus einer jüdisch-feministischen und geschlechterintegrierenden Perspektive betrachten.
        Das AOV-Archiv bietet neue und adaptierte Musikwerke für viele Aspekte des jüdischen Lebens.
        AOV umfasst auch Musik für Aspekte des menschlichen Lebens, die in der jüdischen Liturgie lange vermisst wurden,
        wie etwa das Einsetzen der Menstruation, Unfruchtbarkeit und Fehlgeburten, Rosch Chodesch,
        Bat und B-Mizwa, Coming-out, lesbische und schwule Hochzeiten sowie jüdische Frauen aus der Bibel,
        Talmud, sowie weitere Aspekte Jüdischer und weltlicher Geschichte. Zu den vielen Sprachen in AOV gehören Aramäisch,
        Englisch, Hebräisch (biblisches, talmudisches, modernes und nichtbinäres Hebräisch), Malayalam, Urdu, Jiddisch und Spanisch.
        Primäre Musikformen sind jüdische Musikliturgie, Lieder, Kantillation, musikalischer Midrasch, Oper und Musiktheater.
        Unter den Stilen: Mizrachi, Sephardi, Ashkenazi, Klezmer, Hip-Hop, Rap, Rock, Pop und Volksmusik, Rhythm & Blues,
        Reggae, Musiktheater, Oper, Jospel, Jazz, A Capella, Chor, Gesänge, feministisches Haftarot in
        traditioneller Manier und feministischen talmudischen Balladen.
    </p>
    <h3>DANKSAGUNGEN:</h3>
    <p>
        An das Reclaiming Judaism-Team bestehend aus Rabbinerin Dr. Goldie Mara Milgram, Rabbi Geela Rayzel Raphael,
        Mindy Rosenthal, und Cantor Anita Schubert,
        sowie an das Team des Europäischen Zentrums für Jüdische Musik der Hochschule für Musik,
        Theater und Medien Hannover um Prof. Dr. Sarah M. Ross und Samuel Mund.
    </p>
    <p>
      Bis Dezember 2023 wurden mehr als 30.000 Stunden beruflicher und ehrenamtlicher Arbeit in das Sammeln und
        Dokumentieren der AOV-Materialien, den Aufbau der Archivinfrastruktur, das Sammeln von Musik, das Kuratieren
        und die Notenvorbereitung sowie das Hochladen und Korrekturlesen des gesamten Materials investiert.
        Dementsprechend danken wir zusätzlich:
    </p>
    <p>
        Musikbearbeitung, Rezension, Transkription und Notensatz: Kantor Ron Fischman, Kantorin Anita Schubert, AOV Notenbarbeitung;
        und Dr. Joshua Fishbein, Didi King, Alexander Massey, Cantor Richard Nadel, Harry Style und Alexander Petshaft
    </p>
    <p>Lektorat: Lior Gross, Rabbi Jane Litman, Rabbi Vivie Mayer, Dr. Adina Newberg und Eyal Rivlin</p>
    <p>Ehrenamtliche Musikkorrektor:innen: Mary Feinsinger, David P. Goldstein, Carol Boyd Leon, Elaine Moise, Kantorin Aviva Rosenbloom, Rebecca Schwartz und Laura Vidmar</p>
    <p>Daten- und Vertragsmanagement: Zhanna Beyl, Karen Farkas, und Noga Schechter</p>
    <p>Öffentlichkeitsarbeit: Fern Wallach, Jane Freund und Sherry Kirschenbaum</p>
    <p>Anfragen EZJM: soundscape@hmtm-hannover.de</p>
    <p>Anfragen "Rückeroberung des Judentums": Rabbinerin Dr. Goldie Milgram</p>
  </div>
  <div *ngIf="isLang('en')" class="detail-data" >
      <h2>Adding Our Voices (AOV)</h2>
      <h6 class="subtitle">Jewish Feminist and Gender-Inclusive Music Archive</h6>
    <a class="brand-link-magenta default-pointer" [routerLink]="['/aov']" [queryParams]="{tab:'aovidx', key: 'A'}">Search AOV archive</a>
    <p>The <b>Jewish Feminist and Gender-Inclusive Music Archive: ADDING OUR VOICES (AOV)</b> provides free access to hundreds
        of jury-selected exemplary musical liturgy, musical midrash, and songs by artist for use throughout the spectrum
        of Jewish life and practice. The European Centre for Jewish Music at the Hanover University of Music, Drama and Media,
        in conjunction with the AOV Initiative documents the artists, highlighting how their musical activities center
        around issues of social justice and equality. The professionals of the non-profit Reclaiming Judaism worked with
        artists and artist estates to secure donation and archival preparation of music, sound files, photos, and extensive
        biographical information. The staff at the European Centre for Jewish Music at Hanover University of Music, Drama
        and Media developed, hosts and manages the archive’s technical infrastructure, the website and its design.
    </p>
    <p>Feminism, as understood by the AOV activists, means striving to secure and manifest inclusion, equality, liberty,
        justice, and voice for all—starting and not ending with those who identify as women and girls. Accordingly,
        this grass-roots initiative musical collection aims to serve communities seeking gender-sensitive, expansive,
        and inclusive Jewish music in order to become more welcoming and just. In addition, the collection caters to educators,
        scholars, and artists researching Jewish feminist music history and those teaching Jewish culture, religion, music, history and present.
    </p>
    <p>Inspired by the Women’s Rights Movement, the AOV artists collection originates in the 1960s. Since then,
      an increasing number of artists has been creating music which strives to be a transformational force for
      overcoming patriarchal hegemony within Judaism and Jewish life. The name Adding Our Voices is a response to t
      he artists’ inspiration to address the problematic principle of “Kol isha,” the notion that the female singing
      voice is seductive and not to be heard by Jewish males – a custom which greatly restricted female access to
      Jewish literacy as well as keeping women from attaining communal and religious leadership roles.
    </p>
    <p>The artists are from all walks of Jewish life and are lay and professional composers, liturgists,
      and performing artists; among them are cantors, rabbis and other Jewish clergy, educators, and diverse
      individuals addressing aspects of their personal lives through a Jewish feminist and gender-inclusive lens.
      The collection provides new and adapted musical works for many aspects of Jewish life. AOV also includes music
      for aspects of human life long missing from Jewish liturgy such as onset of menses, infertility and miscarriage,
      Rosh Chodesh, Bat and B-mitzvah, coming out, lesbian and gay weddings, and Jewish women of the Bible, Talmud,
      Jewish and secular history. Among the many languages in AOV are Aramaic, English,
      Hebrew (Biblical, Talmudic, Modern, and Nonbinary Hebrew), Malayalam, Urdu, Yiddish, and Spanish.
      Primary musical forms are Jewish musical liturgy, songs, cantillation, musical midrash, opera, and musical theater.
      Among the styles: Mizrachi, Sephardi, Ashkenazi, Klezmer, hip-hop, rap, rock, pop, and folk music, rhythm & blues,
      reggae, musical theater, opera, Jospel, jazz, a Capella, choral, chants, feminist Haftarot set to traditional trope, and feminist Talmudic ballads.
    </p>
    <h3>GRATITUDES:</h3>
    <p>To the Reclaiming Judaism (USA) team of Rabbi Dr. Goldie Mara Milgram, Rabbi Geela Rayzel Raphael, Mindy Rosenthal,
        and Cantor Anita Schubert, and the European Centre for Jewish Music of the Hanover University of Music,
        Drama and Media (Germany) team of Prof. Dr. Sarah M. Ross and Samuel Mund.
    </p>
    <p>As of December 2023, over 30,000 hours of professional and volunteer time have gone into gathering and
        documenting the over 600,000 AOV data points, creating the archive’s infrastructure, music gathering, curating,
        and sheet music preparation, and uploading and proofreading all of the material. Accordingly, we additionally thank:
    </p>
    <p>Music Editing, Review, Transcription and Engraving:  Cantor Ron Fischman, Cantor Anita Schubert,
        AOV Music Notation Editors; and Dr. Joshua Fishbein, Didi King, Alexander Massey, Cantor Richard Nadel,
        Harry Style and Alexander Petshaft
    </p>
    <p>Grammar Consultants: Lior Gross, Rabbi Jane Litman, Rabbi Vivie Mayer, Dr. Adina Newberg, and Eyal Rivlin</p>
    <p>Volunteer Music Proofreaders: Mary Feinsinger, David P. Goldstein, Carol Boyd Leon, Elaine Moise,
        Cantor Aviva Rosenbloom, Rebecca Schwartz, and Laura Vidmar
    </p>
    <p>Data and Contract Management: Zhanna Beyl, Karen Farkas, and Noga Schechter</p>
    <p>Public Relations: Fern Wallach, Jane Freund, and Sherry Kirschenbaum</p>
    <p>
        Inquiries EZJM: soundscape@hmtm-hannover.de
    </p>
    <p>
        Inquiries "Reclaiming Judaism": Rabbi Dr. Goldie Milgram
    </p>
  </div>
</div>
