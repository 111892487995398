import {Injectable} from "@angular/core";
import {LocalStorageService} from "./local-storage.service";
import {isNullOrUndefined} from "../util/SharedUtils";
import {isStringNullOrEmpty} from "../util/StringUtil";

@Injectable({
  providedIn: 'root',
})
export class BasketService {

  constructor(private localStorageService: LocalStorageService) {}

  addToBasket(val: any) {
    return this.localStorageService.setBasket(val);
  }

  // @ts-ignore
  containsId(list: any[], id: any) {
    if(!Array.isArray(list)){
      throw 'ERROR provided list is not an Array';
    }
    if(Array.isArray(list)){
      return list.some(function(entry: any){
        return id === entry.id;
      });
    }
  }

  putIntoBasket(person: any, identifier: string) {

    // make sure we have an array instance for the basket, else create it
    let basket: any[]|null = null;
    basket = this.localStorageService.getBasket();
    if(!basket || null == basket){
      basket = [];
      this.addToBasket(basket);
    }
    // identify the index type -> SOSY or AOV
    if(identifier.startsWith('SOSY_')){
      if(!this.containsId(basket, identifier)){
        basket.push({
          'id': person.personBase.identifier,
          'date': new Date(),
          'name': person.displayName,
          'birthPlace': person.birthPlace,
          'birthDate': person.birthDate
        });
        this.addToBasket(basket);
      }
    }
    if(identifier.startsWith('AOV_')){
      if(!this.containsId(basket, identifier)){
        basket.push({
          'id': person.identifier,
          'date': new Date(),
          'name': person.aovBase.lastName + ', ' + person.aovBase.firstName,
          'birthPlace': person.aovBase.birthPlace,
          'birthDate': person.aovBase.birthDate
        });
        this.addToBasket(basket);
      }
    }
  }
}
