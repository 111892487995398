import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class MynModel {

  _AllMynBaseList: any;
  _mynMap: any;
  constructor() {
  }

  getAllMynBaseList() {
    return this._AllMynBaseList;
  }

  getMynMap() {
    return this._mynMap;
  }

  setAllMynBaseList(allAovBaseList: any) {
    this._AllMynBaseList = allAovBaseList;
  }

  setMynMap(map: any) {
    this._mynMap = map;
  }
}
