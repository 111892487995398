<div id="person_view">
  <div id="person-data">
    <div>
        <a class="brand-link-magenta" [routerLink]="['/myn']" [queryParams]="{tab:'mynidx', key: previousDictKey}">
          {{ 'MYN_BACK_TO_ARCHIVE' | transloco }}
        </a></div>
    <div *ngIf="mynContainer" id="person-headline">
      <h1 style="position:relative;left:-6px;float:left;margin-top: 0"> {{mynContainer.cantor.firstName}} {{mynContainer.cantor.lastName}}
      </h1>
    </div>
    <div class="clear"></div>
    <div id="tab-container">
      <button class="tab-button" (click)="showTabContent('masterdata-tab-content')">
        <div [ngClass]="selectedTab === 'masterdata' ? 'link-box selected-tab' : 'link-box'">
          <span id="masterdata" class="tab">
            {{'CANTOR' | transloco}}
          </span>
       </div>
      </button>

      <button class="tab-button" (click)="showTabContent('media-tab-content')">
        <div [ngClass]="selectedTab === 'media' ? 'link-box selected-tab' : 'link-box'">
            <span id="media" class="tab">{{'MYNUSACH' | transloco}}
            </span>
        </div>
      </button>

    </div>

    <!-- Person Detail Page Content START -->
    <div id="person-content" *ngIf="mynContainer">

      <div id="portrait-box">
        <div id="portrait">
          <img *ngIf="mynContainer.cantor.photoList && mynContainer.cantor.photoList.length > 0"
               class="portrait"
               src="{{storageUrl + 'myn/' + mynContainer.identifier + '/img/' + mynContainer.cantor.photoList[0].identifier + '.jpg'}}"
               onerror="src='../../assets/images/img_placeholder_black.jpg' "
               alt="portrait" />
          <span
            class="media-credit"
            *ngIf="null != mynContainer.cantor.photoList &&
                    mynContainer.cantor.photoList.length > 0 &&
                    null != mynContainer.cantor.photoList[0].mediaFileCredit &&
                    '' != mynContainer.cantor.photoList[0].mediaFileCredit">
            {{'PHOTOCREDIT' | transloco}}: {{  mynContainer.cantor.photoList[0].mediaFileCredit }}
          </span>
          <img class="portrait"
               *ngIf="null == mynContainer.cantor.photoList || mynContainer.cantor.photoList.length == 0"
               src="{{img}}"
               onerror="src='../../assets/images/img_placeholder_black.jpg' " alt="portrait" />
        </div><br>
      </div>

      <!-- TAB CONTENT START -->
      <div id="content-tabs">
        <!-- MASTER DATA -->
        <div *ngIf="selectedTab === 'masterdata'" #masterTabContent id="masterdata-tab-content" class="tab-content">
        <p id="TITLE" *ngIf="mynContainer.cantor.title">
            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.cantor.title)">{{'TITLE' | transloco}}:&nbsp;</span>
            <span>{{ mynContainer.cantor.title}}</span>
        </p>

        <p id="BIRTHDATE" *ngIf="mynContainer.cantor.birthDate">
            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.cantor.birthDate)">{{'BIRTH' | transloco}}:&nbsp;</span>
            <span>{{ mynContainer.cantor.birthDate | date:'y'}}</span>
        </p>
        <p id="DATEOFDEATH" *ngIf="mynContainer.cantor.dateOfDeath">
            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.cantor.dateOfDeath)">{{'DATEOFDEATH' | transloco}}:&nbsp;</span>
            <span>{{ mynContainer.cantor.dateOfDeath | date:'y'}}</span>
        </p>

        <p id="BIRTHPLACE" *ngIf="mynContainer.cantor.placeOfBirth && (mynContainer.cantor.placeOfBirth.city || mynContainer.cantor.placeOfBirth.state || mynContainer.cantor.placeOfBirth.country)">
            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.cantor.placeOfBirth)">{{'BIRTHPLACE' | transloco}}:&nbsp;</span>
            <span *ngIf="mynContainer.cantor.placeOfBirth.city">{{ mynContainer.cantor.placeOfBirth.city }},&nbsp;</span>
            <span *ngIf="mynContainer.cantor.placeOfBirth.state">{{ mynContainer.cantor.placeOfBirth.state }},&nbsp;</span>
            <span>{{ mynContainer.cantor.placeOfBirth.country }}</span>
        </p>

        <p id="LANGUAGES" *ngIf="mynContainer.cantor.languages && mynContainer.cantor.languages.length > 0">
        <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.cantor.languages)">{{'LANGUAGES' | transloco}}:&nbsp;</span>
          <span class="property-list" *ngFor="let language of mynContainer.cantor.languages;let $index = index;">
            <span *ngIf="language">{{language}}</span>
            <span *ngIf="$index < (mynContainer.cantor.languages.length-1)">, </span>
          </span>
        </p>

        <p id="NATIONALITY" *ngIf="mynContainer.cantor.nationality">
            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.cantor.nationality)">{{'NATIONALITY' | transloco}}:&nbsp;</span>
            <span>{{ mynContainer.cantor.nationality }}</span>
        </p>

        <p id="GENDER" *ngIf="mynContainer.cantor.gender">
            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.cantor.gender)">{{'GENDER' | transloco}}:&nbsp;</span>
            <span>{{ mynContainer.cantor.gender }}</span>
        </p>

        <p id="WEBSITE" *ngIf="mynContainer.cantor.website">
            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.cantor.website)">{{'WEBSITE' | transloco}}:&nbsp;</span>
            <span>{{ mynContainer.cantor.website }}</span>
        </p>

        <p id="SOCIALHANDLE" *ngIf="mynContainer.cantor.website">
            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.cantor.socialHandle)">{{'SOCIALHANDLE' | transloco}}:&nbsp;</span>
            <span>{{ mynContainer.cantor.socialHandle }}</span>
        </p>

        <p id="MUSICPUBLICATIONS" *ngIf="mynContainer.cantor.musicPublications && mynContainer.cantor.musicPublications.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.cantor.musicPublications)">{{ 'MUSICPUBLICATIONS' | transloco}}:&nbsp;</span>
            <span class="property-list" *ngFor="let musicPub of mynContainer.cantor.musicPublications;let $index = index;">
            <span *ngIf="musicPub">{{musicPub}}</span>
            <span *ngIf="$index < (mynContainer.cantor.musicPublications.length-1)">, </span>
            </span>
        </p>

        <p id="WRITTENPUBLICATIONS" *ngIf="mynContainer.cantor.writtenPublications && mynContainer.cantor.writtenPublications.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.cantor.writtenPublications)">{{ 'WRITTENPUBLICATIONS' | transloco}}:&nbsp;</span>
            <span class="property-list" *ngFor="let writtenPub of mynContainer.cantor.writtenPublications;let $index = index;">
            <span *ngIf="writtenPub">{{writtenPub}}</span>
            <span *ngIf="$index < (mynContainer.cantor.writtenPublications.length-1)">, </span>
            </span>
        </p>
            <p id="MYMUSIC" *ngIf="mynContainer.cantor.myMusic">
                <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.cantor.myMusic)">{{ 'MYMUSIC' | transloco}}:&nbsp;</span>
                <span>{{ mynContainer.cantor.myMusic }}</span>
            </p>

        </div>
        <!-- MEDIA -->
        <div *ngIf="selectedTab === 'media'">
          <div #mediaTabContent class="tab-content" *ngFor="let composition of mynContainer.compositionList;let compositionIndex = index;">
            <h3 *ngIf="composition.songTitle">
              <span>{{ composition.songTitle }}</span>
            </h3>
            <p id="{{ 'AUDIOFILE-' + compositionIndex }}" *ngIf="composition.audioFile">
              <!-- AUDIO -->
              <span [ngClass]="getLabelClassViaPropertyValue(composition.audioFile)">{{'AUDIOFILE' | transloco}}:&nbsp;</span><br>
              <audio controls preload="auto"
                     (contextmenu)="!composition.showAudioFileDownloadOption ? showCtx($event) : null"
                     [controlsList]="!composition.showAudioFileDownloadOption ? 'nodownload' : null"
                     [src]="storageUrl + 'myn/' + mynContainer.identifier + '/audio/' + composition.audioFile.identifier + '.mp3' ">
              </audio>
            </p>
            <div id="{{ 'VIDEOFILE-' + compositionIndex }}" *ngIf="composition.videoFile">
              <!-- VIDEO -->
              <span [ngClass]="getLabelClassViaPropertyValue(composition.videoFile)">{{'VIDEOFILE' | transloco}}:&nbsp;</span><br>
              <video width="400" height="225"
                     controls preload="auto"
                     [src]="storageUrl + 'myn/' + mynContainer.identifier + '/video/' + composition.videoFile.identifier + '.mp4' ">
                Your browser does not support HTML5 video.
              </video>
            </div>
            <div id="{{ 'ALTERNATIVE_AUDIO-' + compositionIndex }}" *ngIf="composition.pianoFile">
              <!-- PIANOFILE -->
              <span [ngClass]="getLabelClassViaPropertyValue(composition.pianoFile)">{{'ALTERNATIVE_AUDIO' | transloco}}:&nbsp;</span><br>
              <audio controls preload="auto"
                     [src]="storageUrl + 'myn/' + mynContainer.identifier + '/pianofile/' + composition.pianoFile.identifier + '.mp3' "></audio>
            </div>
            <p id="{{ 'LYRICS-' + compositionIndex }}" *ngIf="composition.lyrics">
              <span [ngClass]="getLabelClassViaPropertyValue(composition.lyrics)">{{'LYRICS' | transloco}}:&nbsp;</span><br>
              <span [innerText]="composition.lyrics"></span>
            </p><br>
            <p id="{{ 'BACKGROUND-' + compositionIndex }}" *ngIf="composition.background" style="margin-bottom: 1rem;white-space: pre-wrap;">
              <span [ngClass]="getLabelClassViaPropertyValue(composition.background)">{{'BACKGROUND' | transloco}}:&nbsp;</span><br>
              <span>{{ composition.background }}</span>
            </p>
            <div id="{{ 'SHEETMUSIC-' + compositionIndex }}" *ngIf="composition.sheetMusic">
              <!-- PDF -->
              <span [ngClass]="getLabelClassViaPropertyValue(composition.sheetMusic)">{{'SHEETMUSIC' | transloco}}:&nbsp;</span>
              <p style="text-overflow: ellipsis;margin-top:0;">
                <a [href]="storageUrl + 'myn/' + mynContainer.identifier + '/sheetmusic/' + composition.sheetMusic.identifier + '.pdf'">
                  {{composition.sheetMusic.name}}
                </a>
              </p>
            </div>
            <p id="{{ 'MISC-' + compositionIndex }}" *ngIf="composition.misc">
              <span [ngClass]="getLabelClassViaPropertyValue(composition.misc)">{{'MISC' | transloco}}:&nbsp;</span>
              <span>{{ composition.misc }}</span>
            </p>
            <p id="{{ 'TAGLIST-' + compositionIndex }}" *ngIf="composition.tagList && composition.tagList.length > 0">
              <span [ngClass]="getLabelClassViaPropertyValue(composition.tagList)">{{'TAGS' | transloco}}:&nbsp;</span>
              <span>{{ composition.tagList.join(', ') }}</span>
            </p>
          </div>
        </div>
<!--       &lt;!&ndash; INFO &ndash;&gt;-->
<!--        <div *ngIf="selectedTab === 'info'" #infoTabContent id="info-tab-content" class="tab-content">-->

<!--          <div id="WEBSITES" *ngIf="mynContainer.info.websites && mynContainer.info.websites.length > 0">-->
<!--            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.info.websites)">{{'WEBSITES' | transloco}}:&nbsp;</span>-->
<!--            <ul class="property-list" *ngFor="let orientation of mynContainer.info.websites;let $index=index;"><li>{{orientation}}</li></ul>-->
<!--          </div>-->
<!--          <div id="MUSICPUBLICATIONS" *ngIf="mynContainer.info.musicPublications && mynContainer.info.musicPublications.length > 0">-->
<!--            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.info.musicPublications)">{{'MUSICPUBLICATIONS' | transloco}}:&nbsp;</span>-->
<!--            <ul class="property-list" *ngFor="let ordination of mynContainer.info.musicPublications;let $index=index;"><li>{{ordination}}</li></ul>-->
<!--          </div>-->
<!--          <div id="MUSICALINFLUENCES" *ngIf="mynContainer.info.musicalInfluences && mynContainer.info.musicalInfluences.length > 0">-->
<!--            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.info.musicalInfluences)">{{'MUSICALINFLUENCES' | transloco}}:&nbsp;</span><br>-->
<!--            <ul class="property-list" *ngFor="let musicalInfluence of mynContainer.info.musicalInfluences;let $index=index;"><li>{{musicalInfluence}}</li></ul>-->
<!--          </div>-->
<!--          <div id="ADDITIONALINFLUENCES" *ngIf="mynContainer.info.additionalInfluences && mynContainer.info.additionalInfluences.length > 0">-->
<!--            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.info.additionalInfluences)">{{'ADDITIONALINFLUENCES' | transloco}}:&nbsp;</span>-->
<!--            <ul class="property-list" *ngFor="let pub of mynContainer.info.additionalInfluences;let $index=index;"><li>{{pub}}</li></ul>-->
<!--          </div>-->
<!--          <div id="MAJORCONCERTSANDAPPEARANCES" *ngIf="mynContainer.info.majorConcertsAndAppearances && mynContainer.info.majorConcertsAndAppearances.length > 0">-->
<!--            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.info.majorConcertsAndAppearances)">{{'MAJORCONCERTSANDAPPEARANCES' | transloco}}:&nbsp;</span>-->
<!--            <ul class="property-list" *ngFor="let founding of mynContainer.info.majorConcertsAndAppearances;let $index=index;"><li>{{founding}}</li></ul>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash; BIO &ndash;&gt;-->
<!--        <div *ngIf="selectedTab === 'bio'" #bioTabContent id="bio-tab-content" class="tab-content">-->

<!--          <div id="ORIENTATIONLIST" *ngIf="mynContainer.biography.orientationList && mynContainer.biography.orientationList.length > 0">-->
<!--            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.biography.orientationList)">{{'ORIENTATIONLIST' | transloco}}:&nbsp;</span>-->
<!--            <ul class="property-list" *ngFor="let orientation of mynContainer.biography.orientationList;let $index=index;"><li>{{orientation}}</li></ul>-->
<!--          </div>-->
<!--          <div id="ORDINATIONLIST" *ngIf="mynContainer.biography.ordinationList && mynContainer.biography.ordinationList.length > 0">-->
<!--            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.biography.ordinationList)">{{'ORDINATIONLIST' | transloco}}:&nbsp;</span>-->
<!--            <ul class="property-list" *ngFor="let ordination of mynContainer.biography.ordinationList;let $index=index;"><li>{{ordination}}</li></ul>-->
<!--          </div>-->
<!--&lt;!&ndash;          <div *ngIf="mynContainer.info.organizationalMemberships && mynContainer.info.organizationalMemberships.length > 0">&ndash;&gt;-->
<!--&lt;!&ndash;            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.info.organizationalMemberships)">{{'ORGANIZATIONALMEMBERSHIPS' | transloco}}:&nbsp;</span>&ndash;&gt;-->
<!--&lt;!&ndash;            <ul class="property-list" *ngFor="let edu of mynContainer.info.organizationalMemberships;let $index=index;"><li>{{edu}}</li></ul>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--          <div id="ORGANIZATIONALMEMBERSHIPS" *ngIf="mynContainer.biography.organizationalMemberships && mynContainer.biography.organizationalMemberships.length > 0">-->
<!--            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.biography.organizationalMemberships)">{{'ORGANIZATIONALMEMBERSHIPS' | transloco}}:&nbsp;</span>-->
<!--            <ul class="property-list" *ngFor="let orgMember of mynContainer.biography.organizationalMemberships;"><li>{{orgMember}}</li></ul>-->
<!--          </div>-->
<!--          <div id="EDUCATIONLIST" *ngIf="mynContainer.biography.educationList && mynContainer.biography.educationList.length > 0">-->
<!--            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.biography.educationList)">{{'EDUCATIONLIST' | transloco}}:&nbsp;</span>-->
<!--            <ul class="property-list" *ngFor="let edu of mynContainer.biography.educationList;let $index=index;"><li>{{edu}}</li></ul>-->
<!--          </div>-->
<!--          <div id="HONORSYEAR" *ngIf="mynContainer.biography.honorsYear && mynContainer.biography.honorsYear.length > 0">-->
<!--            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.biography.honorsYear)">{{ 'HONORS' | transloco }}:&nbsp;</span>-->
<!--            <ul class="property-list" *ngFor="let honor of mynContainer.biography.honorsYear;let $index=index;"><li>{{honor}}</li></ul>-->
<!--          </div>-->
<!--          <div id="PUBLICATIONSAUTHOREDITOR" *ngIf="mynContainer.biography.publicationsAuthorEditor && mynContainer.biography.publicationsAuthorEditor.length > 0">-->
<!--            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.biography.publicationsAuthorEditor)">{{'PUBLICATIONSAUTHOREDITOR' | transloco}}:&nbsp;</span>-->
<!--            <ul class="property-list" *ngFor="let pub of mynContainer.biography.publicationsAuthorEditor;let $index=index;"><li>{{pub}}</li></ul>-->
<!--          </div>-->
<!--          <div id="ACADEMICAPPOINTMENTS" *ngIf="mynContainer.biography.academicAppointments && mynContainer.biography.academicAppointments.length > 0">-->
<!--            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.biography.academicAppointments)">{{'ACADEMICAPPOINTMENTS' | transloco}}:&nbsp;</span>-->
<!--            <ul class="property-list" *ngFor="let academicAppointment of mynContainer.biography.academicAppointments;let $index=index;"><li>{{academicAppointment}}</li></ul>-->
<!--          </div>-->
<!--          <div id="FOUNDINGS" *ngIf="mynContainer.biography.foundings && mynContainer.biography.foundings.length > 0">-->
<!--            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.biography.foundings)">{{'FOUNDINGLIST' | transloco}}:&nbsp;</span>-->
<!--            <ul class="property-list" *ngFor="let founding of mynContainer.biography.foundings;let $index=index;"><li>{{founding}}</li></ul>-->
<!--          </div>-->
<!--          <div id="JEWISHCOMMUNAL" *ngIf="mynContainer.biography.jewishCommunal && mynContainer.biography.jewishCommunal.length > 0">-->
<!--            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.biography.jewishCommunal)">{{'JEWISHCOMMUNAL' | transloco}}:&nbsp;</span>-->
<!--            <ul class="property-list" *ngFor="let communal of mynContainer.biography.jewishCommunal;let $index=index;"><li>{{communal}}</li></ul>-->
<!--          </div>-->
<!--          <div id="OTHERCAREER" *ngIf="mynContainer.biography.otherCareer && mynContainer.biography.otherCareer.length > 0">-->
<!--            <span [ngClass]="getLabelClassViaPropertyValue(mynContainer.biography.otherCareer)">{{'OTHERCAREER' | transloco}}:&nbsp;</span>-->
<!--            <ul class="property-list" *ngFor="let activity of mynContainer.biography.otherCareer;let $index=index;"><li>{{activity}}</li></ul>-->
<!--          </div>-->
<!--        </div>-->

      </div>
      <!-- TAB CONTENT END -->

    </div>
    <!-- Person Page Content END -->

  </div>
</div>
