import { Component, Input, OnInit } from '@angular/core';
import { MynService } from "../../../service/myn/myn-service";
import { MynSearchModel } from "../../../service/myn/myn-search-model";

@Component({
  selector: 'myn-simple-search',
  templateUrl: './simplesearch.component.html',
  styleUrls: ['./simplesearch.component.css']
})
export class MynSimpleSearchComponent implements OnInit {

  searchStr = '';
  @Input() setSearchResult!: Function;
  constructor(private mynService: MynService, private searchModel: MynSearchModel) { }

  ngOnInit(): void { }

  inputChangeHandler(searchString: string): void {
    if(searchString){
      this.searchModel.setSearchParameter({'firstSearchInputValue': searchString});
      this.mynService.performTextSearch(searchString, this.setSearchResult);
    }
  }

  onKey(keyCode: String): void {
    if('Enter' === keyCode) {
      this.inputChangeHandler(this.searchStr);
    }
  }

}
