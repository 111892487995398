import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  TRANSLOCO_INTERCEPTOR,
  translocoConfig,
  TranslocoModule,
  FunctionalTranspiler,
  provideTranslocoTranspiler,
  provideTranslocoMissingHandler,
  TranslocoMissingHandler,
  TranslocoConfig,
  TranslocoInterceptor,
  TRANSLOCO_FALLBACK_STRATEGY,
  TranslocoFallbackStrategy, DefaultTranspiler
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

@Injectable({ providedIn: 'root' })
export class CustomHandler implements TranslocoMissingHandler {
  handle(key: string, config: TranslocoConfig) {
    console.warn(key)
    return null;
  }
}

@Injectable({ providedIn: 'root' })
export class CustomInterceptor implements TranslocoInterceptor {
  preSaveTranslation(translation: Translation, lang: string): Translation {
    return translation;
  }

  preSaveTranslationKey(key: string, value: string, lang: string): string {
    return value;
  }
}
@Injectable({ providedIn: 'root' })
export class CustomStrategy implements TranslocoFallbackStrategy {
  getNextLangs(failedLang: string): string[] {
    return ['de'];
  }
}

export const translocoConf = translocoConfig({
  availableLangs: ['en', 'de'],
  defaultLang: 'de',
  fallbackLang: 'de',
  // Remove this option if your application doesn't support changing language in runtime.
  reRenderOnLangChange: true,
  prodMode: environment.production,
});

@NgModule({
  exports: [TranslocoModule],
  providers: [
    { provide: TRANSLOCO_CONFIG, useValue: translocoConf },
    provideTranslocoMissingHandler(CustomHandler),
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    { provide: TRANSLOCO_INTERCEPTOR, useClass: CustomInterceptor },
    provideTranslocoTranspiler(DefaultTranspiler),
    { provide: TRANSLOCO_FALLBACK_STRATEGY, useClass: CustomStrategy }
  ],
})
export class TranslocoRootModule {}
