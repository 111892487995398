import { Injectable } from "@angular/core";
import { ServiceClient } from "../service-client";
import { BasketService } from "../basket-service";
import { AovModel } from "./aov-service-model";

@Injectable({
  providedIn: 'root',
})
export class AovService {

  NO_PERSON_KEY = 'NO_NAME';
  AllPersonContainerList: any;
  constructor(private serviceClient: ServiceClient,
              private aovModel: AovModel,
              private basketService: BasketService)
  {

  }

  getAllAovPersonList(_handleGetAllPersonListResult: Function) {
    let that = this;
    this.serviceClient.getAllAovPersonList().subscribe((response: any) => {
        return _handleGetAllPersonListResult(response.data);
    },(response) => {
      return that._handleServiceFault(response);
    });
  }

  getAllAovBaseList(callback: Function) {
    let that = this;
    let tempMap = this.aovModel.getAovMap();
    if(null == tempMap || tempMap.length === 0){
      this.serviceClient.getAllAovPersonBaseList().subscribe((response: any) => {
        let map = that._createAovMap(response);
        callback(map);
      }, (response: any) => {
        that._handleServiceFault(response);
      });
    }
    else {
      callback(tempMap);
    }
  }

  getCached(identifier: string) {
    let personContainer = null;
    let tempPersonContainer = null;
    if(this.AllPersonContainerList) {
      for(let i = 0; i < this.AllPersonContainerList.length; i++){
        tempPersonContainer = this.AllPersonContainerList[i];
        if(tempPersonContainer.person.personBase.identifier === identifier){
          personContainer =  tempPersonContainer;
          break;
        }
      }
    }
    return personContainer;
  }

  getAovContainerViaIdentifier(identifier: string, callback: Function) {
    let that = this;
    let identifierList  = [identifier];
    this._getAovContainerViaIdentifierList(identifierList).subscribe((response: any) => {
      callback(response);
    }, (response: any) => {
      that._handleServiceFault(response);
    });
  }

  getAovContainerListViaIdentifierList(identifierList: string[]) {
    return this._getAovContainerViaIdentifierList(identifierList);
  }

  getPersonMap() {
    return this.aovModel.getAovMap();
  }

  performAovTextSearch(searchStr: string, _handleTextSearchResult: Function) {
    this.serviceClient.performAovTextSearch(searchStr).subscribe((response: any) => {
      _handleTextSearchResult(response);
    },(response: any) => {
      _handleTextSearchResult(response);
    });
  }

  performAovSearch(searchParameter: any, _handleSearchResult: Function) {
    this.serviceClient.performAovSearch(searchParameter).subscribe((response: any) => {
      _handleSearchResult(response);
    },(response: any) => {
      _handleSearchResult(response);
    });
  }

  putIntoBasket(aovIdentifier: string) {
    let idList = [];
    idList.push(aovIdentifier);
    this._getAovContainerViaIdentifierList(idList).subscribe((response: any) =>{
      if(response){
        let aovContainer = response[0];
        this.basketService.putIntoBasket(aovContainer, aovIdentifier);
      }
    }, (response) => {
      this._handleServiceFault(response)
    });
  }

  _createAovMap(aovBaseList: any) {
    let map: any = {};
    let dictKey = '';
    this.aovModel.setAllAovBaseList(aovBaseList);
    for (let i = 0; i < aovBaseList.length; i++) {
      let lastName = aovBaseList[i].lastName;
      dictKey = (typeof lastName!='undefined' && lastName) ? lastName.substr(0, 1).toUpperCase() : this.NO_PERSON_KEY;
      if (0 === map.length || null == map[dictKey]){
        map[dictKey] = [];
      }
      map[dictKey].push({'key': i, 'aovBase': aovBaseList[i]});
    }
    this.aovModel.setAovMap(map);
    return map;
  }

  _getAovContainerViaIdentifierList(identifierList: string[]) {
    return this.serviceClient.getAovContainerViaIdentifierList(identifierList);
  }

  _handleServiceFault(response:any) {
    console.log(response);
  }
}


