import { Component, Input, OnInit } from '@angular/core';
import { AovService } from "../../../service/aov/aov-service";
import { AovSearchModel } from "../../../service/aov/aov-search-model";

@Component({
  selector: 'aov-simple-search',
  templateUrl: './simplesearch.component.html',
  styleUrls: ['./simplesearch.component.css']
})
export class AovSimpleSearchComponent implements OnInit {

  searchStr = '';
  @Input() setSearchResult!: Function;
  constructor(private aovService: AovService, private searchModel: AovSearchModel) { }

  ngOnInit(): void { }

  inputChangeHandler(searchString: string): void {
    if(searchString){
      this.searchModel.setSearchParameter({'firstSearchInputValue': searchString});
      this.aovService.performAovTextSearch(searchString, this.setSearchResult);
    }
  }

  onKey(keyCode: String): void {
    if('Enter' === keyCode) {
      this.inputChangeHandler(this.searchStr);
    }
  }

}
