import {inject, NgModule} from '@angular/core';
import {CanActivateFn, Router, RouterModule, Routes} from '@angular/router';
import { IntroComponent } from "./pages/static/intro/intro.component";
import { LandingComponent } from "./pages/static/landing/landing.component";
import { ContactComponent } from "./components/contact/contact.component";
import { WelcomeComponent } from "./pages/static/welcome/welcome.component";
import { HowtoComponent } from "./pages/static/howto/howto.component";
import { TraditionComponent } from "./pages/static/tradition/tradition.component";
import { CarryOnTraditionComponent } from "./pages/static/carry-on-tradition/carry-on-tradition.component";
import { ImprintComponent } from "./pages/static/imprint/imprint.component";
import { EncyclopediaComponent } from "./pages/static/encyclopedia/encyclopedia.component";
import { AovComponent } from "./pages/aov/aov-master/aov.component";
import { AovDetailComponent } from "./pages/aov/aov-detail/aov-detail.component";
import { AovSearchResultComponent } from "./pages/aov/search-result/search-result.component";
import { ArticleComponent } from "./pages/static/encyclopedia/article/article.component";
import { AovLandingComponent } from "./pages/aov/aov-landing/aov-landing.component";
import {TermsComponent} from "./pages/static/terms/terms.component";
import {MynComponent} from "./pages/myn/myn-master/myn.component";
import {MynSearchResultComponent} from "./pages/myn/search-result/search-result.component";
import {MynDetailComponent} from "./pages/myn/myn-detail/myn-detail.component";
import {MynLandingComponent} from "./pages/myn/myn-landing/myn-landing.component";
import {LocalStorageService} from "./service/local-storage.service";


export function MynPageGuard(redirectRoute: string): CanActivateFn {
  return () => {
    const router: Router = inject(Router);
    const localStorageService: LocalStorageService = inject(LocalStorageService);
    const showMyn = localStorageService.getShowMyNusach();
    if (null != showMyn && 'TRUE' === showMyn) {
      return true;
    }
    return router.createUrlTree([redirectRoute]);
  };
}

const routes: Routes = [
  { path: '',   redirectTo: '/landing', pathMatch: 'full' },

  { path: 'intro', component: IntroComponent },
  { path: 'imprint', component: ImprintComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'landing', component: LandingComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'howto', component: HowtoComponent },
  { path: 'tradition', component: TraditionComponent },
  { path: 'carry-on-tradition', component: CarryOnTraditionComponent },

  { path: 'myn', component: MynComponent, canActivate: [MynPageGuard('/landing')] },
  { path: 'myn-search-result', component: MynSearchResultComponent,canActivate: [MynPageGuard('/landing')] },
  { path: 'myn-detail', component: MynDetailComponent, canActivate: [MynPageGuard('/landing')] },
  { path: 'myn-landing', component: MynLandingComponent , canActivate: [MynPageGuard('/landing')] },

  { path: 'aov', component: AovComponent },
  { path: 'aov-search-result', component: AovSearchResultComponent },
  { path: 'aov-detail', component: AovDetailComponent },
  { path: 'aov-landing', component: AovLandingComponent },

  // Stories
  { path: 'encyclopedia', component: EncyclopediaComponent },
  { path: 'encyclopedia/ashkenazim', data: { page: 'ashkenazim' }, component: ArticleComponent },
  { path: 'encyclopedia/feminist', data: { page: 'feminist' }, component: ArticleComponent },
  { path: 'encyclopedia/liberal', data: { page: 'liberal' }, component: ArticleComponent },
  { path: 'encyclopedia/mizrahim', data: { page: 'mizrahim' }, component: ArticleComponent },
  { path: 'encyclopedia/orthodox', data: { page: 'orthodox' }, component: ArticleComponent },
  { path: 'encyclopedia/romaniotes', data: { page: 'romaniotes' }, component: ArticleComponent },
  { path: 'encyclopedia/sephardim', data: { page: 'sephardim' }, component: ArticleComponent },


  { path: '**', redirectTo: '/landing' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // onSameUrlNavigation: 'reload',
    // scrollPositionRestoration: 'enabled',
    // anchorScrolling: 'enabled',
    // scrollOffset: [100, 100], // [x, y]
    enableTracing: false }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
