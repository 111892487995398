<div id="enzyklopedia_view" ng-controller="SearchController as search">
  <div id="enzyklopedia-data">
    <!-- DE -->
    <div *ngIf="isLang('de')">
      <h2>Orthodoxes Judentum</h2>
      <p>
        Als orthodox werden Personen oder Gemeinden bezeichnet, die der Halakha, dem jüdischen Religionsgesetz folgen.</p>
      <p>
        Das Phänomen der Orthodoxie entstand Anfang des 19. Jahrhundert als Antwort auf die Haskalah (jüdische Aufklärung) und in
        Abgrenzung zu religiösen Reformen in West- und Mitteleuropa. In diesem Zusammenhang kam auch erst die Bezeichnung „orthodox“
        in Verbindung mit dem Judentum auf (andere Bezeichnungen sind „toratreu“, „fromm“ oder „nach dem alten Ritus“).
      </p><p>
      In Bezug auf die musikalische Praxis der Synagoge lehnten sich als orthodox verstehende Gemeinden in der Regel Neuerungen der Reformbewegung ab.
      Ein wesentlicher Streitgegenstand war die Verwendung der Orgel in der Synagoge, die im sogenannten „Orgelstreit“ verhandelt wurde.
      Aber auch die Einführung von Chören, in denen teilweise auch Frauen sangen, wurde in vielen orthodoxen Gemeinden abgelehnt.
      Bezüglich der Melodien waren die Grenzen durchlässiger – in vielen orthodoxen Gemeinden wurden auch Melodien von Komponisten der Reformbewegung übernommen.
    </p><p>
      Heute ist die Orthodoxie keine einheitliche Bewegung oder Organisation.
      Vielmehr werden eine Vielzahl unterschiedlicher Strömungen und Gemeinden als orthodox bezeichnet,
      die jedoch alle die schriftliche und mündliche Tora als verbindlich verstehen.
    </p><p>
      Zu orthodoxen Strömungen s. <a href="https://www.jewishvirtuallibrary.org/orthodox-judaism">https://www.jewishvirtuallibrary.org/orthodox-judaism</a>
    </p><p>
      Autorin: Martha Stellmacher
    </p>
      <h5>Literatur:</h5>
      <p>Mordechai Breuer: Jüdische Orthodoxie im Deutschen Reich 1871 – 1918. Sozialgeschichte einer religiösen Minderheit, Frankfurt am Main: Jüd. Verl. bei Athenäum 1986.</p>
    </div>
    <!-- EN -->
    <div *ngIf="isLang('en')">
      <h2>Orthodox Judaism</h2>
      <p>Orthodox Jews are persons or communities that follow the Halakha, the Jewish religious law.</p>
      <p>
        The phenomenon of Orthodoxy arose at the beginning of the 19th century in response to the Haskalah (Jewish Enlightenment)
        and in contrast to religious reforms in Western and Central Europe. In this context, the term "orthodox"
        was first used in connection with Judaism (other terms are "Torah-believing", "pious, docile" or "according to the old rites").
      </p><p>
      With regard to the musical practice of the synagogue, Orthodox communities generally rejected innovations of the reform movement.
      One important issue was the use of the organ in the synagogue, which was the matter of contention in the so-called "organ dispute".
      In addition, the introduction of choirs, some of them employing women, was rejected in many Orthodox communities. With regard to melodies,
      the boundaries were more permeable - in many Orthodox communities, melodies were also adopted by composers of the reform movement.
    </p><p>
      Today Orthodoxy is not a uniform movement or organization. Rather, a multitude of different currents and communities are called Orthodox,
      but they all understand the written and oral Torah as binding.
    </p><p>
      For orthodox currents see: <a href="https://www.jewishvirtuallibrary.org/orthodox-judaism">https://www.jewishvirtuallibrary.org/orthodox-judaism</a>
    </p><p>
      Author: Martha Stellmacher
    </p>
      <h5>Literature:</h5>
      <p>Mordechai Breuer: Jüdische Orthodoxie im Deutschen Reich 1871 – 1918. Sozialgeschichte einer religiösen Minderheit, Frankfurt am Main: Jüd. Verl. bei Athenäum 1986.</p>
    </div>
  </div>
</div>
