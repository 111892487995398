import { Injectable } from '@angular/core';
import {Settings} from "../../model/Settings";
import {LocalStorageService} from "../../service/local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class WaiSettingsFacadeService {

  _settings: any;
  constructor(private localStorageService: LocalStorageService) { }

  getDefaultSettings(): Settings {
    let settings: Settings = new Settings();
    settings.isGreyscale = false;
    settings.arrowOption = 'NOT_SET';
    settings.isHighlightLinks = false;
    settings.backgroundColorOption = 'BACKGROUND_OPTION_WHITE';
    settings.relativeFontSize = 100;
    return settings;
  }

  getSettings() {
    //if(!this._settings){
      this._settings = this.localStorageService.loadSettings();
      if(!this._settings){
        this._settings = this.getDefaultSettings();
      }
    //}
    return this._settings;
  }

  setSettings(settings: Settings) {
    let _settings = settings;
    this.localStorageService.setSettings(_settings);
  }
}
