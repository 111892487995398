<div id="person_view">
  <div id="person-data">
    <div>
        <a class="brand-link-magenta" [routerLink]="['/aov']" [queryParams]="{tab:'aovidx', key: previousDictKey}" queryParamsHandling="merge">
          {{ 'AOV_BACK_TO_ARCHIVE' | transloco }}
        </a></div>
    <div *ngIf="aovContainer" id="person-headline">
      <h1 style="position:relative;left:-6px;float:left;margin-top: 0"> {{aovContainer.aovBase.firstName}} {{aovContainer.aovBase.lastName}}
      </h1>
    </div>
    <div class="clear"></div>
    <div id="tab-container">
      <button class="tab-button" (click)="showTabContent('masterdata-tab-content')">
        <div [ngClass]="selectedTab === 'masterdata' ? 'link-box selected-tab' : 'link-box'">
          <span id="masterdata" class="tab">
            {{'AOV_MASTER_DATA' | transloco}}
          </span>
       </div>
      </button>

      <button class="tab-button" (click)="showTabContent('media-tab-content')">
        <div [ngClass]="selectedTab === 'media' ? 'link-box selected-tab' : 'link-box'">
            <span id="media" class="tab">{{'MUSIC' | transloco}}
            </span>
        </div>
      </button>

      <button class="tab-button" (click)="showTabContent('info-tab-content')">
        <div [ngClass]="selectedTab === 'info' ? 'link-box selected-tab' : 'link-box'">
          <span id="relationship" class="tab">{{'MUSIC_INFO' | transloco}}</span>
        </div>
      </button>

      <button class="tab-button" (click)="showTabContent('bio-tab-content')">
        <div [ngClass]="selectedTab === 'bio' ? 'link-box selected-tab' : 'link-box'">
          <span id="bio" class="tab">{{'PROFILE_TAB' | transloco}}</span>
        </div>
      </button>
    </div>

    <!-- Person Detail Page Content START -->
    <div id="person-content" *ngIf="aovContainer">

      <div id="portrait-box">
        <div id="portrait">
          <img *ngIf="aovContainer.aovBase.photoList && aovContainer.aovBase.photoList.length > 0"
               class="portrait"
               src="{{storageUrl + 'aov/' + aovContainer.identifier + '/img/' + aovContainer.aovBase.photoList[0].identifier + '.jpg'}}"
               onerror="src='../../assets/images/img_placeholder_black.jpg' "
               alt="portrait" />
          <span
            class="media-credit"
            *ngIf="null != aovContainer.aovBase.photoList &&
                    aovContainer.aovBase.photoList.length > 0 &&
                    null != aovContainer.aovBase.photoList[0].mediaFileCredit &&
                    '' != aovContainer.aovBase.photoList[0].mediaFileCredit">
            {{'PHOTOCREDIT' | transloco}}: {{  aovContainer.aovBase.photoList[0].mediaFileCredit }}
          </span>
          <img class="portrait"
               *ngIf="null == aovContainer.aovBase.photoList || aovContainer.aovBase.photoList.length == 0"
               src="{{img}}"
               onerror="src='../../assets/images/img_placeholder_black.jpg' " alt="portrait" />
        </div><br>
      </div>

      <!-- TAB CONTENT START -->
      <div id="content-tabs">
        <!-- MASTER DATA -->
        <div *ngIf="selectedTab === 'masterdata'" #masterTabContent id="masterdata-tab-content" class="tab-content">

          <p id="BIO" *ngIf="aovContainer.aovBase.bio">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.aovBase.bio)">{{'AOV_BIO' | transloco}}:&nbsp;</span><br>
            <span>{{ aovContainer.aovBase.bio }}</span>
          </p>

          <div id="TITLELIST" *ngIf="aovContainer.aovBase.titleList && aovContainer.aovBase.titleList.length > 0">
            <p>
              <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.aovBase.titleList)">{{'TITLELIST' | transloco}}:&nbsp;</span>
              <span class="property-list" *ngFor="let title of aovContainer.aovBase.titleList;let $index = index;">
                <span *ngIf="title">{{title}}</span>
                <span *ngIf="$index < (aovContainer.aovBase.titleList.length-1)">, </span>
              </span>
            </p>
          </div>

          <p id="BIRTHDATE" *ngIf="aovContainer.aovBase.birthDate">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.aovBase.birthDate)">{{'BIRTH' | transloco}}:&nbsp;</span>
            <span>{{ aovContainer.aovBase.birthDate | date:'y'}}</span>
          </p>
        <p id="DATEOFDEATH" *ngIf="aovContainer.aovBase.dateOfDeath">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.aovBase.dateOfDeath)">{{'DATEOFDEATH' | transloco}}:&nbsp;</span>
            <span>{{ aovContainer.aovBase.dateOfDeath | date:'y'}}</span>
        </p>

          <p id="BIRTHPLACE" *ngIf="aovContainer.aovBase.placeOfBirth && (aovContainer.aovBase.placeOfBirth.city || aovContainer.aovBase.placeOfBirth.state || aovContainer.aovBase.placeOfBirth.country)">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.aovBase.placeOfBirth)">{{'BIRTHPLACE' | transloco}}:&nbsp;</span>
            <span *ngIf="aovContainer.aovBase.placeOfBirth.city">{{ aovContainer.aovBase.placeOfBirth.city }},&nbsp;</span>
            <span *ngIf="aovContainer.aovBase.placeOfBirth.state">{{ aovContainer.aovBase.placeOfBirth.state }},&nbsp;</span>
            <span>{{ aovContainer.aovBase.placeOfBirth.country }}</span>
          </p>

          <p id="NATIVELANGUAGE" *ngIf="aovContainer.aovBase.nativeLanguage">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.aovBase.nativeLanguage)">{{'NATIVELANGUAGE' | transloco}}:&nbsp;</span>
            <span>{{ aovContainer.aovBase.nativeLanguage }}</span>
          </p>

          <p id="OTHERLANGUAGES" *ngIf="aovContainer.aovBase.otherLanguages && aovContainer.aovBase.otherLanguages.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.aovBase.otherLanguages)">{{'OTHERLANGUAGES' | transloco}}:&nbsp;</span>
              <span class="property-list" *ngFor="let language of aovContainer.aovBase.otherLanguages;let $index = index;">
                <span *ngIf="language">{{language}}</span>
                <span *ngIf="$index < (aovContainer.aovBase.otherLanguages.length-1)">, </span>
              </span>
          </p>

          <p id="NATIONALITY" *ngIf="aovContainer.aovBase.nationality">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.aovBase.nationality)">{{'NATIONALITY' | transloco}}:&nbsp;</span>
            <span>{{ aovContainer.aovBase.nationality }}</span>
          </p>

          <p id="GENDER" *ngIf="aovContainer.aovBase.gender">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.aovBase.gender)">{{'GENDER' | transloco}}:&nbsp;</span>
            <span>{{ aovContainer.aovBase.gender }}</span>
          </p>

          <p id="PRONOUNS" *ngIf="aovContainer.aovBase.pronouns && aovContainer.aovBase.pronouns.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.aovBase.pronouns)">{{'PRONOUNS' | transloco}}:&nbsp;</span>
            <span>{{ aovContainer.aovBase.pronouns.join(', ') }}</span>
          </p>

        </div>
        <!-- MUSIC -->
        <div *ngIf="selectedTab === 'media'">
          <div #mediaTabContent class="tab-content" *ngFor="let composition of aovContainer.compositionList;let compositionIndex = index;">
            <h3 *ngIf="composition.songTitle">
              <span>{{ composition.songTitle }}</span>
            </h3>
            <p id="{{ 'AUDIOFILE-' + compositionIndex }}" *ngIf="composition.audioFile">
              <!-- AUDIO -->
              <span [ngClass]="getLabelClassViaPropertyValue(composition.audioFile)">{{'AUDIOFILE' | transloco}}:&nbsp;</span><br>
              <audio controls preload="auto"
                     (contextmenu)="!composition.showAudioFileDownloadOption ? showCtx($event) : null"
                     [controlsList]="!composition.showAudioFileDownloadOption ? 'nodownload' : null"
                     [src]="storageUrl + 'aov/' + aovContainer.identifier + '/audio/' + composition.audioFile.identifier + '.mp3' ">
              </audio>
            </p>
            <div id="{{ 'VIDEOFILE-' + compositionIndex }}" *ngIf="composition.videoFile">
              <!-- VIDEO -->
              <span [ngClass]="getLabelClassViaPropertyValue(composition.videoFile)">{{'VIDEOFILE' | transloco}}:&nbsp;</span><br>
              <video width="400" height="225"
                     controls preload="auto"
                     [src]="storageUrl + 'aov/' + aovContainer.identifier + '/video/' + composition.videoFile.identifier + '.mp4' ">
                Your browser does not support HTML5 video.
              </video>
            </div>
            <div id="{{ 'PIANOFILE-' + compositionIndex }}" *ngIf="composition.pianoFile">
              <!-- PIANOFILE -->
              <span [ngClass]="getLabelClassViaPropertyValue(composition.pianoFile)">{{'PIANOFILE' | transloco}}:&nbsp;</span><br>
              <audio controls preload="auto"
                     [src]="storageUrl + 'aov/' + aovContainer.identifier + '/pianofile/' + composition.pianoFile.identifier + '.mp3' "></audio>
            </div>
            <p id="{{ 'LYRICS-' + compositionIndex }}" *ngIf="composition.lyrics">
              <span [ngClass]="getLabelClassViaPropertyValue(composition.lyrics)">{{'LYRICS' | transloco}}:&nbsp;</span><br>
              <span [innerText]="composition.lyrics"></span>
            </p><br>
            <p id="{{ 'BACKGROUND-' + compositionIndex }}" *ngIf="composition.background" style="margin-bottom: 1rem;white-space: pre-wrap;">
              <span [ngClass]="getLabelClassViaPropertyValue(composition.background)">{{'BACKGROUND' | transloco}}:&nbsp;</span><br>
              <span>{{ composition.background }}</span>
            </p>
            <div id="{{ 'SHEETMUSIC-' + compositionIndex }}" *ngIf="composition.sheetMusic">
              <!-- PDF -->
              <span [ngClass]="getLabelClassViaPropertyValue(composition.sheetMusic)">{{'SHEETMUSIC' | transloco}}:&nbsp;</span>
              <p style="text-overflow: ellipsis;margin-top:0;">
                <a [href]="storageUrl + 'aov/' + aovContainer.identifier + '/sheetmusic/' + composition.sheetMusic.identifier + '.pdf'">
                  {{composition.sheetMusic.name}}
                </a>
              </p>
            </div>
            <p id="{{ 'MISC-' + compositionIndex }}" *ngIf="composition.misc">
              <span [ngClass]="getLabelClassViaPropertyValue(composition.misc)">{{'MISC' | transloco}}:&nbsp;</span>
              <span>{{ composition.misc }}</span>
            </p>
            <p id="{{ 'TAGLIST-' + compositionIndex }}" *ngIf="composition.tagList && composition.tagList.length > 0">
              <span [ngClass]="getLabelClassViaPropertyValue(composition.tagList)">{{'TAGS' | transloco}}:&nbsp;</span>
              <span>{{ composition.tagList.join(', ') }}</span>
            </p>
          </div>
        </div>
       <!-- MUSIC INFO -->
        <div *ngIf="selectedTab === 'info'" #infoTabContent id="info-tab-content" class="tab-content">

          <div id="WEBSITES" *ngIf="aovContainer.info.websites && aovContainer.info.websites.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.info.websites)">{{'WEBSITES' | transloco}}:&nbsp;</span>
            <ul class="property-list" *ngFor="let orientation of aovContainer.info.websites;let $index=index;"><li>{{orientation}}</li></ul>
          </div>
          <div id="MUSICPUBLICATIONS" *ngIf="aovContainer.info.musicPublications && aovContainer.info.musicPublications.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.info.musicPublications)">{{'MUSICPUBLICATIONS' | transloco}}:&nbsp;</span>
            <ul class="property-list" *ngFor="let ordination of aovContainer.info.musicPublications;let $index=index;"><li>{{ordination}}</li></ul>
          </div>
          <div id="MUSICALINFLUENCES" *ngIf="aovContainer.info.musicalInfluences && aovContainer.info.musicalInfluences.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.info.musicalInfluences)">{{'MUSICALINFLUENCES' | transloco}}:&nbsp;</span><br>
            <ul class="property-list" *ngFor="let musicalInfluence of aovContainer.info.musicalInfluences;let $index=index;"><li>{{musicalInfluence}}</li></ul>
          </div>
          <div id="ADDITIONALINFLUENCES" *ngIf="aovContainer.info.additionalInfluences && aovContainer.info.additionalInfluences.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.info.additionalInfluences)">{{'ADDITIONALINFLUENCES' | transloco}}:&nbsp;</span>
            <ul class="property-list" *ngFor="let pub of aovContainer.info.additionalInfluences;let $index=index;"><li>{{pub}}</li></ul>
          </div>
          <div id="MAJORCONCERTSANDAPPEARANCES" *ngIf="aovContainer.info.majorConcertsAndAppearances && aovContainer.info.majorConcertsAndAppearances.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.info.majorConcertsAndAppearances)">{{'MAJORCONCERTSANDAPPEARANCES' | transloco}}:&nbsp;</span>
            <ul class="property-list" *ngFor="let founding of aovContainer.info.majorConcertsAndAppearances;let $index=index;"><li>{{founding}}</li></ul>
          </div>
        </div>
        <!-- PROFILE -->
        <div *ngIf="selectedTab === 'bio'" #bioTabContent id="bio-tab-content" class="tab-content">

          <div id="ORIENTATIONLIST" *ngIf="aovContainer.biography.orientationList && aovContainer.biography.orientationList.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.biography.orientationList)">{{'ORIENTATIONLIST' | transloco}}:&nbsp;</span>
            <ul class="property-list" *ngFor="let orientation of aovContainer.biography.orientationList;let $index=index;"><li>{{orientation}}</li></ul>
          </div>
          <div id="ORDINATIONLIST" *ngIf="aovContainer.biography.ordinationList && aovContainer.biography.ordinationList.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.biography.ordinationList)">{{'ORDINATIONLIST' | transloco}}:&nbsp;</span>
            <ul class="property-list" *ngFor="let ordination of aovContainer.biography.ordinationList;let $index=index;"><li>{{ordination}}</li></ul>
          </div>
<!--          <div *ngIf="aovContainer.info.organizationalMemberships && aovContainer.info.organizationalMemberships.length > 0">-->
<!--            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.info.organizationalMemberships)">{{'ORGANIZATIONALMEMBERSHIPS' | transloco}}:&nbsp;</span>-->
<!--            <ul class="property-list" *ngFor="let edu of aovContainer.info.organizationalMemberships;let $index=index;"><li>{{edu}}</li></ul>-->
<!--          </div>-->
          <div id="ORGANIZATIONALMEMBERSHIPS" *ngIf="aovContainer.biography.organizationalMemberships && aovContainer.biography.organizationalMemberships.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.biography.organizationalMemberships)">{{'ORGANIZATIONALMEMBERSHIPS' | transloco}}:&nbsp;</span>
            <ul class="property-list" *ngFor="let orgMember of aovContainer.biography.organizationalMemberships;"><li>{{orgMember}}</li></ul>
          </div>
          <div id="EDUCATIONLIST" *ngIf="aovContainer.biography.educationList && aovContainer.biography.educationList.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.biography.educationList)">{{'EDUCATIONLIST' | transloco}}:&nbsp;</span>
            <ul class="property-list" *ngFor="let edu of aovContainer.biography.educationList;let $index=index;"><li>{{edu}}</li></ul>
          </div>
          <div id="HONORSYEAR" *ngIf="aovContainer.biography.honorsYear && aovContainer.biography.honorsYear.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.biography.honorsYear)">{{ 'HONORS' | transloco }}:&nbsp;</span>
            <ul class="property-list" *ngFor="let honor of aovContainer.biography.honorsYear;let $index=index;"><li>{{honor}}</li></ul>
          </div>
          <div id="PUBLICATIONSAUTHOREDITOR" *ngIf="aovContainer.biography.publicationsAuthorEditor && aovContainer.biography.publicationsAuthorEditor.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.biography.publicationsAuthorEditor)">{{'PUBLICATIONSAUTHOREDITOR' | transloco}}:&nbsp;</span>
            <ul class="property-list" *ngFor="let pub of aovContainer.biography.publicationsAuthorEditor;let $index=index;"><li>{{pub}}</li></ul>
          </div>
          <div id="ACADEMICAPPOINTMENTS" *ngIf="aovContainer.biography.academicAppointments && aovContainer.biography.academicAppointments.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.biography.academicAppointments)">{{'ACADEMICAPPOINTMENTS' | transloco}}:&nbsp;</span>
            <ul class="property-list" *ngFor="let academicAppointment of aovContainer.biography.academicAppointments;let $index=index;"><li>{{academicAppointment}}</li></ul>
          </div>
          <div id="FOUNDINGS" *ngIf="aovContainer.biography.foundings && aovContainer.biography.foundings.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.biography.foundings)">{{'FOUNDINGLIST' | transloco}}:&nbsp;</span>
            <ul class="property-list" *ngFor="let founding of aovContainer.biography.foundings;let $index=index;"><li>{{founding}}</li></ul>
          </div>
          <div id="JEWISHCOMMUNAL" *ngIf="aovContainer.biography.jewishCommunal && aovContainer.biography.jewishCommunal.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.biography.jewishCommunal)">{{'JEWISHCOMMUNAL' | transloco}}:&nbsp;</span>
            <ul class="property-list" *ngFor="let communal of aovContainer.biography.jewishCommunal;let $index=index;"><li>{{communal}}</li></ul>
          </div>
          <div id="OTHERCAREER" *ngIf="aovContainer.biography.otherCareer && aovContainer.biography.otherCareer.length > 0">
            <span [ngClass]="getLabelClassViaPropertyValue(aovContainer.biography.otherCareer)">{{'OTHERCAREER' | transloco}}:&nbsp;</span>
            <ul class="property-list" *ngFor="let activity of aovContainer.biography.otherCareer;let $index=index;"><li>{{activity}}</li></ul>
          </div>
        </div>

      </div>
      <!-- TAB CONTENT END -->

    </div>
    <!-- Person Page Content END -->

  </div>
</div>
