import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {WaiSettingsService} from "../../../components/wai-menu/wai-settings.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {Settings} from "../../../model/Settings";
import {MynSearchModel} from "../../../service/myn/myn-search-model";
import {MynService} from "../../../service/myn/myn-service";

@Component({
  selector: 'myn-index',
  templateUrl: './myn-index.component.html',
  styleUrls: ['./myn-index.component.css']
})
export class MynIndexComponent implements OnInit, OnDestroy {

  @Input() isLang!: Function;

  indexKeys = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J' ,'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  NO_PERSON_KEY = 'NO_NAME';

  allMynMap: any = [];
  mynMap: any[] | undefined;
  mynMapKeyList: any[] = [];
  selectedIndexKey!: string;
  baseUrl!: string;

  settings!: Settings;
  constructor(private searchModel: MynSearchModel,
              private mynService: MynService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public settingsService: WaiSettingsService) {
    this.baseUrl = environment.clientBaseUrl;
    this.selectedIndexKey = this.activatedRoute.snapshot.queryParams['key'];
    this.mynMapKeyList = this.indexKeys.sort();
    this.showMynContainerDict(this.selectedIndexKey);
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit(): void { }

  hasIndexDataForKey(key: string) {
    let result = false;
    if(key && this.allMynMap.hasOwnProperty(key))
    {
      let tempList: any[] = this.allMynMap[key];
      if(null != tempList && 0 < tempList.length){
        result = true;
      }
    }
    return result;
  }

  open(mynContainer: any){
    this.router.navigate(['myn-detail'], { queryParams: { identifier: mynContainer.identifier }});
  }

  putIntoBasket(identifier: string) {
    this.mynService.putIntoBasket(identifier);
  }

  showIndexAt(dictKey: string) {
    this.router.navigate(['myn'], { queryParams: { tab: 'mynidx', key: dictKey }});
  }

  showMynContainerDict(key: string | null) {
    let that = this;
    let personMap = this.searchModel.getMynMap();
    if(key){
      if(personMap && Object.keys(personMap).length > 0) {
        this._mynMapHandler(personMap, key);
      }
      else {
        this.mynService.getAllMynCantorList(function (map: any) {
          that._mynMapHandler(map, key);
        });
      }
    }
  }

  showMynMapContentViaKey(key: string) {
    this.selectedIndexKey = key;
    this.mynMap = [];
    let tempMap: [] = this.searchModel.getMynMapViaKey(key);
    if(tempMap){
      this.mynMap = tempMap.sort(this._sortMynMap);
    }
  }


  _sortMynMap(a: any, b: any) {
    if (a.cantor.lastName < b.cantor.lastName) {
      return -1;
    }
    if (a.cantor.lastName > b.cantor.lastName) {
      return 1;
    }
    return 0;
  }

  _mynMapHandler(mynMap: any, key: string) {
    this.searchModel.setMynMap(mynMap);
    this.allMynMap = this.searchModel.getMynMap();
    let tempMap = this.allMynMap;
    if(!key){
      this.selectedIndexKey = this.mynMapKeyList[0];
    }
    else{
      this.selectedIndexKey = key.toUpperCase();
    }

    if(this.selectedIndexKey){
      let tempMapSelection: any[] = tempMap[this.selectedIndexKey];
      if(null != tempMapSelection && tempMapSelection.length > 0){
        this.mynMap = tempMapSelection.sort(this._sortMynMap);
      }
    }
    this.settings = this.settingsService.getSettings();
    this.settingsService.setSettings(this.settings);
  }

  ngOnDestroy(): void {
    //this.routeChangeSubscription.unsubscribe();
  }
}
