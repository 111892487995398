import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {LocalStorageService} from "./local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class ApplicationStateService {

  execLanguageChange: Subject<string> = new Subject<string>();
  execRouteChange: Subject<any> = new Subject<any>();

  currentRoute: string | undefined;
  previousUrl: string | undefined;

  constructor(private localStorageService: LocalStorageService) {}

  languageChange(languageCode: string){
    this.localStorageService.setLanguage(languageCode);
    this.execLanguageChange.next(languageCode);
  }

  routeChange(data: string) {
    this.previousUrl = this.currentRoute;
    this.currentRoute = data;
    this.execRouteChange.next(data);
  }

  public getPreviousUrl(){
    return this.previousUrl;
  }

}
