<div>
    <button mat-icon-button color="accent" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item [class.active-lang-button]="isLang('de')" (click)="changeLanguage('de')">
            <mat-icon>language</mat-icon>
            <span>DE</span>
        </button>
        <button mat-menu-item [class.active-lang-button]="isLang('en')" (click)="changeLanguage('en')">
            <mat-icon>language</mat-icon>
            <span>EN</span>
        </button>
    </mat-menu>
</div>
