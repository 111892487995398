import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";


@Injectable({
  providedIn: 'root',
})
export class ServiceClient {

  constructor(private httpClient: HttpClient) {}

  getAllAovPersonList() {
    return this.httpClient.get(environment.apiBaseUrl + 'aov/all');
  }

  getAllAovPersonBaseList() {
    return this.httpClient.get(environment.apiBaseUrl + 'aov/all/base');
  }

  getAovContainerViaIdentifierList(identifierList: string[]) {
    return this.httpClient.post(environment.apiBaseUrl + 'aov/list', identifierList);
  }

  performAovSearch(searchParameter: any) {
    return this.httpClient.post(environment.apiBaseUrl + 'aov/search/expert', searchParameter);
  }

  performAovTextSearch(searchStr: string) {
    return this.httpClient.post(environment.apiBaseUrl + 'aov/search/simple', searchStr);
  }

  getAllSoSyPersonList() {
    return this.httpClient.get(environment.apiBaseUrl + 'person/all');
  }

  getAllPersonBaseList() {
    return this.httpClient.get(environment.apiBaseUrl + 'person/all/base');
  }

  getPersonContainerViaIdentifierList(identifierList: string[]) {
    return this.httpClient.post(environment.apiBaseUrl + 'person/list', identifierList);
  }

  performPersonSearch(searchParameter: any) {
    return this.httpClient.post(environment.apiBaseUrl + 'person/search/expert', searchParameter);
  }

  performPersonTextSearch(searchStr: string) {
    return this.httpClient.post(environment.apiBaseUrl + 'person/search/simple', searchStr);
  }

  submitContact(contactPayload: any) : any {
    return this.httpClient.post(environment.apiBaseUrl + 'aov/carryover', contactPayload);
  }

  // region MYN
  getAllMynList() {
    return this.httpClient.get(environment.apiBaseUrl + 'myn/all');
  }
  getAllMynCantorList() {
    return this.httpClient.get(environment.apiBaseUrl + 'myn/all/base');
  }
  getMynContainerViaIdentifierList(identifierList: string[]) {
    return this.httpClient.post(environment.apiBaseUrl + 'myn/list', identifierList);
  }

  performMynSearch(searchParameter: any) {
    return this.httpClient.post(environment.apiBaseUrl + 'myn/search/expert', searchParameter);
  }
  performMynTextSearch(searchStr: string) {
    return this.httpClient.post(environment.apiBaseUrl + 'myn/search/simple', searchStr);
  }
  // endregion MYN



}


