import { Component, OnInit } from '@angular/core';
import { TranslocoService } from "@ngneat/transloco";
import { FormGroup, FormControl } from '@angular/forms';
import { Contact } from "../../model/Contact";
import { contactFormGroup } from '../../model/ContactFormGroup';
import { WaiSettingsFacadeService } from "../wai-menu/wai-settings-facade.service";
import { Settings } from "../../model/Settings";
import { ServiceClient } from "../../service/service-client";
import {WaiSettingsService} from "../wai-menu/wai-settings.service";
import {ActivatedRoute, Params, Router} from "@angular/router";


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  private language: string;
  contact: Contact;
  settings: Settings;
  contactForm = new FormGroup<contactFormGroup>({
    name: new FormControl<string>('', { nonNullable: true }),
    email: new FormControl<string>('', { nonNullable: true}),
    subject: new FormControl<string | null>('', { nonNullable: false}),
    content: new FormControl<string | null>('', { nonNullable: false })
  });
  constructor(
      private translateService: TranslocoService,
      protected settingsModel: WaiSettingsFacadeService,
      public settingsService: WaiSettingsService,
      private serviceClient: ServiceClient,
      private activatedRoute: ActivatedRoute,
      private router: Router
  ) {
    this.contact = { name: '', email:'', subject:'', content: ''};
    this.settings = this.settingsModel.getSettings()
    this.language = this.translateService.getActiveLang();
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const parameter = params['lang'];
      if (!parameter && this.language) {
        const queryParams: any = {};
        queryParams['lang'] = this.language;
        this.router.navigate([], {
          queryParams: queryParams,
          queryParamsHandling: 'merge'
        });
      }});
  }

  ngOnInit(): void {}

  isLang(lang: string){
    return lang === this.translateService.getActiveLang();
  }

  resetSubmitContactForm(){
    this.contactForm.reset();
  }

  submit() {
    this.contact.name = this.contactForm.value.name ;
    this.contact.email = this.contactForm.value.email;
    this.contact.subject = null != this.contactForm.value.subject ? this.contactForm.value.subject : '';
    this.contact.content = null != this.contactForm.value.content ? this.contactForm.value.content : '';
    this.serviceClient.submitContact(this.contact).subscribe((response: any) => {
      console.log(response);
      this.contactForm.reset();
    });
  }
}
