import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TopbarComponent} from "./components/passepartout/topbar/topbar.component";
import {DomSanitizer, Title} from "@angular/platform-browser";
import {LocalStorageService} from "./service/local-storage.service";
import {WaiSettingsService} from "./components/wai-menu/wai-settings.service";
import {PassepartoutComponent} from "./components/passepartout/passepartout.component";
import {MatIconRegistry} from "@angular/material/icon";
import {getBrowserLang, LangDefinition, TranslocoService} from "@ngneat/transloco";
import {take} from "rxjs/operators";
import {Subscription} from "rxjs";
import {ApplicationStateService} from "./service/application-state.service";
import {HttpParams} from "@angular/common/http";
import {ActivatedRoute, Params, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'sosy-web';

  LANGUAGE_CODE_DE: string;
  LANGUAGE_CODE_EN: string;
  SUPPORTED_LANGUAGES: string[];
  LOCALSTORAGE_LANGUAGE: string;
  public language: string | null | undefined;
  selectedLanguage: any;
  availableLangs: LangDefinition[];
  private subscription: Subscription = Subscription.EMPTY;

  isInit: boolean = true;
  showDesktopLayout: boolean = true;

  public screenWidth: any;
  public screenHeight: any;

  @ViewChild(PassepartoutComponent) passepartoutComponent!: PassepartoutComponent;
  constructor(
    private el: ElementRef,
    private localStorageService: LocalStorageService,
    private settingsService: WaiSettingsService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translocoService: TranslocoService,
    private appContext: ApplicationStateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  )
  {
    this.availableLangs = this.translocoService.getAvailableLangs() as LangDefinition[];
    this.selectedLanguage = localStorageService.getLanguage();
    this.language = getBrowserLang();
    this.language = (null != this.selectedLanguage && this.selectedLanguage != this.language) ? this.selectedLanguage : this.language;
    this.LANGUAGE_CODE_DE = 'de';
    this.LANGUAGE_CODE_EN = 'en';
    this.LOCALSTORAGE_LANGUAGE = 'SOSY_LANG';
    this.SUPPORTED_LANGUAGES = [this.LANGUAGE_CODE_DE, this.LANGUAGE_CODE_EN];
    if(this.language)
    {
      this.selectedLanguage = this.language;
      if (this.translocoService.getActiveLang() !== this.selectedLanguage) {
        this.translocoService.setActiveLang(this.selectedLanguage);
      }
    }
    else
    {
      this.selectedLanguage = this.LANGUAGE_CODE_DE;
    }

    const urlParamLang = this.getParamValueQueryString('lang')
    if(urlParamLang && (urlParamLang.toLowerCase() === 'de' || urlParamLang.toLowerCase() === 'en')){
      this.selectedLanguage = urlParamLang.toLowerCase();
      this.translocoService.setActiveLang(this.selectedLanguage);
      this.change(this.selectedLanguage)
    }

    this.activatedRoute.queryParams.
      subscribe((params: Params) => {
          const parameter = params['lang'];
          if (!parameter && this.selectedLanguage) {
            const queryParams: any = {};
            queryParams['lang'] = this.selectedLanguage;
            // this.router.navigate([], {
            //   queryParams: queryParams,
            //   queryParamsHandling: 'merge'
            // });
          }
      });

    this.matIconRegistry.addSvgIcon(
      `recorder-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/svg/Recorder.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `miriam-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/svg/Miriam.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `haim-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/svg/Haim.svg")
    );
  }

  getParamValueQueryString( paramName: string ): string|null {
    const url = window.location.href;
    let paramValue = null;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

  change(lang: string) {
    console.log(lang);
    this.localStorageService.setLanguage(lang);
    // Ensure new active lang is loaded
    this.subscription.unsubscribe();
    this.subscription = this.translocoService
        .load(lang)
        .pipe(take(1))
        .subscribe(() => {
          this.translocoService.setActiveLang(lang);
        });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    console.log('OnInit Screen Size: width=' + this.screenWidth + ' height='+this.screenHeight );
    this.showDesktopLayout = this.isDesktopLayout();
    console.log('showDesktopLayout = ' + this.showDesktopLayout)

    if (navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0) {
      document.getElementsByTagName('BODY')[0].className += ' safari';
    }
    if(navigator.platform.indexOf('Mac') >=0 &&
      navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ){
      document.getElementsByTagName('BODY')[0].className += ' macfox';
    }
    let passepartout = this.el.nativeElement.querySelector('#passepartout');
    if(passepartout){
      passepartout.style.setProperty('display', '');
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    console.log('onWindowResize Screen Size: width=' + this.screenWidth + ' height='+this.screenHeight );
    this.showDesktopLayout = this.isDesktopLayout();
    console.log('showDesktopLayout = ' + this.showDesktopLayout)
  }

  isDesktopLayout(): boolean {
    const hasMinimalDesktopScreenWidth = this.screenWidth > 1002;
    console.log('hasDesktopWidth = ' + hasMinimalDesktopScreenWidth)
    const isLandscape = this.screenWidth > this.screenHeight
    console.log('isLandscape = ' + isLandscape)
    let isDesktopVersion = hasMinimalDesktopScreenWidth && isLandscape;
    console.log('isDesktopVersion = ' + isDesktopVersion)
    return isDesktopVersion;
  }

  ngAfterViewInit(): void {
    this.appContext.languageChange(this.selectedLanguage);
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    console.log('AfterViewInit Screen Size: width=' + this.screenWidth + ' height='+this.screenHeight );
    let settings = this.settingsService.getSettings();
    // set initial / reloaded settings
    if(this.isInit){
      this.initialiseSettings();
      this.settingsService.setSettings(settings);
      this.isInit = false;
    }
    let passepartout = this.el.nativeElement.querySelector('#passepartout');
    if(passepartout){
      passepartout.style.setProperty('display', '');
    }
  }

  initialiseSettings(){
    // set initial / reloaded language
    let language; //this.localStorageService.get(LOCALSTORAGE_LANGUAGE);
    let isInitialized; // = !common.isNullOrUndefined(language) && SUPPORTED_LANGUAGES.indexOf(language) > -1;

    if(!isInitialized){
      language = this.LANGUAGE_CODE_DE;
      this.localStorageService.setLanguage(language);
    }
  }
}
