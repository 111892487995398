import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  CdkMenuItemRadio,
  CdkMenuItemCheckbox,
  CdkMenuGroup,
  CdkMenu,
  CdkMenuTrigger,
  CdkMenuItem,
  CdkMenuBar,
} from '@angular/cdk/menu';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.css'],
  standalone: true,
  imports: [
    CdkMenuBar,
    CdkMenuItem,
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuGroup,
    CdkMenuItemCheckbox,
    CdkMenuItemRadio,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
  ],
})
export class MenuBarComponent implements OnInit, OnChanges {

  @Output() changeLanguageEvent = new EventEmitter<string>();
  private language: string;

  constructor(private translocoService: TranslocoService) {
    this.language = this.translocoService.getActiveLang();
  }

  ngOnInit(): void {
    console.log(this.language);
  }

  isLang(lang: string){
    return lang === this.translocoService.getActiveLang();
  }
  changeLanguage(lang: string) {
    this.changeLanguageEvent.next(lang);
    this.language = lang;
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("changes:1" + changes);
  }
}
