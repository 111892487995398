<div id="search-input" class="search_clip" style="position: relative;top: 0px;">
  <input id="search"
         class="search-input"
         type="text"
         [(ngModel)]="searchStr"
         (keyup)="$event.code=='Enter' && onKey($event.code)"
         placeholder="{{'SEARCH_INPUT_PLACEHOLDER' | transloco}}"
         autofocus>
</div>
<button mat-flat-button
        color="primary"
        class="search-btn"
        (click)="inputChangeHandler(searchStr)">
  <mat-icon>search</mat-icon>
</button>
