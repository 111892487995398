import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { filter, take } from "rxjs/operators";
import { LocalStorageService } from "../../service/local-storage.service";
import { Subscription } from "rxjs";
import { LangDefinition, TranslocoService } from "@ngneat/transloco";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { ApplicationStateService } from "../../service/application-state.service";
import {TopbarComponent} from "./topbar/topbar.component";

@Component({
  selector: 'passepartout',
  templateUrl: './passepartout.component.html',
  styleUrls: ['./passepartout.component.css']
})
export class PassepartoutComponent implements OnInit, OnDestroy, AfterViewInit {

  LANGUAGE_CODE_DE: string;
  LANGUAGE_CODE_EN: string;
  SUPPORTED_LANGUAGES: string[];
  LOCALSTORAGE_LANGUAGE: string;
  language: string | null;
  selectedLanguage: any;
  availableLangs: LangDefinition[];

  currentRoute: string | undefined;
  private _subscription_route: Subscription;
  private subscription: Subscription = Subscription.EMPTY;

  showMyNusach: boolean;
  @ViewChild(TopbarComponent) topBarComponent!: TopbarComponent;
  constructor(private router: Router,
              private appContext: ApplicationStateService,
              private localStorageService: LocalStorageService,
              private translocoService: TranslocoService) {

    this._subscription_route = this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: RouterEvent) => {
        this.currentRoute = event.url;
        this.appContext.routeChange(this.currentRoute);
        console.log(event.url);
      });

    let showMyn = localStorageService.getShowMyNusach();
    this.showMyNusach = null != showMyn && 'TRUE' === showMyn;

    this.availableLangs = this.translocoService.getAvailableLangs() as LangDefinition[];

    this.language = localStorageService.getLanguage();
    this.LANGUAGE_CODE_DE = 'de';
    this.LANGUAGE_CODE_EN = 'en';
    this.LOCALSTORAGE_LANGUAGE = 'SOSY_LANG';
    this.SUPPORTED_LANGUAGES = [this.LANGUAGE_CODE_DE, this.LANGUAGE_CODE_EN];

    if(this.language)
    {
      this.selectedLanguage = this.language;
      if (this.translocoService.getActiveLang() !== this.selectedLanguage) {
        this.translocoService.setActiveLang(this.selectedLanguage);
      }
    }
    else
    {
      this.selectedLanguage = this.LANGUAGE_CODE_DE;
    }
  }

  ngAfterViewInit(){
    this.appContext.languageChange(this.selectedLanguage);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._subscription_route.unsubscribe();
    this.subscription.unsubscribe();
  }

  change(lang: string) {
    console.log(lang);
    this.localStorageService.setLanguage(lang);
    // Ensure new active lang is loaded
    this.subscription.unsubscribe();
    this.subscription = this.translocoService
      .load(lang)
      .pipe(take(1))
      .subscribe(() => {
        this.translocoService.setActiveLang(lang);
      });
  }
}
