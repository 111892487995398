import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-mizrahim',
  templateUrl: './mizrahim.component.html',
  styleUrls: ['./mizrahim.component.css']
})
export class MizrahimComponent implements OnInit {

  @Input() isLang!: Function;
  constructor() { }

  ngOnInit(): void {
  }

}
