<div id="search-input-fields">
  <div id="first-search-input" class="search_clip">
    <input id="first-search"
           class="search-input"
           [ngClass]="settingsService.settings.backgroundColorOption === 'BACKGROUND_OPTION_BLACK' ? 'theme-color-dark' : 'theme-color-light'"
           type="text"
           [(ngModel)]="firstSearchValue"
           (keyup)="$event.code=='Enter' && onKey($event.code)"
           placeholder="{{'SEARCH_INPUT_PLACEHOLDER' | transloco}}"
           autofocus>
  </div>
  <button mat-stroked-button mat-flat-button color="primary"
          class="search-btn"
          [ngClass]="settingsService.settings.backgroundColorOption === 'BACKGROUND_OPTION_BLACK' ? 'theme-color-dark' : 'theme-color-light'"
          (click)="performSearch()">
    <mat-icon>search</mat-icon>
  </button>
  <div class="clear"></div>
  <div id="searchOpBool" class="tab" style="">
    <input type="radio" value="|" [(ngModel)]="operator" name="bool_operator"><span class="radio_label">{{'OR'|transloco}}</span>&nbsp;
    <input type="radio" value="+" [(ngModel)]="operator" name="bool_operator"><span class="radio_label">{{'AND'|transloco}}</span>
  </div>
  <div id="second-search-input" class="search_clip">
    <input id="second-search"
           class="search-input"
           [ngClass]="settingsService.settings.backgroundColorOption === 'BACKGROUND_OPTION_BLACK' ? 'theme-color-dark' : 'theme-color-light'"
           type="text"
           [(ngModel)]="secondSearchValue"
           (keyup)="$event.code=='Enter' && onKey($event.code)"
           placeholder="{{'SEARCH_INPUT_PLACEHOLDER' | transloco}}"
           autofocus>
  </div>
  <button mat-stroked-button mat-flat-button color="primary"
          class="search-btn"
          [ngClass]="settingsService.settings.backgroundColorOption === 'BACKGROUND_OPTION_BLACK' ? 'theme-color-dark' : 'theme-color-light'"
          (click)="performSearch()">
    <mat-icon>search</mat-icon>
  </button>
</div>
<div class="input-grid">
  <div class="column-1">
    <form id="base_filter" style="position: relative;margin: 18px 0 9px 0;">
      <fieldset id="base_filter_set">
        <legend class="category-checkbox-legend">{{'MYN_MASTER_DATA' | transloco}}
          <input class="category-checkbox-input"
                 type="checkbox"
                 name="base_filter_set"
                 value="MASTER_DATA"
                 [checked]="isChecked(masterdataFieldParams)"
                 (click)="setSelectedCategory('MASTERDATA', masterdataFieldParams)">
        </legend>
        <div>
          <label class="checkbox-label" *ngFor="let field of getSearchFieldListViaKey('MASTERDATA')" >
            <input type="checkbox"
                   name="masterdataFieldParams[]"
                   [checked]="-1 < getSelectedFieldIndex(field, masterdataFieldParams)"
                   (change)="setSelectedField(field, masterdataFieldParams)"> {{field | transloco}}
          </label>
        </div>
      </fieldset>
    </form>
  </div>
  <div class="column-2">
    <form id="media_filter" style="position: relative;margin: 18px 0 9px 0;">
      <fieldset id="media_filter_set">
        <legend class="category-checkbox-legend">{{'MYNUSACH' | transloco}}
          <input class="category-checkbox-input"
                 type="checkbox"
                 value=""
                 name="base_filter_set"
                 [checked]="isChecked(mediaFieldParams)"
                 (click)="setSelectedCategory('COMPOSITION', mediaFieldParams)">
        </legend>
        <label class="checkbox-label" *ngFor="let field of getSearchFieldListViaKey('COMPOSITION')" >
          <input type="checkbox"
                 value="field"
                 name="selectedFields"
                 [checked]="-1 < getSelectedFieldIndex(field, mediaFieldParams)"
                 (click)="setSelectedField(field, mediaFieldParams)"> {{field | transloco}}</label>
      </fieldset>
    </form>
  </div>
</div>
