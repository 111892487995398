<div class="dict-index">
    <div class="dict-index-item" *ngFor="let key of mynMapKeyList | orderBy: 'key'"
          id="{{'index-' + key}}"
          [ngClass]="[selectedIndexKey === key ? 'persondict-index-key active' : 'persondict-index-key',]"
          style="margin: 0;">
        <div class="link-box" style="position:relative;float:left;top:0;">
            <a *ngIf="hasIndexDataForKey(key)"
               class="index-link" [ngClass]="settingsService.settings.arrowOption === 'ARROW_OPTION_WHITE' ? '.custom-pointer-white' : settingsService.settings.arrowOption === 'ARROW_OPTION_BLACK' ? 'custom-pointer-black' : ''"
               name="{{key}}"
               (click)="showIndexAt(key)"
               [routerLink]="['/myn']"
               [queryParams]="{tab: 'mynidx', key: key}">{{key}}
            </a>
            <a *ngIf="!hasIndexDataForKey(key)"
               class="index-link inactive readonly"
               id="{{key}}"
               style="color:#ccc;">{{key}}
            </a>
        </div>
    </div>
</div>
<div id="index-component-list">
    <mat-list class="" style="margin-left: -1rem">
        <mat-list-item class="" *ngFor="let keyValue of mynMap" lines="3">
            <a matListItemTitle
               [ngClass]="settingsService.settings.arrowOption === 'ARROW_OPTION_WHITE' ? '.custom-pointer-white' : settingsService.settings.arrowOption === 'ARROW_OPTION_BLACK' ? 'custom-pointer-black' : ''"
               style="padding: 0 0 8px 0;"
               [routerLink]="['/myn-detail']"
               [queryParams]="{
                   identifier: keyValue.cantor.identifier,
                   tab: 'masterdata'
                }">{{keyValue.cantor.lastName + ', ' + keyValue.cantor.firstName}}
            </a>
            <span class="index-list-item-description" [ngStyle]="{color: settingsService.settings.backgroundColorOption === 'BACKGROUND_OPTION_BLACK' ? '#fff' : ''}">{{keyValue.cantor.identifier}}</span>
        </mat-list-item>
    </mat-list>
</div>
