<div id="enzyklopedia_view">
  <div id="enzyklopedia-data">
    <!-- DE -->
    <div *ngIf="isLang('de')">
      <h2>Romanioten</h2>
      <p>
        Romaniotische Juden leben seit vermutlich über 2000 Jahren in Griechenland. Das Romaniotische Judentum wurde stets durch die
        jeweiligen Mehrheitsgesellschaften und Mittelmeerkulturen beeinflusst. Zu diesen Begegnungen zählen Kontakte mit nicht-jüdischen Gemeinschaften,
        wie beispielsweise den muslimischen Gemeinden während der osmanischen Herrschaft, sowie innerjüdische Kontakte zwischen Romanioten und ladinosprachigen Sepahrdim,
        die sich in der Folge ihrer Vertreibung durch die Spanische Inquisition in Griechenland niedergelassen hatten.
      </p><p>
      Der Name 'Romanioten' ist von dem Wort 'Romania' abgeleitet, der lateinischen Bezeichnung für das Oströmische Reich, das ab 330 n. Chr. Byzanz hieß.
      Im Verlauf ihrer langen Geschichte namen die Romaniotischen Juden viele Gebräuche und Traditionen der griechischen Zivilisation an,
      behielten dabei aber ihre jüdische Identität bei. Die Romanioten durchlebten etliche Jahrhunderte unter verschiedenen Herrschern und Regentschaften -
      die römische Epoche (146 v. Chr. - 330 n. Chr.), die byzantinische Epoche (331 - 1082), die Herrschaft der Franken (1082 - 1204), die venezianische Epoche (1205 - 1453),
      das osmanische Reich (1453 - 1913) und den Aufstieg des griechischen Nationalismus.
    </p><p>
      Ŵährend der Besetzung Griechenlands durch die Nazis wurde die Romaniotische Bevölkerung nahezu komplett ausgelöscht.
      Gemeinden wie die von Ioannina litten unter Verlusten von 91% ihrer Mitglieder. Im März des Jahres 1944 wurden 1860 Juden aus Ionannina nach Auschwitz deportiert.
      Von diesen Männern, Frauen und Kindern überlebten etwa 163 Erwachsene, nur die wenigsten von ihnen kehrten nach Griechenland zurück.
      Heutzutage leben die wenigen Nachkommen der Romanioten hauptsächlich in Israel und den USA.
    </p><p>
      Bis in das sechzehnte Jahrhundert hinein verwendeten die Romaniotischen Juden den Machzor Romania, der im byzantinischen Reich entstanden war,
      und die älteste Zusammenstellung von Gebeten und paraliturgischen Hymnen in Europa darstellt.
      Das soziale und spirituelle Zentrum der Romanioten war stets Ioannina in der Provinz Epirus in Nordwestgriechenland.
    </p><p>
      Romaniotische Juden waren jahrhundertelang hauptsächlich Händler, Kaufleute und Handwerker.
      Zu ihren gesellschaftlichen Gepflogenheiten zählten arrangierte Ehen, große Familien und eine orthodoxe Befolgung jüdischer Bräuche,
      im Rahmen derer Männer die Führungsposition der Gemeinden inne hatten und die Geldverdiener waren, alldieweil Frauen die traditionellen Rollen des Hauswirtschaftens
      und der Kindeserziehung übernahmen.
    </p><p>
      Sowohl die romaniotische Volks- als auch die liturgische Musik entstammen einer langlebigen, auditiven jüdischen Musiktradition.
      Bisher wurde nur sehr wenig über diese Musik geschrieben. Gesichert ist, dass es ein ausgeprägtes jüdisches Repertoire an Synagogenmusik gab,
      das sich deutlich von den aschkenasischen, sephardischen und mizrahischen (Nahost und Nordafrika) Repertoires unterscheidet.
    </p><p>
      Romaniotische Juden haben eigene Melodien und Ornamente, die sie in ihren Gebetsgesängen verwenden, sowie besondere Melodien für die Piyyutim und ihre eigene Tradition der Teamim.
      Diese Praktiken wurden in Griechenland über zweitausend Jahre lang entwickelt und verfeinert.
    </p>

      <p>
        Autorin: Miranda Crowdus
        Übersetzt aus dem Englischen von: Samuel Mund
      </p>

      <h5>Weiterführende Literatur:</h5>

      <h6>Musik:</h6>
      <p>Dalven, R. (1990) ‘Three Judeo-Greek Hymns’in The Jews of Ioannina. New York: Cadmus Press.</p>
      <p>Katz, I.J. Greek Musical Traditions</p>

      <h6>Geschichte und Kultur:</h6>
      <p>Bowman, S. (1985). The Jews of Byzantium 1204-1453. Tuscaloosa, Alabama: University of Alabama Press.<p>
      <p>Dalven, R. (1990) The Jews of Ioannina. New York: Cadmus Press.</p>
      <p>Fleming, K.E. (2008) Greece – A Jewish History. Princeton, N.J.: Princeton University Press.</p>
    </div>
    <!-- EN -->
    <div *ngIf="isLang('en')">
      <h2>Romaniotes:</h2>
      <p>
        The Romaniote Jews have resided in Greece and its neighbouring countries for arguably more than 2000 years.
        As such, Romaniote Jewry has been consistently influenced by the greater societies and cultures of the Mediterranean.
        These encounters include contact with non-Jewish communities, such as the Muslim communities under Ottoman rule, as well as intra-Jewish encounters between the
        Romaniotes and the Ladino-speaking Sephardim that settled in Greece following the Spanish Inquisition.
      </p><p>
      Their name is derived from “Romania”, the Latin name for the Eastern Roman Empire,
      which became Byzantium after 330 CE. Throughout their long history they adopted many customs and traditions of Greek civilization while maintaining their Jewish identity.
      Over the centuries they survived under different rulers and regimes - Roman Period (146 BCE-330 CE), Byzantine Period (331-1082),
      the Franks (1082-1204), Venetian Period (1205-1453), Ottoman Empire (1453-1913) and the rise of Greek Nationalism.
    </p><p>
      During the Nazi occupation of Greece, the Romaniote community was almost completely annihilated.
      Communities such as the one in Ioannina suffered losses of 91% of the population. 1,860 Jews from Ioannina were deported to Auschwitz in March of 1944.
      Of those men, women, and children only approximately 163 adults survived and very few returned to Greece.
      Today, the few descendents of the Romaniotes live largely in Israel and the USA. Until the 16th century, the Romaniote Jews used the Machzor Romania,
      which originated during the Byzantine Empire and is the oldest compilation of prayers and para-liturgical hymns in Europe.
    </p><p>
      The social and spiritual capital of the Romaniotes has always been Ioannina in the province of Epirus in northwest Greece.
      Over the centuries, the Romaniote Jews were largely merchants, tradesmen, and craftsmen.
      Their society was characterized by arranged marriages, large families, and orthodox Jewish observance,
      in which men were the community leaders and wage-earners, while women occupied the traditional roles of housekeeping and childrearing.
    </p><p>
      In terms of their music, both Romaniote folk music and liturgical music emanates from a long-lived, aural Jewish musical tradition.
      To date, very little has been written exclusively about the music. What we do know is that there was a distinctive
      Romaniote Jewish repertoire of synagogue music that differs significantly from its Ashkenazi and Sephardic and Mizrahi (Middle Eastern and North African) counterparts.
    </p><p>
      Thus, the Romaniote Jews have their own melodies and ornaments that they use in their prayer chants,
      and particular melodies for their piyyutim and their own tradition of Torah cantillation. These practices were developed and refined in Greece for over two thousand years.
    </p>

      <p>Author: Miranda Crowdus</p>

      <h5>Sources for additional reading (Romaniotes):</h5>

      <h6>Music:</h6>
      <p>Dalven, R. (1990) ‘Three Judeo-Greek Hymns’in The Jews of Ioannina. New York: Cadmus Press.</p>
      <p>Katz, I.J. Greek Musical Traditions</p>

      <h6>History and Culture:</h6>
      <p>Bowman, S. (1985). The Jews of Byzantium 1204-1453. Tuscaloosa, Alabama: University of Alabama Press.</p>
      <p>Dalven, R. (1990) The Jews of Ioannina. New York: Cadmus Press.</p>
      <p>Fleming, K.E. (2008) Greece – A Jewish History. Princeton, N.J.: Princeton University Press.</p>

    </div>
  </div>
</div>
