import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {TranslocoService} from "@ngneat/transloco";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.css']
})
export class RightSidebarComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Output() changeLanguageEvent = new EventEmitter<string>();
  private language: string;

  constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private translocoService: TranslocoService) {
    this.language = this.translocoService.getActiveLang();
  }

  ngOnInit(): void {
    console.log(this.language);
  }

  isLang(lang: string){
    return lang === this.translocoService.getActiveLang();
  }
  changeLanguage(lang: string) {
    this.router.navigate([], { queryParams: {lang: lang},  queryParamsHandling:"merge"});
    this.changeLanguageEvent.next(lang);
    this.language = lang;

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("changes:1" + changes);
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {

  }
}
