<div id="imprint_view">
  <div id="imprint-data" *ngIf="isLang('de')">
    <h1>Impressum</h1>

    <br><div class="contrast"><div class="logo-ezjm"></div></div><br><br>

    <h3>Herausgeber</h3>
    <p>
      Europäisches Zentrum für Jüdische Musik<br>
      der Hochschule für Musik, Theater und Medien Hannover
    </p>

    <p>
      Villa Seligmann<br>
      Hohenzollernstraße 39<br>
      30161 Hannover<br>
    </p>
    <p>
      Telefon <a href="tel:+49511844887100">+49-(0)511-3100-7121</a><br>
      Telefax +49-(0)511-3100-71275<br>
    </p>

    <p>
      Email: <a href (click)="openMail()">EZJM</a><br>
      Vertreten durch die Direktorin des Europäischen Zentrums für Jüdische Musik, Prof. Dr. Sarah M. Ross
    </p>

    <h3>Haftungsausschluss</h3>
    <h4 style="padding-top: 0;">Haftung für Inhalte</h4>
    <p>
      Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
      Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
      gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen,
      die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
      nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt
      der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
    </p>
    <h4>Haftung für Links</h4>
    <p>
      Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
      Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
      Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
      Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
      Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
      Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
      Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
    </p>
    <h4>Urheberrecht</h4>
    <p>
      Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen, soweit nicht anders gekennzeichnet,
      der Lizenz <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/deed.de">Creative Commons 4.0 BY-NC-SA</a>.
      Beiträge dürfen weiterverwendet werden, müssen aber namentlich gekennzeichnet, und dürfen nur unter denselben Bedingungen weitergeben werden.
      Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb
      der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
      Downloads und Kopien dieser Seite sind im Rahmen der CC-Lizenz, nicht aber für den kommerziellen Gebrauch gestattet.
    </p>
    <p>
      Die Betreiber der Seiten sind bemüht, stets die Urheberrechte anderer zu beachten bzw. auf selbst erstellte sowie lizenzfreie Werke zurückzugreifen.
    </p>

    <h3>Datenschutz</h3>
    <p>
      Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst.
      Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
    </p>
    <p>
      Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich.
      Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden,
      erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
    </p>
    <p>
      Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
      Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
    </p>

    <h4>Cookies</h4>
    <p>
      Die Internetseiten verwenden teilweise so genannte Cookies.
      Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
      Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen.
      Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
    </p>
    <p>
      Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen.
      Diese Cookies ermöglichen es uns, Einstellungen und Basket-Einträge zu speichern.
    </p>

    <p>
      Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben,
      die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren.
      Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
    </p>

    <h4>Server-Log-Files</h4>
    <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
    <ul class="no-list-style">
      <li>Browsertyp/ Browserversion</li>
      <li>verwendetes Betriebssystem</li>
      <li>URL</li>
      <li>Uhrzeit der Serveranfrage</li>
      <li>IP Adresse</li>
    </ul>
    <p>
      Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
      Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
    </p>

    <h4>Kontaktformular</h4>
    <p>
      Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der
      von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert.
      Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
    </p>
    <h4>Auskunft, Löschung, Sperrung</h4>
    <p>
      Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung
      sowie ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten.
      Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
    </p>
    <br>
    <h4>Gefördert aus Mitteln der Volkswagen Stiftung (Niedersachsen Vorab).</h4>
    <div class="contrast"><div class="logo-stiftung" style="position: relative;"></div></div>
    <div class=""><div class="logo-sharing-heritage" style="position: relative;"></div></div>
  </div>
  <div id="imprint-data" *ngIf="isLang('en')">
    <h1>About us</h1>

      <br><div class="contrast"><div class="logo-ezjm"></div></div><br><br>

    <p>
      European Center for Jewish Music<br>
      Hannover University of Music, Drama and Media
    </p>

    <p>
      Villa Seligmann<br>
      Hohenzollernstraße 39<br>
      30161 Hanover
    </p>

    <p>
      Phone <a href="tel:+49511844887100">+49-(0)511-3100-7121</a><br>
      Fax +49-(0)511-3100-7127
    </p>

    <p>
      Email: <a href (click)="openMail()">EZJM</a><br>
      Represented by the Director of the European Centre for Jewish Music, Prof. Dr. Sarah M. Ross
    </p>


    <h3>Disclaimer of liability</h3>

    <p>
      The contents of our pages have been prepared with the greatest care. However, we cannot assume any liability for the correctness,
      completeness and topicality of the contents. As a service provider, we are responsible for our own content on these pages in accordance
      with § 7 Section 1 of the German Telemedia Act (TMG). According to §§ 8 to 10 TMG, however, we are not obliged as service providers to monitor
      transmitted or stored third-party information or to investigate circumstances that indicate illegal activity.
      Obligations to remove or block the use of information in accordance with general laws remain unaffected by this.
      However, liability in this respect is only possible from the time of knowledge of a concrete violation of the law.
      As soon as we become aware of such violations of the law, we will remove these contents immediately.
    </p>


    <h4>Liability for links</h4>

    <p>
      Our website contains links to external websites of third parties over whose contents we have no influence.
      Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages
      is always responsible for the contents of the linked pages. The linked pages were checked for possible legal infringements at the time of linking.
      Illegal contents were not recognizable at the time of linking. However, permanent monitoring of the content of the linked pages
      is unreasonable without concrete evidence of a violation of the law. If we become aware of any infringements, we will remove such links immediately.
    </p>

    <h4>Copyright</h4>

    <p>
      The content and works created by the site operators on these pages are subject to the <a href ="https://creativecommons.org/licenses/by-nc-sa/4.0/">Creative Commons 4.0 BY-NC-SA</a> license,
      unless otherwise indicated.
      Contributions may be re-used, but must be identified by name, and may only be passed on under the same conditions.
      Contributions of third parties are marked as such.
      Duplication, editing, distribution and any kind of use outside the limits of copyright law require the written consent of the respective author or creator.
      Downloads and copies of this site are permitted under the CC license, but not for commercial use.
    </p>
    <p>
      The operators of these pages always endeavour to respect the copyrights of others or to make use of self-created or licence-free works.
    </p>

    <h3>Data privacy</h3>

    <p>The operators of these pages take the protection of your personal data very seriously.
      We treat your personal data confidentially and in accordance with the statutory data protection regulations and this data protection declaration.
      The use of our website is generally possible without providing personal data.
      As far as on our sides personal data (for example name, address or e-mail addresses) are raised, this takes place, as far as possible, always on voluntary basis.
      This data will not be passed on to third parties without your express consent.
      We would like to point out that data transmission over the Internet (e.g. communication by e-mail) can have security gaps.
      A complete protection of data against access by third parties is not possible.

    <h4>Cookies</h4>

    <p>
      Some of the Internet pages use so-called cookies. Cookies do not cause any damage to your computer and do not contain any viruses.
      Cookies serve to make our website more user-friendly, effective and secure. Cookies are small text files that are stored on your computer and saved by your browser.
      Cookies remain stored on your end device until you delete them. These cookies allow us to save settings and basket entries.
      You can set your browser so that you are informed about the setting of cookies and only allow cookies in individual cases,
      exclude the acceptance of cookies for certain cases or generally and activate the automatic deletion of cookies when closing the browser.
      When cookies are deactivated, the functionality of this website may be limited.
    </p>

    <h4>Server log files</h4>
    <p>The provider of the pages automatically collects and stores information in so-called server log files, which your browser automatically transmits to us. These are:</p>
    <ul class="no-list-style">
      <li>Browser type/ browser version</li>
      <li>Operating system used</li>
      <li>Uniform Resource Locators</li>
      <li>Time of the server request</li>
      <li>IP address</li>
    </ul>

    <p>These data are not combined with other data sources. We reserve the right to check these data subsequently if we become aware of concrete indications of an illegal use.</p>
    <h4>Contact form</h4>
    <p>If you send us enquiries using the contact form, your details from the enquiry form will be included.</p>
    <br>
    <h4>
      Funded by the Volkswagen Foundation (Niedersachsen Vorab)
    </h4>
    <div class="contrast"><div class="logo-stiftung" style="position: relative;"></div></div>
    <div class=""><div class="logo-sharing-heritage" style="position: relative;"></div></div>
  </div>
</div>
