<div class="ez-content">
  <div class="ez-content-view">
    <div id="search-data">
      <h1 style="font-weight: bolder;width: 700px">{{'RESOURCES' | transloco}}</h1>
      <div id="ct">
        <div id="tradition-tab-content">
          <div *ngIf="isLang('de')">
            <p>Die Enzyklopädie enthält Beiträge zu musikalischen Traditionen und Praktiken der Synagoge, zu ausgewählten Gesängen oder Objekten.
              Die Inhalte werden laufend erweitert.</p>
            <br>
            <a href [routerLink]="['sephardim']" queryParamsHandling="merge"><h3 class="ezyclopedia-link">Sephardim</h3></a><br />
            <a href [routerLink]="['ashkenazim']" queryParamsHandling="merge"><h3 class="ezyclopedia-link">Aschkenasim</h3></a><br />
            <a href [routerLink]="['romaniotes']" queryParamsHandling="merge"><h3 class="ezyclopedia-link">Romanioten</h3></a><br />
            <a href [routerLink]="['orthodox']" queryParamsHandling="merge"><h3 class="ezyclopedia-link">Orthodoxes Judentum</h3></a><br />
            <a href [routerLink]="['mizrahim']" queryParamsHandling="merge"><h3 class="ezyclopedia-link">Mizrachim</h3></a><br />
            <a href [routerLink]="['liberal']" queryParamsHandling="merge"><h3 class="ezyclopedia-link">Liberales Judentum</h3></a><br />
            <a href [routerLink]="['feminist']" queryParamsHandling="merge"><h3 class="ezyclopedia-link">Feministische jüdische Musik</h3></a><br />
          </div>
          <div *ngIf="isLang('en')">
            <p>
              The encyclopedia contains contributions on musical traditions and practices of the synagogue, selected songs or objects.
              The contents are constantly being expanded.
            </p>
            <br>
            <a href [routerLink]="['sephardim']" queryParamsHandling="merge"><h3 class="ezyclopedia-link">Sephardi Jews</h3></a><br />
            <a href [routerLink]="['ashkenazim']" queryParamsHandling="merge"><h3 class="ezyclopedia-link">Ashkenazim</h3></a><br />
            <a href [routerLink]="['romaniotes']" queryParamsHandling="merge"><h3 class="ezyclopedia-link">Romaniotes</h3></a><br />
            <a href [routerLink]="['orthodox']" queryParamsHandling="merge"><h3 class="ezyclopedia-link">Orthodox Judaism</h3></a><br />
            <a href [routerLink]="['mizrahim']" queryParamsHandling="merge"><h3 class="ezyclopedia-link">Mizrahim</h3></a><br />
            <a href [routerLink]="['liberal']" queryParamsHandling="merge"><h3 class="ezyclopedia-link">Liberal Judaism</h3></a><br />
            <a href [routerLink]="['feminist']" queryParamsHandling="merge"><h3 class="ezyclopedia-link">Feminist Jewish Music</h3></a><br />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


