<div id="enzyklopedia_view" ng-controller="SearchController as search">
  <div id="enzyklopedia-data">
    <div *ngIf="isLang('de')">
      <h2>Feministische jüdische Musik</h2>

      <p>
        Feministische jüdische Musik entstand als neues kulturelles Phänomen der Synagogalmusik in den späten 1960er und frühen 1970er Jahren im Kontext von
        Reformgemeinden und progressiven jüdischen Gemeinden in den Vereinigten Staaten. Motiviert und inspiriert wurde sie durch
        zeitgenössische gesellschaftspolitische Phänomene wie die amerikanische Gegenkultur sowie die Frauenbewegung und ihren Ableger,
        die jüdische feministische Bewegung. Ziel dieser Musik ist es, die Gleichstellung der Geschlechter im Judentum im
        Allgemeinen und in der jüdischen liturgischen Praxis im Besonderen zu fördern.
      </p><p>
      Musikalisch entstand das feministische jüdische Songwriting nach dem Vorbild der so genannten New Jewish Music und Women’s Music.
      Die feministische jüdische Musik kann jedoch weder dem einen noch dem anderen Genre eindeutig zugeordnet werden,
      da sie sich durch eine multikulturelle Verschmelzung von Klängen auszeichnet. Sie hat also die gleichen Probleme wie die Women’s Music
      (auch bekannt als „Musik für Frauen“). Musik für Frauen ist eine sehr umstrittene Kategorie, da diese Bezeichnung kein geschlossenes und homogenes
      Konzept eines Kunstwerks beschreibt. Dies gilt auch für die feministische jüdische Musik. Eine Klassifizierung nach rein ästhetischen,
      formalen oder technischen Aspekten ist daher nicht möglich. Amerikanische feministische jüdische Musik ist demnach kein in sich geschlossenes Genre,
      sondern vielmehr eine sozio-religiöse Praxis, die nur durch Faktoren wie Autorschaft, Aufführungskontext, Inhalt, Intention und identitätsstiftende
      Wirkung der Musik sowie durch ihre Gesamtfunktion in jüdischen religiösen Kontexten (vor allem im Kontext feministischer jüdischer Rituale) definiert werden kann.
    </p><p>
      Die folgenden allgemeinen Merkmale können als bestimmend für die feministische jüdische Musik gelten:</p>
      <p>
        Die meisten Charakteristika feministischer jüdischer Musik hängen mit deren Inhalt zusammen. Ein Lied gilt als feministisch,
        wenn es
        (1) feministische Sprache (feministisches Hebräisch oder irgendeine geschlechtsneutrale Sprache) verwendet,
        (2) feministische Bilder (zum Beispiel geschlechtsneutrale Begriffe für das Göttliche, wie „der Geliebte“) verwendet und
        (3) die feministischen Aspekte Gottes (wie Shekhinah, der immanente Gott, oder Gottes Gegenwart in der Welt und der Gemeinschaft) behandelt.
        Feministische jüdische Lieder stellen auch
        (4) die Rolle jüdischer Frauen in der Geschichte und der jüdischen Tradition dar und
        (5) sind mit spezifischen Ritualen und Erfahrungen jüdischer Frauen verbunden.
        Die primäre Absicht hinter diesen Liedern ist es, jüdischen Frauen eine Stimme zu geben, sowohl in der Vergangenheit als auch in der Gegenwart.
        Darüber hinaus kommen in den Liedern neue Perspektiven auf die jüdische Liturgie und darauf, wie das Judentum und die jüdische Gemeinde
        in Zukunft aussehen könnten, zum Ausdruck.
        So befasst sich die feministische jüdische Musik auch mit weiteren Themen wie Heilung,
        soziale Gerechtigkeit, tikkun olam (hebräisch; Heilung der Welt), Frieden, Ökofeminismus und Multikulturalismus etc.
      </p>
      <p>
        Unter den feministischen jüdischen Singer-Songwriterinnen hat man sich auf folgende Grundsätze geeinigt: Die Musik sollte so komponiert sein,
        dass sie leicht von Menschen gesungen werden kann, die keine spezielle musikalische Vorbildung mitbringen;
        sie sollte im Tonumfang begrenzt und leicht zu beherrschen sein; der Sound sollte weder langweilig noch repetitiv sein;
        und die Musik sollte den Inhalt der Texte spiegeln. Darüber hinaus gilt der bloße Akt des Schreibens und Aufführens von Musik als feministischer Akt,
        da der Großteil des Repertoires der Synagogalmusik von Männern und für Männerstimmen geschrieben wurde.
        Der Stil der feministischen jüdischen Musik wird jedoch durch die musikalischen Konventionen der Strömung des Judentums,
        welcher die Singer-Songwriterinnen angehören, sowie durch den Einfluss lokaler Musikkulturen und den persönlichen Musikgeschmack bestimmt.
      </p>
      <p>
        Welche Rolle spielt diese Musik im zeitgenössischen jüdischen Feminismus?</p>
      <p>
        Die Pluralität des jüdischen Feminismus zeigt sich in den Themen, die in den Liedern angesprochen werden,
        wie Geschlechtergerechtigkeit, soziale Gerechtigkeit, Multikulturalismus und Identitätspolitik etc.
        Der Hauptunterschied zwischen der Musik der frühen Jahre des jüdischen Feminismus (1960er und 1970er Jahre) und der Musik von heute besteht darin,
        dass die feministische jüdische Musik und die darin enthaltenen politischen Aussagen nun stärker in den allgemeinen jüdischen Gottesdienst und das
        kulturelle Bewusstsein des amerikanischen Judentums integriert sind. Darüber hinaus werden Populärkultur und neue Medien zunehmend als Mittel genutzt,
        um den gesellschaftlichen und religiösen Wandel im heutigen jüdischen Feminismus herbeizuführen.</p>
      <p>Autorin: Sarah M. Ross</p>

      <h5>Für weitere Informationen siehe:</h5>
      <p>Sarah M. Ross (2016): A Season of Singing. Creating Feminist Jewish Music in the United States, Waltham, MA: Brandeis University Press.</p>
      <p>Goldie Milgram, Geela Rayzel Raphael und Sarah Ross: Adding Our Voices. The Torah of Jewish Women in Song, New Rochelle, NY: Reclaiming Judaism Press (in Vorbereitung).</p>
    </div>
    <div *ngIf="isLang('en')">
      <h2>Feminist Jewish Music</h2>
      <p>
        Feminist Jewish music emerged as a new cultural phenomenon of synagogue music in the late 1960s and early 1970s
        within the context of Reform and progressive Jewish communities in the United States:
        motivated and inspired by contemporary socio-political phenomena like the American counterculture as well as the women’s movement and its offshoot the Jewish feminist movement.
        The aim of this music is to advocate and spur gender equality in Judaism in general, and in Jewish liturgical practice in particular.
      </p><p>
      Musically, feminist Jewish songwriting was created on the model of so-called New Jewish Music and secular women’s music.
      However, feminist Jewish music cannot be defined as fitting in one genre or another; instead, it consists of a multicultural fusion of sounds.
      Thus, it has the same problems as so-called women’s music, or what is more generally labeled as “music for women.”
      Music for women is a highly controversial category, as the name does not describe a closed and homogeneous concept of a work of art.
      This is also true in the case of feminist Jewish music. Therefore, a classification according to merely aesthetic, formal, or technical aspects of feminist Jewish music is not possible.
      American feminist Jewish music as a genre has to be understood as a social/religious practice, which can be defined only by factors
      such as authorship, performance context, content, intention, and the identity-establishing effect of the music,
      as well as by its overall function in Jewish religious contexts (most importantly in the context of feminist Jewish rituals).
    </p><p>
      The following general characteristics can be identified as defining feminist Jewish music.</p>
      <p>
        Most of the characteristics are related to the music’s content. A song is considered as feminist if it
        (1) uses feminist language (feminist Hebrew or any gender-neutral language),
        (2) uses feminist images (for example, gender-neutral terms for the divine, such as “the beloved”), and
        (3) addresses thefeministaspects of God (such as Shekhinah, the immanent God, or God’s presence in the world and the community). Feminist Jewish songs also
        (4) portray the role of Jewish women in history and Jewish tradition, and
        (5) are related to specific Jewish women’s rituals and experiences.
        The primary intention behind these songs is to give a voice to Jewish women, in the past as well as in the present.
        In addition, new perspectives on Jewish liturgy and on a vision of what Judaism and the Jewish community might look like in the future are incorporated in the songs.
        Thus, feminist Jewish music furthermore addresses broader issues such as healing, social justice, tikkun olam(Hebrew: healing the world),
        peace, ecofeminism, and multiculturalism etc.
      </p><p>
      Among feminist Jewish singer-songwriters it was agreed that the music should be written in a way that can easily be sung by groups of untrained people;
      it should be limited in range and easy to master; its sound should be neither boring nor repetitive;
      and the music should match the lyrics. In addition, the mere act of writing and performing music is considered to be a feminist act,
      as most of the repertoire of synagogue music has been written by men and for men’s voices.
      The style of feminist Jewish music, however, is determined by the musical conventions of the denomination of the singer-songwriter
      and by the influence of local musical cultures, or by the individual’s interest in other musical cultures.
    </p><p>
      What role does this music play in contemporary Jewish feminism?</p>
      <p>
        The plurality of the Jewish feminist movement is apparent in the issues the songs address, such as gender equity,
        social justice, multiculturalism, and identity politics—all of which have been part of the content of feminist Jewish music since the beginning of the Jewish feminist movement.
        The major difference between the music of the early years of Jewish feminism (the 1960s and 1970s)
        and the music of today is that feminist Jewish music—and the political statements that it contains—is now more integrated into general Jewish worship
        and the cultural terrain of American Judaism. Furthermore, popular culture and new media are increasingly used as ways to bring
        about social and religious change in today’s Jewish feminism.
      </p>
      <p>
        Author: Sarah M. Ross
      </p>

      <h5>For more information see:</h5>
      <p>Sarah M. Ross (2016): A Season of Singing. Creating Feminist Jewish Music in the United States. Brandeis University Press.</p>
      <p>Goldie Milgram, Geela Rayzel Raphael, and Sarah Ross: Adding Our Voices: The Torah of Jewish Women in Song. Reclaiming Judaism Press (in preparation)</p>
    </div>
  </div>
</div>
