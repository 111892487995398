<div id="start_view" class="start_view">
  <div id="col-group">
    <div class="col bg-dark-alpha-high" [routerLink]="'/welcome'" [ngClass]="applyCursorClass()">
      <div class="col-footer">
        <div class="col-label-container">
          <span class="col-footer-label">{{'WELCOME' | transloco}}</span>
          <div class="svg-container">
            <mat-icon class="col-footer-svg-icon" svgIcon="recorder-icon" style="stroke: #5a0433;"></mat-icon>
          </div>
        </div>
        <div class="col-footer-content" *ngIf="isLang('de')">
          <p>
              Soundscape Synagogue bietet Informationen über das musikalische Erbe jüdischer Gemeinschaften aus verschiedenen Weltregionen.
            <br><br>
            <a class="landing-link" [routerLink]="'/welcome'">Weiterlesen</a>
          </p>
        </div>
        <div class="col-footer-content" *ngIf="isLang('en')">
          <p>
              Soundscape Synagogue provides information on the musical heritage of Jewish communities from different regions of the world.
            <br><br><a class="landing-link" [routerLink]="'/welcome'">Continue</a>
          </p>
        </div>
      </div>
    </div>
    <div class="col bg-dark-alpha-high" [routerLink]="'/aov-landing'" [ngClass]="applyCursorClass()">
      <div class="col-footer">
        <div class="col-label-container">
          <span class="col-footer-label">{{'AOV' | transloco}}</span>
          <div class="svg-container">
            <mat-icon class="col-footer-svg-icon" svgIcon="miriam-icon" style="stroke: #5a0433;"></mat-icon>
          </div>
        </div>
        <div class="col-footer-content" *ngIf="isLang('de')">
          <p>
              "Adding Our Voices" dokumentiert Jüdische feministische und Gender-inklusive Musikstücke und biographische Informationen zu den Künstler:innen.
            <br><br>
            <a class="landing-link" [routerLink]="'/aov-landing'">Weiterlesen</a>
          </p>
        </div>
        <div class="col-footer-content" *ngIf="isLang('en')">
          <p>
              "Adding Our Voices" documents Jewish feminist and gender-inclusive musical works and biographical information on the artists.
            <br><br>
            <a class="landing-link" [routerLink]="'/aov-landing'">Continue</a>
          </p>
        </div>
      </div>
    </div>
    <div class="col bg-dark-alpha-high" [routerLink]="'/cantors-landing'" [ngClass]="applyCursorClass()">
      <div class="col-footer">
        <div class="col-label-container">
          <span class="col-footer-label">{{'MYNUSACH' | transloco}}</span>
          <div class="svg-container">
            <mat-icon class="col-footer-svg-icon" svgIcon="haim-icon" style="stroke: #5a0433;"></mat-icon>
          </div>
        </div>

        <div class="col-footer-content" *ngIf="isLang('de')">
          <p>
              Unter MyNusach finden Sie Informationen zu Kantor:innen und ihren lokalen liturgischen Traditionen.
            <br><br>
            <a class="landing-link" [routerLink]="'/cantors-landing'">Weiterlesen</a>
          </p>
        </div>
        <div class="col-footer-content" *ngIf="isLang('en')">
          <p>
              At MyNusach you will find information on cantors and their local liturgical traditions.
            <br><br><a class="landing-link" [routerLink]="'/cantors-landing'">Continue</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="clear"></div>
</div>
