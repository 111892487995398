import { Component, OnInit } from '@angular/core';
import { ApplicationStateService } from "../../../service/application-state.service";
import { Subscription } from "rxjs";
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  language: string;
  constructor(private translocoService: TranslocoService) {
    this.language = this.translocoService.getActiveLang();
  }

  isLang(lang: string){
    return lang === this.translocoService.getActiveLang();
  }

  ngOnInit(): void {
  }

}
