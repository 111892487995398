import {Component, Input, OnInit} from '@angular/core';
import {AovSearchModel} from "../../../service/aov/aov-search-model";
import {AovSearchParameter} from "../../../model/SearchParamter";
import {AovService} from "../../../service/aov/aov-service";
import {WaiSettingsService} from "../../../components/wai-menu/wai-settings.service";

@Component({
  selector: 'aov-expert-search',
  templateUrl: './expertsearch.component.html',
  styleUrls: ['./expertsearch.component.css']
})
export class AovExpertSearchComponent implements OnInit {

  masterdataFieldParams: []|null|undefined = [];
  persondataFieldParams: []|null|undefined = [];
  bioFieldParams: []|null|undefined = [];
  mediaFieldParams: []|null|undefined = [];
  infoFieldParams: []|null|undefined = [];

  operator: string|null|undefined = null;
  firstSearchValue: string|null|undefined = '';
  secondSearchValue:string|null|undefined = '';

  getSearchFieldListViaKey: Function;
  @Input() setSearchResult!: Function;
  constructor(private searchModel: AovSearchModel,
              private aovService: AovService,
              public settingsService: WaiSettingsService) {
    this.getSearchFieldListViaKey = (key: string) => {
      return searchModel.getSearchFieldListViaKey(key);
    };
  }

  ngOnInit(): void {
    let searchParameter = this.searchModel.getSearchParameter();
    if(typeof searchParameter != undefined && searchParameter)
    {
      this.operator = searchParameter.searchOperatorValue;
      this.firstSearchValue = searchParameter.firstSearchInputValue;
      this.secondSearchValue = searchParameter.secondSearchInputValue;
      if(typeof searchParameter.filterMap != undefined && searchParameter.filterMap){
        this.masterdataFieldParams = searchParameter.filterMap['MASTERDATA'];
        this.bioFieldParams = searchParameter.filterMap['BIO'];
        this.infoFieldParams = searchParameter.filterMap['INFO'];
        this.mediaFieldParams = searchParameter.filterMap['COMPOSITION'];
      }
    }
  }

  fillSelectionList(selectionList: any, searchFieldList: any) {
    for(let i = 0; i < searchFieldList.length; i++){
      this.setSelectedField(searchFieldList[i], selectionList);
    }
  }

  getSelectedFieldIndex(field: string, selectionList: any) {
    return selectionList.indexOf(field);
  }

  isChecked(checkList: any[]|null|undefined) {
    return (null != checkList && checkList.length > 0);
  }

  onKey(keyCode: String): void {
    if('Enter' === keyCode) {
      this.performAovSearch();
    }
  }

  performAovSearch() {
    if(this.firstSearchValue || this.secondSearchValue) {
      let searchParameter = this._createSearchParameter();
      this.searchModel.setSearchParameter(searchParameter);
      this.aovService.performAovSearch(searchParameter, this.setSearchResult);
    }
  }

  setSelectedCategory(key: string, selectionList: any) {
    let searchFieldList = this.searchModel.getSearchFieldListViaKey(key);
    if(selectionList.length > 0 && selectionList.length < searchFieldList.length){
      selectionList.splice(0,selectionList.length);
    }
    else{
      this.fillSelectionList(selectionList, searchFieldList);
    }
  }

  setSelectedField(field: any, selectionList: any) {
    let _fieldIndex = this.getSelectedFieldIndex(field, selectionList);
    if(-1 < _fieldIndex){
      selectionList.splice(_fieldIndex, 1);
    }else {
      selectionList.push(field);
    }
  }

  _createSearchParameter() {
    let filterMap = {
      MASTERDATA:this.masterdataFieldParams,
      BIO: this.bioFieldParams,
      INFO:this.infoFieldParams,
      COMPOSITION:this.mediaFieldParams
    };
    let searchParameter: AovSearchParameter = {
      filterMap: filterMap,
      searchOperatorValue: this.operator,
      firstSearchInputValue: this.firstSearchValue,
      secondSearchInputValue: this.secondSearchValue,
    };
    return searchParameter;
  }

}
