import { Injectable, PLATFORM_ID, Inject } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'

function getLocalStorage(): Storage {
  return localStorage;
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageRefService {

  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  get localStorage(): Storage {
    return isPlatformBrowser(this.platformId) ? getLocalStorage() : new Storage();
  }
}
