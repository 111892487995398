<div id="welcome_view">
  <div class="welcome-data" *ngIf="isLang('de')">
    <h1>Willkommen</h1>
    <p>
        Soundscape Synagogue bietet Informationen über das musikalische Erbe jüdischer Gemeinschaften aus verschiedenen Weltregionen.
        Hier finden Sie Wissen über jüdische Liturgien der Vergangenheit und der Gegenwart,
        sowie Informationen über verschiedene Traditionen jüdischer liturgischer Sänger:innen und ihre Werke,
        einschließlich Texten, Noten, Bildern, Tonaufnahmen und Filme. Viele Inhalte sind frei zugänglich und können mit Quellenangabe verwendet werden.
        Neue Inhalte werden regelmäßig hinzugefügt.
    </p>
  </div>
  <div class="welcome-data" *ngIf="isLang('en')">
    <h1>Welcome</h1>
    <p>
        Soundscape Synagogue provides information on the musical heritage of Jewish communities from different regions of the world.
        Here you will find knowledge on Jewish liturgies of the past and present, as well as information on various traditions of
        Jewish liturgical singers and their works, including texts, musical notes, images, sound recordings and films.
        Many contents are freely accessible and may be used with reference to the source.
        New content is added on a regular basis.
    </p>
  </div>
</div>
