<div id="search-input-fields">
  <div id="first-search-input" class="search_clip">
    <input id="first-search"
           class="search-input"
           [ngClass]="settingsService.settings.backgroundColorOption === 'BACKGROUND_OPTION_BLACK' ? 'theme-color-dark' : 'theme-color-light'"
           type="text"
           [(ngModel)]="firstSearchValue"
           (keyup)="$event.code=='Enter' && onKey($event.code)"
           placeholder="{{'SEARCH_INPUT_PLACEHOLDER' | transloco}}"
           autofocus>
  </div>
<!--  <div class="search-btn" (click)="performAovSearch()">-->
<!--    <svg class="search-btn-icon" viewBox="0 0 24 24">-->
<!--      <path fill="#fff" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />-->
<!--    </svg>-->
<!--  </div>-->
  <button mat-stroked-button mat-flat-button color="primary"
          class="search-btn"
          [ngClass]="settingsService.settings.backgroundColorOption === 'BACKGROUND_OPTION_BLACK' ? 'theme-color-dark' : 'theme-color-light'"
          (click)="performAovSearch()">
    <mat-icon>search</mat-icon>
  </button>
  <div class="clear"></div>
  <div id="searchOpBool" class="tab" style="">
    <input type="radio" value="|" [(ngModel)]="operator" name="bool_operator"><span class="radio_label">{{'OR'|transloco}}</span>&nbsp;
    <input type="radio" value="+" [(ngModel)]="operator" name="bool_operator"><span class="radio_label">{{'AND'|transloco}}</span>
  </div>
  <div id="second-search-input" class="search_clip">
    <input id="second-search"
           class="search-input"
           [ngClass]="settingsService.settings.backgroundColorOption === 'BACKGROUND_OPTION_BLACK' ? 'theme-color-dark' : 'theme-color-light'"
           type="text"
           [(ngModel)]="secondSearchValue"
           (keyup)="$event.code=='Enter' && onKey($event.code)"
           placeholder="{{'SEARCH_INPUT_PLACEHOLDER' | transloco}}"
           autofocus>
  </div>
  <button mat-stroked-button mat-flat-button color="primary"
          class="search-btn"
          [ngClass]="settingsService.settings.backgroundColorOption === 'BACKGROUND_OPTION_BLACK' ? 'theme-color-dark' : 'theme-color-light'"
          (click)="performAovSearch()">
    <mat-icon>search</mat-icon>
  </button>
</div>
<div class="input-grid">
  <div class="column-1">
    <form id="base_filter" style="position: relative;margin: 18px 0 9px 0;">
      <fieldset id="base_filter_set">
        <legend class="category-checkbox-legend">{{'AOV_MASTER_DATA' | transloco}}
          <input class="category-checkbox-input"
                 type="checkbox"
                 name="base_filter_set"
                 value="MASTER_DATA"
                 [checked]="isChecked(masterdataFieldParams)"
                 (click)="setSelectedCategory('MASTERDATA', masterdataFieldParams)">
        </legend>
        <div>
          <label class="checkbox-label" *ngFor="let field of getSearchFieldListViaKey('MASTERDATA')" >
            <input type="checkbox"
                   name="masterdataFieldParams[]"
                   [checked]="-1 < getSelectedFieldIndex(field, masterdataFieldParams)"
                   (change)="setSelectedField(field, masterdataFieldParams)"> {{field | transloco}}
          </label>
        </div>
      </fieldset>
    </form>
    <form id="bio_filter" class="filter" style="position: relative;margin: 18px 0 9px 0;">
      <fieldset id="bio_filter_set">
        <legend class="category-checkbox-legend">{{'PROFILE_TAB' | transloco}}
          <input class="category-checkbox-input"
                 type="checkbox"
                 value=""
                 name="base_filter_set"
                 [checked]="isChecked(bioFieldParams)"
                 (click)="setSelectedCategory('BIO', bioFieldParams)">
        </legend>
        <label class="checkbox-label" *ngFor="let field of getSearchFieldListViaKey('BIO')" >
          <input type="checkbox"
                 value="field"
                 name="bioFieldParams[]"
                 [checked]="-1 < getSelectedFieldIndex(field, bioFieldParams)"
                 (click)="setSelectedField(field, bioFieldParams)"> {{field | transloco}}
        </label>
      </fieldset>
    </form>
  </div>
  <div class="column-2">
    <form id="info_filter" style="position: relative;margin: 18px 0 9px 0;">
      <fieldset id="info_filter_set">
        <legend class="category-checkbox-legend">{{'MUSIC_INFO' | transloco}}
          <input class="category-checkbox-input"
                 type="checkbox"
                 value=""
                 name="base_filter_set"
                 [checked]="isChecked(infoFieldParams)"
                 (click)="setSelectedCategory('INFO', infoFieldParams)">
        </legend>
        <label class="checkbox-label" *ngFor="let field of getSearchFieldListViaKey('INFO')" >
          <input type="checkbox"
                 value="field"
                 name="selectedFields"
                 [checked]="-1 < getSelectedFieldIndex(field, infoFieldParams)"
                 (click)="setSelectedField(field, infoFieldParams)"> {{field | transloco}}</label>
      </fieldset>
    </form>
    <form id="media_filter" style="position: relative;margin: 18px 0 9px 0;">
      <fieldset id="media_filter_set">
        <legend class="category-checkbox-legend">{{'MUSIC' | transloco}}
          <input class="category-checkbox-input"
                 type="checkbox"
                 value=""
                 name="base_filter_set"
                 [checked]="isChecked(mediaFieldParams)"
                 (click)="setSelectedCategory('COMPOSITION', mediaFieldParams)">
        </legend>
        <label class="checkbox-label" *ngFor="let field of getSearchFieldListViaKey('COMPOSITION')" >
          <input type="checkbox"
                 value="field"
                 name="selectedFields"
                 [checked]="-1 < getSelectedFieldIndex(field, mediaFieldParams)"
                 (click)="setSelectedField(field, mediaFieldParams)"> {{field | transloco}}</label>
      </fieldset>
    </form>
  </div>
</div>
