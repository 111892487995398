<div id="howto_view">
  <div *ngIf="isLang('de')">
    <h1>Kurzanleitung</h1>
    <p>
      Sie können diese Internetseite auf verschiedene Weisen nutzen.
      Neben der Suchfunktion stehen ein ein alphabetisches Personenverzeichnis und eine Enzyklopädie zur Verfügung.
      Außerdem können Sie uns bei der Erweiterung unserer Seite helfen.
    </p>

    <h3><a [routerLink]="['/aov']" [queryParams]="{ tab: 'simplesearch' }" >Einfache Suche:</a></h3>
    <p>Geben Sie einen oder mehrere Suchbegriffe in das Textfeld ein, beispielsweise 'Samuel Lampel' (ohne Anführungszeichen).</p>

    <h3><a [routerLink]="['/aov']" [queryParams]="{ tab: 'expertsearch' }" >Erweiterte Suche:</a></h3>
    <p>Geben Sie einen oder mehrere Suchbegriffe in ein oder beide Textfelder ein, beispielsweise 'Samuel Lampel' und 'Hannover'.</p>

    <p>Wählen Sie AND, um nach beiden Begriffen in Verbindung zu suchen. Es werden nur solche Einträge angezeigt, in denen sowohl 'Samuel Lampel' als auch 'Hannover' auftauchen.</p>

    <p>Wählen Sie OR, um unabhängig voneinander nach mehreren Begriffen zu suchen. Es werden alle Einträge angezeigt, in denen entweder 'Samuel Lampel' oder 'Hannover' vorkommen.
      Dazu zählen auch solche Einträge, in denen beide Begriffe verzeichnet sind.</p>


    <h3><a [routerLink]="['/aov']" [queryParams]="{tab:'aovidx', key: 'A'}">Personen alphabetisch:</a></h3>
    <p>Unter dieser Kategorie können Sie sich sämtliche Personeneinträge in alphabetischer Reihenfolge (Nachname, Vorname) anzeigen lassen.</p>


    <h3><a [routerLink]="['/encyclopedia']">Enzyklopädie:</a></h3>
    <p>Die Enzyklopädie enthält Beiträge zu musikalischen Traditionen und Praktiken der Synagoge, zu ausgewählten Gesängen oder Objekten. Die Inhalte werden laufend erweitert.</p>


    <h3><a [routerLink]="['/tradition']">Carry on Tradition:</a></h3>
    <p>Verfügen Sie über Informationen rund um Musik in Synagogen? Schauen Sie sich unser <a [routerLink]="['/tradition']">Video</a> an, oder <a href [routerLink]="['/contact']">kontaktieren</a> Sie uns gerne.</p>

<!--    <h3><a href ui-sref="basket">Basket</a></h3>-->
<!--    <p>-->
<!--      <svg style="position:relative;width:24px;height:24px;top:5px;" class="svg-btn" viewBox="0 0 24 24">-->
<!--        <path d="M3,2H6V5H3V2M6,7H9V10H6V7M8,2H11V5H8V2M17,11L12,6H15V2H19V6H22L17,11M7.5,22C6.72,22 6.04,21.55 5.71,20.9V20.9L3.1,13.44L3,13A1,1 0 0,1 4,12H20A1,1 0 0,1 21,13L20.96,13.29L18.29,20.9C17.96,21.55 17.28,22 16.5,22H7.5M7.61,20H16.39L18.57,14H5.42L7.61,20Z" />-->
<!--      </svg>-->
<!--      Indem Sie auf das Basketsymbol klicken,-->
<!--      fügen Sie einen oder mehrere Sucheinträge einer Liste (e.g. dem Basket) hinzu. Diese Liste können Sie sich anschließend per Email zuschicken, oder als PDF exportieren.-->
<!--    </p>-->

    <h3>Urheberrecht</h3>
    <p>
      Bitte beachten Sie unbedingt die Maßgaben der <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/deed.de">Creative Commons Share Alike 4.0 Lizenz (CC-BY-SA)</a>, unter der sich dieses Werk befindet.
      Gerne können Sie sämtliche Materialien auf unserer Website weiter verwenden (soweit nicht anders angegeben). Hierzu müssen Sie sich an die Maßgaben der CC-BY-SA-Lizenz halten.
    </p>
    <p>Weitere Angaben entnehme Sie bitte dem Impressum.</p>

    <h3>Empfohlene Zitierweise:</h3>
    <p>Sollten Sie das Werk in wissenschaftlichen Publikationen zitieren wollen, empfehlen wir folgende Zitierweise:</p>


    <h3>Titel des Gesamtwerks:</h3>
    <p>Soundscape Synagoge. Archiv zur Musik in Synagogen und religiösen Orten, hg. von Europäisches Zentrum für
      Jüdische Musik / Sarah M. Ross, Regina Randhofer / Samuel Mund, Hannover: Hochschule für Musik,
      Theater und Medien Hannover, seit 2018, http://www.soundscape-synagoge.de
    </p>
    <h3>Titel der in "SoSY" veröffentlichten Personenartikel:</h3>
    <p> Vorname, Name in: Soundscape Synagoge. Archiv zur Musik in Synagogen und religiösen Orten, hg. von
      Europäisches Zentrum für Jüdische Musik / Sarah M. Ross, Regina Randhofer, Samuel Mund, Hannover: Hochschule
      für Musik, Theater und Medien Hannover, seit 2018
    </p>
    <h3>Title der in "SoSy" veröffentlichten Enzyclopädieeinträge:</h3>
    <p>Name des Eintrags, in: Soundscape Synagoge. Archiv zur Musik in Synagogen und religiösen Orten, hg. von
     Europäisches Zentrum für Jüdische Musik / Sarah M. Ross, Regina Randhofer, Samuel Mund, Hannover: Hochschule für
     Musik, Theater und Medien Hannover, seit 2018, [http://www.soundscape-synagoge.de/... (date accessed)]
    </p>

  </div>
  <div *ngIf="isLang('en')">
    <h1>Getting Started</h1>
    <p>
      You can use this website in various ways.
      In addition to the search function, an alphabetical list of persons and an encyclopaedia are available.
      You can also help us to expand our site.
    </p>

    <h3><a [routerLink]="['/aov']" [queryParams]="{ tab: 'simplesearch' }">Simple search:</a></h3>
    <p>Enter one or more search terms in the text box, for example 'Samuel Lampel' (without quotes).</p>

    <h3><a [routerLink]="['/aov']" [queryParams]="{ tab: 'expertsearch' }">Advanced Search:</a></h3>
    <p>
      Enter one or more search terms in one or both text fields, for example 'Samuel Lampel' and 'Hannover'.
    </p><p>
    Select AND to search for both terms in combination. Only entries containing both 'Samuel Lampel' and 'Hannover' will be returned.
  </p><p>
    Choose OR to search for several terms independently of each other. All entries containing either 'Samuel Lampel' or 'Hannover' will be returned. Note: this also includes entries in which both terms are listed.
  </p>

    <h3><a [routerLink]="['/aov']" [queryParams]="{tab:'aovidx', key: 'A'}">Alphabetical Order:</a></h3>
    <p>Under this category, you will find all person entries in alphabetical order (last name, first name).</p>

    <h3><a [routerLink]="['/encyclopedia']">Encyclopedia:</a></h3>
    <p>The encyclopedia contains contributions on musical traditions and practices of the synagogue, selected songs or objects. The contents are constantly being expanded.</p>

    <h3><a [routerLink]="['/tradition']">Carry on Tradition:</a></h3>
    <p>Do you have information about music in synagogues? Take a look at our video, or contact us.</p>

<!--    <h3><a href ui-sref="basket">Basket</a></h3>-->
<!--    <p>-->
<!--      <svg style="position:relative;width:24px;height:24px;top:5px;" class="svg-btn" viewBox="0 0 24 24">-->
<!--        <path d="M3,2H6V5H3V2M6,7H9V10H6V7M8,2H11V5H8V2M17,11L12,6H15V2H19V6H22L17,11M7.5,22C6.72,22 6.04,21.55 5.71,20.9V20.9L3.1,13.44L3,13A1,1 0 0,1 4,12H20A1,1 0 0,1 21,13L20.96,13.29L18.29,20.9C17.96,21.55 17.28,22 16.5,22H7.5M7.61,20H16.39L18.57,14H5.42L7.61,20Z" />-->
<!--      </svg>-->
<!--      By clicking on the basket symbol, you can add one or more search entries to a list (e.g. to the basket). You can then send yourself this list by email or export it as a PDF file.-->
<!--    </p>-->

    <h3>Copyright & Imprint</h3>
    <p>Please note the requirements of the <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/">Creative Commons Share Alike 4.0 License (CC-BY-SA)</a> under which this work is published.
      You are welcome to continue using all materials on our website (unless otherwise stated). You must comply with the requirements of the CC-BY-SA license.
    </p><p>Further information can be found in the imprint.</p>

    <h3>Recommended citation:</h3>

    <p>If you want to cite the work in scientific publications, we recommend the following citation method:</p>

    <h3>Title:</h3>
    <p>
        Soundscape Synagoge. Archiv zur Musik in Synagogen und religiösen Orten, hg. von Europäisches Zentrum für
        Jüdische Musik / Sarah M. Ross, Regina Randhofer, Samuel Mund, Hannover: Hochschule für Musik, Theater und Medien Hannover, seit 2018, http://www.soundscape-synagoge.de
    </p>

    <h3>Titles of the personal articles published in "SoSY":</h3>
    <p>
        Surname, Name in: Soundscape Synagoge. Archiv zur Musik in Synagogen und religiösen Orten,
        hg. von Europäisches Zentrum für Jüdische Musik / Sarah M. Ross, Regina Randhofer, Samuel Mund,
        Hannover: Hochschule für Musik, Theater und Medien Hannover, seit 2018,
        [http://www.soundscape-synagoge.de/person?identifier=SOSY_..._000... (date accessed)]
    </p>


    <h3>Titles of the encyclopedic entries published in "SoSY":</h3>
    <p>
        Name of entry in: Soundscape Synagoge. Archiv zur Musik in Synagogen und religiösen Orten, hg. von Europäisches
        Zentrum für Jüdische Musik / Sarah M. Ross, Regina Randhofer, Samuel Mund, Hannover: Hochschule für Musik,
        Theater und Medien Hannover, seit 2018, [http://www.soundscape-synagoge.de/... (date accessed)]
    </p>

  </div>
</div>

