<div id="enzyklopedia_view" ng-controller="SearchController as search">
  <div id="enzyklopedia-data">
    <div *ngIf="isLang('de')">
      <h2>Mizrachim:</h2>
      <p>Mizrachi ('östlich') ist ein soziopolitischer Begriff, der Juden aus nordafrikanischen und westasiatischen Gebieten, sowie aus Teilen des Kaukausus und des Irans bezeichnet. Seit den 1950er Jahren an flohen circa 850,000 Mizrachim aus ideologischen Motiven und wegen Judenverfolgungen aus ihren ursprünglichen Heimatstätten und emigrierten unter anderem nach Israel. Beispielsweise wurden nahezu sämtliche Angehörigen der örtlichen jüdischen Gemeinden Ägyptens und des Iraks aus ihren Ländern ausgewiesen. Daher konnotiert der Begriff 'Mizrachi' den Akt der Enteignung, allerdings im Sinne einer innerjüdischen 'Andersheit', denn der Begriff wurde ursprünglich vom aschkenasischen Establishment Israels abwertend als Bezeichnung für die jüdischen Immigranten aus arabischen Ländern ab den 1950ern verwendet.
      </p><p>
      Im Laufe der Zeit verwendeten die Mizrachim Israels diesen Begriff als Eigenbezeichnung, dadurch erhielt Mizrachim eine solidarische und emanzipatorische Bedeutung. Der Begriff wurde auch zur Abgrenzung von anderen Gruppen verwendet. In den 1970er Jahren stellten die Mizrachim mehr als die Hälfte der jüdischen israelischen Bevölkerung; allerdings waren sie politisch unterrepräsentiert. Vor diesem Hintergrund wurde die Bewegung Mizrahi Black Panther Movement gegründet. Obwohl sie nie den Status einer politischen Partei innehatte, lenkte die Bewegung die Aufmerksamkeit erfolgreich auf die mangelnden Bürgerrechte der Mizrachim.
    </p><p>
      In den 1980er Jahren wurde die politische Emanzipation der Mirzrachim durch kulturelle Vermischung und Akzeptanz im israelischen Sozialleben gestärkt. Dennoch muß darauf hingewiesen werden, dass der Begriff Mirzrachi bedenklich und problematisch bleibt, da er die unterschiedlichen geografischen Herkünfte und Kulturen der westasiatischen und nordafrikanischen Juden sprachlich verschwinden lässt, und somit unangemessen homogenisiert.
    </p><p>
      Mizrachi-Musik ist ein Label, das musikalische Beiträge jüdischer Einwanderer aus der 'arabischen' Welt beschreibt. Sie entstand in den 1950er Jahren mit der signifikanten Einbürgerung von Juden, die aus vielen Ländern des Nahen Ostens und anderen Ländern ausgewandert und/oder vertrieben worden waren. Die Ursprünge der Mizrachi-Musik liegen in den israelischen Vierteln, vor allem im überwiegend jemenitischen Tel Aviver Viertel Kerem Hatemanim und in der Stadt Ramat Gan, die eine große jüdisch-irakische Bevölkerung hat.
    </p><p>
      Diese Viertel wurden zu "Schmelztiegeln" für eine experimentelle musikalische Mischung, inspiriert von einer Vielzahl westasiatischer Einflüsse, und angetrieben durch die Vermischung jüdischer Gemeinden unterschiedlicher Herkunft. Obwohl die Texte meist hebräisch sind, wird die Musik als überwiegend 'arabisch' beschrieben, da sie oft traditionelle orientalische Instrumente wie Oud, Kanun, Mijwiz und Darbbuka enthält. Allmählich wurde Mizrachitische Musikpraktiken von "modernen" Instrumenten durchdrungen. In den 1960er Jahren kamen Akustik- und E-Gitarren hinzu, der Klang wurde eklektischer und wurde an andere aufstrebenden Genres, wie beispielsweise Rockmusik, angepasst. Seit Mitte der 1980er Jahre ist die Mizrachi-Musik immer mehr teil des musikalischen Mainstreams geworden.
    </p><p>
      Autorin: Miranda Crowdus<br>
      Übersetzt aus dem Englischen von: Samuel Mund</p>


      <h5>Weiterführende Literatur:</h5>

      <h6>Musik:</h6>
      <p>
        Regev, M. (1996) ‘Musica Mizrakhit, Israeli Rock and National Culture in Israel’.
        Popular Music 15 (3), pp. 275-284.
      </p><p>
      Saada-Ophir, G. (2006) ‘Borderland Pop: Arab Jewish Musicians and the Politics of
      Performance’. Cultural Anthropology, 21(1), pp. 205-233.
    </p><p>
      Seroussi, E., Halper, J., Squires-Kidron, P. (1989) ‘Musica mizrakhit: Ethnicity and
      Class Structure in Israel’. Popular Music, 8(2), pp. 131-141.
    </p>
      <h6>Geschichte und Kultur:</h6>
      <p>
        Acosta, B. (2011) ‘The Cultural Politics of Israel’s Mirzrachim: From Marginalization to Dominance’. Culture Critique, 2(1) [Online] available at:
        <a href="http://ccjournal.cgu.edu">http://ccjournal.cgu.edu</a>.
      </p><p>
      Behar, M. (2008) ‘Mirzrachim, Abstracted: Action, Reflection, and the
      Academization of the Mirzrachi Cause’ in Journal of Palestine Studies 37(2), pp. 89-100.
    </p><p>
      Stillman, N. [1991] (2003) The Jews of Arab Lands in Modern Times. Philadelphia, PA: Jewish Publication Society of America.
    </p><p>
      (1979) The Jews of Arab Lands: Philadelphia, PA: Jewish Publication Society of America
    </p>
    </div>
    <div *ngIf="isLang('en')">
      <h2>Mizrahim:</h2>
      <p>
        Mizrahi (‘easterner’) is a socio-political term referring to Jews with origins in North African and Middle Eastern areas, including parts of the Caucuses and Iran.
        From the 1950s onwards approximately 850,000 Mizrahim fled their homes and immigrated to Israel and other destinations
        for both ideological reasons and owing to local persecution.
      </p><p>
      For instance, expulsions of local Jewish communities due to persecution took place in Egypt and Iraq. Thus, the term ‘Mizrahi’ is associated with dispossession,
      but more so with intra-Jewish ‘otherness’ as it was initially employed in Israel by the Ashkenazi establishment in a derogatory way to describe
      the many immigrants arriving from Arab countries from the 1950s onward. Over time, Mizrahi Israeli communities utilized this moniker to describe themselves,
      and hence, the designation became a symbol of solidarity and empowerment.
    </p><p>
      It was also used to distinguish themselves from other groups. In the 1970s,
      the Mizrahim made up over half of Israel’s Jewish population; however, they had yet to receive sufficient representation in the country’s political sphere.
      It was in this environment that the Mizrahi Black Panther movement was founded, modeled on the American Black Movement. While it never became a formal political party,
      it succeeded in bringing Mizrahi civil rights to central awareness.
    </p><p>
      By the 1980s, the political empowerment of Mizrahi communities was enabled by cultural diffusion and acceptance in the Israeli social sphere.
      It must be noted, however, that the term ‘Mizrahi’ remains critically problematic because it implies the uniformity of all Jews emanating from the MENA (Middle East and North Africa) area,
      regardless of their cultural and geographic origins.
    </p><p>
      Mizrahi music is a label used to describe all musical output of Jewish immigrants from the ‘Arab’ world. It emerged in the 1950s,
      with the massive influx of Jews that had emigrated and/or been expelled from many Middle Eastern and other countries. The origins of Mizrahi music are
      associated with grassroots performers in Israeli neighbourhoods, most notably the predominantly Yemenite neighbourhood
      of Tel Aviv called Kerem Hatemanim and also the city of Ramat Gan,
      which has a large Jewish-Iraqi population.
    </p>
      <p>
        These neighbourhoods became ‘melting pots’ for an experimental musical mixture of a variety of Middle Eastern influences
        due to the convergence of Jewish communities of disparate origins. Although the lyrics are usually in Hebrew, the music is described as being in a predominantly ‘Arab’ style,
        since it often incorporates traditional Middle Eastern instruments such as the oud, the kanun, the mijwiz, and the darbbuka.
        Gradually, Mizrahi musical performance was infused with ‘modern’,
        rather than traditional Middle Eastern, instruments. In the 1960s, performers added acoustic and electric guitar,
        and their sound became more eclectic and current in relation to other emergent genres (e.g. rock). Since the mid-1980s, Mizrahi music has increasingly become more mainstream.
      </p>
      <p>
        Author: Miranda Crowdus
      </p>

      <h5>Sources for additional reading:</h5>

      <h6>Music:</h6>
      <p>
        Regev, M. (1996) ‘Musica Mizrakhit, Israeli Rock and National Culture in Israel’.
        Popular Music 15 (3), pp. 275-284.
      </p><p>
      Saada-Ophir, G. (2006) ‘Borderland Pop: Arab Jewish Musicians and the Politics of
      Performance’. Cultural Anthropology, 21(1), pp. 205-233.
    </p><p>
      Seroussi, E., Halper, J., Squires-Kidron, P. (1989) ‘Musica mizrakhit: Ethnicity and
      Class Structure in Israel’. Popular Music, 8(2), pp. 131-141.
    </p>
      <h6>History and Culture:</h6>
      <p>Acosta, B. (2011) ‘The Cultural Politics of Israel’s Mirzrachim: From Marginalization to Dominance’. Culture Critique, 2(1) [Online] available at:
        <a href="http://ccjournal.cgu.edu">http://ccjournal.cgu.edu</a>.
      </p>
      <p>
        Behar, M. (2008) ‘Mirzrachim, Abstracted: Action, Reflection, and the
        Academization of the Mirzrachi Cause’ in Journal of Palestine Studies 37(2), pp. 89-100.
      </p>
      <p>
        Stillman, N. [1991] (2003) The Jews of Arab Lands in Modern Times. Philadelphia, PA: Jewish Publication Society of America.
      </p>
      <p>
        (1979) The Jews of Arab Lands: Philadelphia, PA: Jewish Publication Society of America
      </p>
    </div>
  </div>
</div>
