import { Injectable } from '@angular/core';
import {WaiSettingsFacadeService} from "./wai-settings-facade.service";
import {Settings} from "../../model/Settings";

@Injectable({
  providedIn: 'root'
})
export class WaiSettingsService {

  settings!: Settings;
  constructor(private settingsModel: WaiSettingsFacadeService) { }

  getCursorClass() {
    let settings = this.getSettings();
    let cursorClass = 'default-pointer';
    switch (settings.arrowOption){
      case 'ARROW_OPTION_BLACK':
        cursorClass = 'custom-pointer-black';
        break;
      case'ARROW_OPTION_WHITE':
        cursorClass = 'custom-pointer-white';
        break;
      case 'NOT_SET':
        break;
    }
    return cursorClass;
  }

  getSettings() {
    this.settings = this.settingsModel.getSettings();
    return this.settings
  }

  resetSettings(): Settings {
    this.settings = this.settingsModel.getDefaultSettings();
    this.setSettings(this.settings);
    return this.settings;
  }

  setSettings(settings: Settings) {
    this.settings = settings;
    this.settingsModel.setSettings(this.settings);
  }
}
