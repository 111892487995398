import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WaiMenuComponent } from "../../wai-menu/wai-menu.component";
import { ApplicationStateService } from "../../../service/application-state.service";
import { Subscription } from "rxjs";
import { Settings } from "../../../model/Settings";
import { WaiSettingsService } from "../../wai-menu/wai-settings.service";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit, AfterViewInit, OnDestroy {

  public routeUrl: string | undefined;
  public settings: Settings;
  private _subscription_route: Subscription;

  @Input() lang!: string;
  @Input() showMyNusach!: boolean;
  @ViewChild(WaiMenuComponent) waiMenu: WaiMenuComponent | undefined;
  constructor(private appContext: ApplicationStateService,
              private settingsService: WaiSettingsService) {
    this.settings = settingsService.getSettings();
    this._subscription_route = this.appContext.execRouteChange.subscribe((value) => {
      this.routeUrl = value;
      console.log(value);
    });

  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {

  }

  showSettings() {
    if(this.waiMenu){
      this.waiMenu.showSettings();
    }
  }

  ngOnDestroy(): void {
    this._subscription_route.unsubscribe();
  }
}
