import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class AovModel {

  _AllAovBaseList: any;
  _aovMap: any;
  constructor() {
  }

  getAllAovBaseList() {
    return this._AllAovBaseList;
  }

  getAovMap() {
    return this._aovMap;
  }

  setAllAovBaseList(allAovBaseList: any) {
    this._AllAovBaseList = allAovBaseList;
  }

  setAovMap(map: any) {
    this._aovMap = map;
  }
}
