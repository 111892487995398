import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {WaiSettingsService} from "./wai-settings.service";
import {Settings} from "../../model/Settings";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-wai-menu',
  templateUrl: './wai-menu.component.html',
  styleUrls: ['./wai-menu.component.css']
})
export class WaiMenuComponent implements OnInit, AfterViewInit {

  body: any;
  relativeFontSize: number;
  settings: Settings;
  styleObject: {[key: string]: string} = {};

  @ViewChild("accessibilitySettings") settingsElement: ElementRef | undefined;
  @ViewChild("button") button: ElementRef | undefined;
  @ViewChild("passepartout") passepartout: ElementRef | undefined;
  constructor(@Inject(DOCUMENT) private document: Document,
              private el: ElementRef,
              private settingsService: WaiSettingsService) {
    this.relativeFontSize = 100;
    this.settings = settingsService.getSettings();
  }

  ngOnInit(): void {
    this.setMenuItemActive();
    this.setSettings(this.settings);
  }

  ngAfterViewInit(): void {

  }

  getMenuStyles(item: string) {
    let selectedStyle = {backgroundColor: 'rgba(18,18,18,0.7)', color:'#fff', fill: '#fff'};
    let defaultStyle = {};
    switch(item)
    {
      case 'HIGHLIGHT':
        if(this.settings.isHighlightLinks){
          this.styleObject = selectedStyle;
        }
        else {
          this.styleObject = defaultStyle;
        }
        break;
      case 'GREYSCALE':
        if(this.settings.isGreyscale){
          this.styleObject = selectedStyle;
        }
        else {
          this.styleObject = defaultStyle;
        }
        break;
      case 'BG_BLACK':
        if(this.settings.backgroundColorOption === 'BACKGROUND_OPTION_BLACK'){
          this.styleObject = selectedStyle;
        }
        else {
          this.styleObject = defaultStyle;
        }
        break;
      case 'BG_WHITE':
        if(this.settings.backgroundColorOption === 'BACKGROUND_OPTION_WHITE'){
          this.styleObject = selectedStyle;
        }
        else {
          this.styleObject = defaultStyle;
        }
        break;
      case 'ARROW_BLACK':
        if(this.settings.arrowOption === 'ARROW_OPTION_BLACK'){
          this.styleObject = selectedStyle;
        }
        else {
          this.styleObject = defaultStyle;
        }
        break;
      case 'ARROW_WHITE':
        if(this.settings.arrowOption === 'ARROW_OPTION_WHITE'){
          this.styleObject = selectedStyle;
        }
        else {
          this.styleObject = defaultStyle;
        }
        break;
    }
    return this.styleObject;
  }

  greyScaleColors() {
    this.settings = this.settingsService.getSettings();
    this.settings.isGreyscale = !this.settings.isGreyscale;
    this.setSettings(this.settings);
    this.settingsService.setSettings(this.settings);
  }

  highLightLinks() {
    this.settings.isHighlightLinks = true;
    this.setSettings(this.settings);
    this.settingsService.setSettings(this.settings);
  }

  resetArrow() {
    this.document.body.classList.remove('custom-cursor-black');
    this.document.body.classList.remove('custom-cursor-white');
    //$('a').removeClass('custom-pointer-black custom-pointer-white');
    let pointerElements = this.document.body.querySelectorAll('a, button, textarea, input, input.input, fieldset.label, div.square, div.b-btn, div.col, div.search-btn, .tab');
    pointerElements.forEach((el: any) => {
      el.classList.remove('custom-pointer-black');
      el.classList.remove('custom-pointer-white');
      el.classList.add('default-pointer');
    })
  }

  resetSettings() {
    //mapBox.switchMapLayer('LIGHT');
    this.settings = this.settingsService.resetSettings();
    this.relativeFontSize = this.settings.relativeFontSize;
    this.setSettings(this.settings);
  }

  scaleFont(size: number) {
    this.document.body.style.setProperty('font-size', size + '%');
  }

  scaleDownFont() {
    if(70 <= this.relativeFontSize){
      this.relativeFontSize = this.relativeFontSize - 10;
      this.settings.relativeFontSize = this.relativeFontSize;
      this.setSettings(this.settings);
      this.settingsService.setSettings(this.settings);
    }
  }

  scaleUpFont(){
    if(130 > this.relativeFontSize) {
      this.relativeFontSize = this.relativeFontSize + 10;
      this.settings.relativeFontSize = this.relativeFontSize;
      this.setSettings(this.settings);
      this.settingsService.setSettings(this.settings);
    }
  }

  setArrowOption(settings: Settings) {
    let reloadedSettings = this.settingsService.getSettings();
    if(settings.arrowOption === reloadedSettings.arrowOption){
      this.resetArrow();
    }
    let pointerElements = this.document.body.querySelectorAll('a, button, textarea, input, input.input, fieldset.label, div.square, div.b-btn, div.col, div.search-btn, .tab');
    switch (settings.arrowOption){
      case 'ARROW_OPTION_WHITE':
        this.document.body.classList.remove('custom-cursor-black');
        this.document.body.classList.add('custom-cursor-white');
        pointerElements.forEach((el: any) => {
          el.classList.add('custom-pointer-white');
        });

        break;
      case 'ARROW_OPTION_BLACK':
        this.document.body.classList.remove('custom-cursor-white');
        this.document.body.classList.add('custom-cursor-black');
        pointerElements.forEach((el: any) => {
          el.classList.add('custom-pointer-black');
        });
        break;
      case 'NOT_SET':
        this.resetArrow();
        break;
    }
  }

  setBigBlackArrow() {
    let reloadedSettings = this.settingsService.getSettings();
    if(reloadedSettings.arrowOption !== 'ARROW_OPTION_BLACK'){
      this.settings.arrowOption = 'ARROW_OPTION_BLACK';
      this.setSettings(this.settings);
      this.settingsService.setSettings(this.settings);
    }
  }

  setBigWhiteArrow() {
    let reloadedSettings = this.settingsService.getSettings();
    if(reloadedSettings.arrowOption !== 'ARROW_OPTION_WHITE'){
      this.settings.arrowOption = 'ARROW_OPTION_WHITE';
      this.setSettings(this.settings);
      this.settingsService.setSettings(this.settings);
    }
  }

  setBlackBackground() {
    //$rootScope.currentTheme = 'Grey';
    //mapBox.switchMapLayer('DARK');
    let reloadedSettings = this.settingsService.getSettings();
    if(reloadedSettings.backgroundColorOption !== 'BACKGROUND_OPTION_BLACK'){
      this.settings.backgroundColorOption = 'BACKGROUND_OPTION_BLACK';
      this.setSettings(this.settings);
      this.settingsService.setSettings(this.settings);
    }
  }

  setSettings(settings: Settings) {

    // may I scale fonts
    if(settings.relativeFontSize){
      this.scaleFont(settings.relativeFontSize);
    }
    this.setArrowOption(settings);
    //let body = $(' body ');
    let passepartout =  this.document.body.querySelector('#passepartout');
    let indexComponentList = this.document.body.querySelector('#index-component-list');


    let themeDark = 'theme-color-dark';
    let themeLight = 'theme-color-light';
    let svgButtonBgDark = 'site-bg-dark-svg-btn';
    //let svgButton = this.el.nativeElement.querySelector('.svg-btn');
    let svgButton = this.document.body.querySelector('.svg-btn');
    let optionGrayScale = 'option-greyscale';
    let optionHighlightLinks = 'option-highlight-all-the-links'

    // greyscale / disable colors
    settings.isGreyscale ? this.document.body.classList.add(optionGrayScale) : this.document.body.classList.remove(optionGrayScale);
    //let logoVW = this.el.nativeElement.querySelector('#logo-stiftung');
    // switch background-color / invert colors
    switch (settings.backgroundColorOption){
      case 'BACKGROUND_OPTION_BLACK':
        // this.el.nativeElement.querySelector('#k').style.setStyle({'color': 'rgba(255,255,255,0.01)', 'text-shadow':'-1px 1px, 1px 1px, 1px 1px rgba(255,255,255,0.04)'});
        // this.el.nativeElement.querySelector('#s').style.setStyle({'color': 'rgba(255,255,255,0.01)', 'text-shadow':'-1px 1px, 1px 1px, 1px 1px rgba(255,255,255,0.04)'});
        this.document.body.classList.remove(themeLight);
        this.document.body.classList.add(themeDark);


        if(passepartout){
          passepartout.classList.remove(themeLight);
          passepartout.classList.add(themeDark);
        }
        if(indexComponentList){
          indexComponentList.classList.remove(themeLight);
          indexComponentList.classList.add(themeDark);
        }
        if(svgButton){
          svgButton.classList.add(svgButtonBgDark);
        }
        break;
      case 'BACKGROUND_OPTION_WHITE':
      case 'NOT_SET':
        // this.el.nativeElement.querySelector('#k').css({'color': 'rgba(0,0,0,0.01)', 'text-shadow':'-1px 1px, 1px 1px, 1px 1px rgba(0,0,0,0.04)'});
        // this.el.nativeElement.querySelector('#s').css({'color': 'rgba(0,0,0,0.01)', 'text-shadow':'-1px 1px, 1px 1px, 1px 1px rgba(0,0,0,0.04)'});
        this.document.body.classList.remove(themeDark)
        this.document.body.classList.add(themeLight);

        if(passepartout){
          passepartout.classList.remove(themeDark)
          passepartout.classList.add(themeLight);
        }
        if(indexComponentList){
          indexComponentList.classList.remove(themeDark);
          indexComponentList.classList.add(themeLight);
        }
        if(svgButton){
          svgButton.classList.remove(svgButtonBgDark);
        }
        break;
      default:
        break;
    }

    settings.isHighlightLinks ? this.document.body.classList.add(optionHighlightLinks) : this.document.body.classList.remove(optionHighlightLinks);
    this.settingsService.setSettings(settings);
  }

  showSettings() {
    if(this.settingsElement) {
      this.settingsElement.nativeElement.style.setProperty('display', this.settingsElement.nativeElement.style.display === 'none' ? '' : 'none');
    }
  }

  setWhiteBackground() {
    //mapBox.switchMapLayer('LIGHT');
    let reloadedSettings = this.settingsService.getSettings();
    if(reloadedSettings.backgroundColorOption !== 'BACKGROUND_OPTION_WHITE'){
      this.settings.backgroundColorOption = 'BACKGROUND_OPTION_WHITE';
      this.setSettings(this.settings);
      this.settingsService.setSettings(this.settings);
    }
  }

  setMenuItemActive() {
      // let menuItemList = this.el.nativeElement.querySelector('side-menu-item');
      // for (let i = 0; i < menuItemList.length; i++) {
      //   menuItemList[i].setAttribute('active', false);
      // }
  }


}
