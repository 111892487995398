import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {WaiSettingsService} from "../../../components/wai-menu/wai-settings.service";
import {TranslocoService} from "@ngneat/transloco";
import {MynSearchModel} from "../../../service/myn/myn-search-model";

@Component({
  selector: 'app-myn',
  templateUrl: './myn.component.html',
  styleUrls: ['./myn.component.css']
})
export class MynComponent implements OnInit {
  tabContentID: string | undefined;
  tabID: string | undefined;
  tabHeader: string | undefined;
  searchResultList: any[] =[];
  loadFullContainerIDList: string[] = []
  setSearchResult: Function;
  isLang: Function;
  constructor(
    public settingsService: WaiSettingsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private searchModel: MynSearchModel,
    private translocoService: TranslocoService
  ) {
    this.isLang = (code: string) => {
      let lang = this.translocoService.getActiveLang();
      return lang === code;
    };
    this.setSearchResult = (response: any) => {
      this._setSearchResult(response);
    }
  }

  ngOnInit(): void {
    let tabId = this.activatedRoute.snapshot.queryParamMap.get('tab');
    if(tabId){
      this.tabContentID = tabId + '-tab-content';
      this.tabID = this.tabContentID.split('-')[0];
      this.tabHeader = this.tabID.toUpperCase();
    }
  }

  showTab(tabContentId:string) {
    this.tabContentID = tabContentId;
    this.tabID = tabContentId.split('-')[0];
    this.tabHeader = this.tabID.toUpperCase();
    this.settingsService.setSettings(this.settingsService.getSettings());
  }

  _setSearchResult(response: any) {
    if(!response) return;
    this.searchResultList = response;
    for(let i = 0; i < this.searchResultList.length; i++){
      let searchResult = this.searchResultList[i];
      let tempMap = searchResult.highlightedFieldMap;
      searchResult.highlightMap = [];
      for (let key in tempMap) {
        if(key.includes('composition')){
          this.loadFullContainerIDList.push(searchResult.identifier);
        }
        if (tempMap.hasOwnProperty(key)) {
          let tempNameArray = key.split('.');
          let name = tempNameArray[tempNameArray.length - 1];
          name = name.toUpperCase();
          searchResult.highlightMap.push({'name': name, 'valueList': tempMap[key]});
        }
      }
    }
    this.searchModel.setMynSearchResultList(this.searchResultList);
    this.searchModel.setLoadFullContainerIdList(this.loadFullContainerIDList);
    this.router.navigate(['myn-search-result']);
  }
}
