import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class MynDetailViewmodel {

  _selectedMynContainer: any = null;
  _selectedTab: any = null;

  getSelectedMynContainer() {
    return this._selectedMynContainer;
  }

  getSelectedTab() {
    return this._selectedTab;
  }

  setSelectedMynContainer(mynContainer: any) {
    this._selectedMynContainer = mynContainer;
  }

  setSelectedTab(tab: string) {
    this._selectedTab = tab;
  }
}
