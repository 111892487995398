import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData} from '@angular/common';
import { HttpClientModule } from "@angular/common/http";
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatButtonModule } from "@angular/material/button";
import { TranslocoRootModule } from './transloco-root.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from "./app-routing.module";
import { OrderByPipe } from "./util/order-by.pipe";

import { MenuBarComponent } from "./components/menu-bar/menu-bar.component";
import { TopbarComponent } from './components/passepartout/topbar/topbar.component';
import { LeftSidebarComponent } from './components/passepartout/left-sidebar/left-sidebar.component';
import { RightSidebarComponent } from './components/passepartout/right-sidebar/right-sidebar.component';
import { FooterComponent } from './components/passepartout/footer/footer.component';
import { WaiMenuComponent } from './components/wai-menu/wai-menu.component';
import { PassepartoutComponent } from "./components/passepartout/passepartout.component";

import { IntroComponent } from './pages/static/intro/intro.component';
import { LandingComponent } from './pages/static/landing/landing.component';
import { ContactComponent } from './components/contact/contact.component';
import { WelcomeComponent } from './pages/static/welcome/welcome.component';
import { TraditionComponent } from './pages/static/tradition/tradition.component';
import { ImprintComponent } from './pages/static/imprint/imprint.component';
import { HowtoComponent } from './pages/static/howto/howto.component';
import { CarryOnTraditionComponent } from './pages/static/carry-on-tradition/carry-on-tradition.component';

import { EncyclopediaComponent } from "./pages/static/encyclopedia/encyclopedia.component";
import { SephardimComponent } from "./pages/static/encyclopedia/article/sephardim/sephardim.component";
import { AshkenazimComponent } from "./pages/static/encyclopedia/article/ashkenazim/ashkenazim.component";
import { LiberalComponent } from "./pages/static/encyclopedia/article/liberal/liberal.component";
import { FeministComponent } from "./pages/static/encyclopedia/article/feminist/feminist.component";
import { MizrahimComponent } from "./pages/static/encyclopedia/article/mizrahim/mizrahim.component";
import { OrthodoxComponent } from "./pages/static/encyclopedia/article/orthodox/orthodox.component";
import { RomaniotesComponent } from "./pages/static/encyclopedia/article/romaniotes/romaniotes.component";

import { NotEmptyOrNullPipe } from "./util/not-emty-or-null.pipe";
import { AovDetailComponent } from "./pages/aov/aov-detail/aov-detail.component";
import { AovComponent } from "./pages/aov/aov-master/aov.component";
import { AovIndexComponent } from "./pages/aov/aov-index/aov-index.component";

import { AovSimpleSearchComponent } from "./pages/aov/simplesearch/simplesearch.component";
import { AovExpertSearchComponent } from "./pages/aov/expertsearch/expertsearch.component";
import { AovSearchResultComponent } from "./pages/aov/search-result/search-result.component";
import { ArticleComponent } from "./pages/static/encyclopedia/article/article.component";
import { AovLandingComponent } from "./pages/aov/aov-landing/aov-landing.component";
import { TermsComponent } from "./pages/static/terms/terms.component";
import {MynSimpleSearchComponent} from "./pages/myn/simplesearch/simplesearch.component";
import {MynSearchResultComponent} from "./pages/myn/search-result/search-result.component";
import {MynExpertSearchComponent} from "./pages/myn/expertsearch/expertsearch.component";
import {MynIndexComponent} from "./pages/myn/myn-index/myn-index.component";
import {MynLandingComponent} from "./pages/myn/myn-landing/myn-landing.component";
import {MynComponent} from "./pages/myn/myn-master/myn.component";
import {MynDetailComponent} from "./pages/myn/myn-detail/myn-detail.component";


registerLocaleData(localeDe, localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    IntroComponent,
    LandingComponent,
    ContactComponent,
    TermsComponent,
    TopbarComponent,
    LeftSidebarComponent,
    RightSidebarComponent,
    FooterComponent,
    WaiMenuComponent,
    WelcomeComponent,
    TraditionComponent,
    ImprintComponent,
    HowtoComponent,
    CarryOnTraditionComponent,
    OrderByPipe,
    NotEmptyOrNullPipe,
    EncyclopediaComponent,
    SephardimComponent,
    AshkenazimComponent,
    LiberalComponent,
    FeministComponent,
    MizrahimComponent,
    OrthodoxComponent,
    RomaniotesComponent,
    AovComponent,
    AovIndexComponent,
    AovDetailComponent,
    AovLandingComponent,
    AovSimpleSearchComponent,
    AovExpertSearchComponent,
    AovSearchResultComponent,
    PassepartoutComponent,
    ArticleComponent,
    LandingComponent,
    MynSimpleSearchComponent,
    MynSearchResultComponent,
    MynExpertSearchComponent,
    MynIndexComponent,
    MynLandingComponent,
    MynComponent,
    MynDetailComponent
  ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        MatIconModule,
        MatListModule,
        MatButtonModule,
        MenuBarComponent,
        TranslocoRootModule
    ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
export const addressLine: ValidatorFn = Validators.pattern('(([0-9]{1,}).(.*[a-zA-Z#/&]){2,}$)|(([RRHC]{2,}).[0-9]{1,})|(([0-9]{1,}).(.*[a-zA-Z#/&]){2,}.(.*[0-9#])$)');
export const date: ValidatorFn = Validators.pattern('((0|1)d{1})((0|1|2|3)d{1})((19|20)d{2})');
