<div id="start_view" class="start_view">
  <div id="col-group">
    <div class="col bg-dark-alpha-high" [routerLink]="'welcome'" ng-class="landing.applyCursorClass()">
      <div class="col-footer">
        <div class="col-label-container">
          <span class="col-footer-label">WELCOME</span>
        </div>
        <div class="col-footer-content"  ng-if="landing.isLang('de')">
          <p>
            Soundscape Synagoge bietet Informationen zum musikalischen Erbe jüdischer Gemeinden Europas.
            Hier finden Sie Angaben zu jüdischen KantorInnen der Vergangenheit und Gegenwart,
            <br><br>
            <a class="landing-link" href [routerLink]="'welcome'">Weiterlesen</a>
          </p>
        </div>
        <div class="col-footer-content" ng-if="landing.isLang('en')">
          <p>
            "Soundscape Synagogue provides information on the musical heritage of Jewish communities in Europe.
            Here you will find knowledge on Jewish cantors of the past and present,
            <br><br><a class="landing-link" [routerLink]="'welcome'">Continue</a>
          </p>
        </div>
      </div>
    </div>
    <div class="col bg-dark-alpha-high" ui-sref="howto" ng-class="landing.applyCursorClass()">
      <div class="col-footer">
        <div class="col-label-container">
          <span class="col-footer-label">HOWTOUSE</span>
        </div>
        <div class="col-footer-content" ng-if="landing.isLang('de')">
          <!--<h2 class="col-footer-branding light-green">Wie benutze ich Soundscape Synagogue?</h2>-->
          <p>Sie können diese Internetseite auf verschiedene Weisen nutzen.
            Neben der Suchfunktion stehen ein ein alphabetisches Personenverzeichnis und eine Enzyklopädie zur Verfügung.
            <br><br><a class="landing-link" href ui-sref="howto">Weiterlesen</a></p>
        </div>
        <div class="col-footer-content" ng-if="landing.isLang('en')">
          <!--<h2 class="col-footer-branding light-green">Soundscape Synagoge</h2>-->
          <p>You can use this website in various ways. In addition to the search function, an alphabetical list of persons and an encyclopaedia are available.
            <br><br><a class="landing-link" href ui-sref="howto">Continue</a>
          </p>
        </div>
      </div>
    </div>
    <div class="col bg-dark-alpha-high" ui-sref="tradition" ng-class="landing.applyCursorClass()">
      <div class="col-footer">
        <div class="col-label-container">
          <span class="col-footer-label">CARRY_ON_TRADITION</span>
        </div>

        <div class="col-footer-content" ng-if="landing.isLang('de')">
          <!--<h2 class="col-footer-branding light-green">Carry on Tradition</h2>-->
          <p>
            Bitte helfen Sie uns mit weiteren Informationen und Materialien für Soundscape Synagoge:
            Besitzen Sie materielle oder immaterielle Zeugnisse von jüdisch-liturgischer/synagogaler Musik
            <br><br><a class="landing-link" href ui-sref="howto">Weiterlesen</a>
          </p>
        </div>
        <!--<span class="col-footer-label">Carry on Tradition</span>-->
        <div class="col-footer-content" ng-if="landing.isLang('en')">
          <!--<h2 class="col-footer-branding light-green">Carry on Tradition</h2>-->
          <p>Please help us with further information and materials for Soundscape Synagogue:
            Do you have material or immaterial testimonies of Jewish-liturgical/synagogical music
            <br><br><a class="landing-link" href ui-sref="howto">Continue</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="clear"></div>
</div>
