<div id="footer" class="always-on-top">
  <div id="footer-nav-left">
    <div class="link-box-bottom">
      <a class="brand-link brand-link-magenta"
         target="_blank"
         rel="noopener noreferrer"
         href="https://www.hmtm-hannover.de"
         title="https://www.hmtm-hannover.de">HMTMH
      </a>
    </div>
    <div class="link-box-bottom">
      <a class="brand-link brand-link-magenta"
         target="_blank"
         rel="noopener noreferrer"
         href="https://www.ezjm.hmtm-hannover.de"
         title="https://www.ezjm.hmtm-hannover.de">EZJM
      </a>
    </div>
  </div>
  <div id="footer-nav-right">
    <div class="link-box link-box-bottom">
      <a id="terms"
         (click)="setSelectedLink('terms')"
         [ngClass]="linkName === 'terms' ? 'b-btn active' : 'b-btn'"
         [routerLink]="['/terms']">{{ 'TERMS' | transloco }}
      </a>
    </div>
    <div class="link-box link-box-bottom">
      <a id="tradition"
         (click)="setSelectedLink('tradition')"
         [ngClass]="linkName === 'tradition' ? 'b-btn active' : 'b-btn'"
         [routerLink]="['/encyclopedia']">{{ 'RESOURCES' | transloco }}
      </a>
    </div>
    <div class="link-box link-box-bottom">
      <a id="help" (click)="setSelectedLink('help')"
         [ngClass]="linkName === 'help' ? 'b-btn active' : 'b-btn'"
         [routerLink]="'/howto'">{{ 'HELP' | transloco }}
      </a>
    </div>
    <div class="link-box link-box-bottom">
      <a id="impressum"
         (click)="setSelectedLink('imprint')"
         [ngClass]="linkName === 'imprint' ? 'b-btn active' : 'b-btn'"
         [routerLink]="'/imprint'">{{ 'IMPRINT' | transloco }}
      </a>
    </div>
  </div>
</div>

