import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ApplicationStateService } from "../../../service/application-state.service";
import { AovSearchModel } from "../../../service/aov/aov-search-model";
import { AovService } from "../../../service/aov/aov-service";

@Component({
  selector: 'aov-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css']
})
export class AovSearchResultComponent implements OnInit {

  prevUrl: string = '/aov';
  param: any;
  aovSearchResultList: any;
  hasFilter: boolean = false;
  operator: any;
  loadContainerIdList: string[] = [];
  containerList: any[] = [];
  constructor(private searchService: AovService,
              private searchModel: AovSearchModel,
              private router: Router,
              private appCtx: ApplicationStateService) { }

  ngOnInit(): void {
    let that = this;
    const url = this.appCtx.getPreviousUrl();
    if(url){
      this.prevUrl = url;
    }
    this.loadContainerIdList = this.searchModel.getLoadFullContainerIdList();
    if(this.loadContainerIdList.length > 0) {
      this.searchService.getAovContainerListViaIdentifierList(this.loadContainerIdList).subscribe(
          (data: any) => {
            this.containerList = data;
          },
          error => {
            console.log(error);
          }
      );
    }

    let searchParameter = this.searchModel.getSearchParameter();
    this.operator = searchParameter?.searchOperatorValue;
    if(searchParameter){
      this.param = searchParameter;
      if(this.param.filterMap){
          Object.keys(that.param.filterMap).map(function (key) {
            if (that.param.filterMap[key].length > 0) {
              that.hasFilter = true;
            }
          });
      }
    }
    this.aovSearchResultList = this.searchModel.getAovSearchResultList();
  }

  back(){
    window.history.back();
    //this.router.navigateByUrl(this.prevUrl);
  }


  getCompositionIndexViaTextSearch(aovSearchResult: any, valueString: string) {
    let resultList: number[] = [];
    const textMatchList = aovSearchResult.highlightMap.filter((x: any) => x.name === valueString);
    const idx = this.containerList.findIndex( (item) => item.identifier === aovSearchResult.identifier);
    if(null != idx && textMatchList.length > 0) {
      const aovContainer = this.containerList[idx];
      const compositionList = aovContainer.compositionList;
      for(let k = 0; k < compositionList.length; k++) {
        const compo = compositionList[k];
        const compositionIndex = k;
        textMatchList[0].valueList.forEach( (v: string) => {
          const searchString = v.replace('<em>', '').replace('</em>', '');
          const searchItem = compo[valueString.toLowerCase()];
          if(null != searchItem && searchItem.includes(searchString)){
            resultList.push(k);
          }
        })
      }
    }
    return resultList;
  }

  getTabNavigation(valueString: string, aovSearchResult: any): any {
    let result: any;
    for (let i = 0; i < this.searchModel.searchFieldList.length; i++){
      let tab = this.searchModel.searchFieldList[i];
      for (let j = 0; j < tab.children.length; j++){
        if(tab.children[j] === valueString)
        {
          if(tab.title === 'COMPOSITION')
          {
            let foundCompositonIdxList = this.getCompositionIndexViaTextSearch(aovSearchResult, valueString);
            let idx = 0;
            if(foundCompositonIdxList.length > 0) {
              idx = foundCompositonIdxList[0];
            }
            const idValue =  valueString + '-' + idx;
            result = { tab: this.searchModel.tabDict[tab.title], fragment: idValue };
            break;
          }
          else
          {
            result = { tab: this.searchModel.tabDict[tab.title], fragment: valueString };
            break;
          }
        }
      }
    }
    return result;
  }

  open(aovSearchResult: any){
    this.router.navigate(['aov-detail'], { queryParams :{identifier: aovSearchResult.identifier, tab:'masterdata' }});
  }

  putIntoBasket(personBase: any) {
    this.searchService.putIntoBasket(personBase.identifier);
  }

  openTabViaProperty(aovSearchResult: any, valueString: any) {
    const tabNavigation = this.getTabNavigation(valueString, aovSearchResult);
    if(null !== tabNavigation){
      let queryParams: any = {
        identifier: aovSearchResult.identifier,
        tab: tabNavigation.tab
      }
      if(this.param.firstSearchInputValue){
        queryParams['s1'] = this.param.firstSearchInputValue;
      }
      if(this.param.secondSearchInputValue){
        queryParams['s2'] = this.param.secondSearchInputValue;
      }
      let extras= { queryParams, fragment: tabNavigation.fragment };
      this.router.navigate(['aov-detail'], extras );
    }
  }
}
