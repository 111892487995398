import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carry-on-tradition',
  templateUrl: './carry-on-tradition.component.html',
  styleUrls: ['./carry-on-tradition.component.css']
})
export class CarryOnTraditionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
