import {Component, Input, OnInit} from '@angular/core';
import {TranslocoService} from "@ngneat/transloco";
import {ActivatedRoute, Params, Router} from "@angular/router";

@Component({
  selector: 'app-ecyclopedia',
  templateUrl: './encyclopedia.component.html',
  styleUrls: ['./encyclopedia.component.css']
})
export class EncyclopediaComponent implements OnInit {
  isLang!: Function;
  selectedLanguage:string;
  constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private translocoService: TranslocoService
  ) {
    this.selectedLanguage = this.translocoService.getActiveLang();
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const parameter = params['lang'];
      if (!parameter && this.selectedLanguage) {
        const queryParams: any = {};
        queryParams['lang'] = this.selectedLanguage;
        this.router.navigate([], {
          queryParams: queryParams,
          queryParamsHandling: 'merge'
        });
      }
    });

    this.isLang = (code: string) => {
      let lang = this.translocoService.getActiveLang();
      return lang === code;
    };
  }

  ngOnInit(): void {
  }

}
