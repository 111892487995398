import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class AovDetailViewModel {

  _selectedAovContainer: any = null;
  _selectedTab: any = null;

  getSelectedAovContainer() {
    return this._selectedAovContainer;
  }

  getSelectedTab() {
    return this._selectedTab;
  }

  setSelectedAovContainer(aovContainer: any) {
    this._selectedAovContainer = aovContainer;
  }

  setSelectedTab(tab: string) {
    this._selectedTab = tab;
  }
}
