import { Injectable } from "@angular/core";
import { MynSearchParameter } from "../../model/SearchParamter";

@Injectable({
  providedIn: 'root',
})
export class MynSearchModel {

  _allMynContainerList =[];
  _mynContainerList = [];
  _mynList = [];
  _mynMap = [];
  _mynSearchResultList = [];
  _searchParameter : MynSearchParameter|null = null;
  _loadFullContainerIdList: string [] = [];

  mynCantorKey = 'MASTERDATA';
  compositionKey = 'COMPOSITION';

  tabDict: {[index: string]:any} = {
    'MASTERDATA':'masterdata',
    'COMPOSITION':'media',
  }

  _masterDataFieldsArray = [
    'IDENTIFIER',
    'LASTNAME',
    'FIRSTNAME',
    'TITLE',
    'BIRTHDATE',
    // 'DATEOFDEATH',
    'PLACEOFBIRTH',
    'LANGUAGES',
    'NATIONALITY',
    'GENDER',
    'WEBSITE',
    'SOCIALHANDLE',
    'MUSICPUBLICATIONS',
    'WRITTENPUBLICATIONS',
    'MYMUSIC',
    'PHOTOLIST'
  ];
  _compositionFieldsArray = [
    'IDENTIFIER',
    'SONGTITLE',
    'LYRICS',
    'MISC',
    'BACKGROUND',
    'TAGLIST'
  ];

  searchFieldList = [
    {
      id: 1,
      title: 'MASTERDATA',
      checked: false,
      children:this._masterDataFieldsArray
    }, {
      id: 3,
      title: 'COMPOSITION',
      checked: false,
      children:this._compositionFieldsArray
    }
  ];

  _searchFieldDict: {[index: string]:any} = {
    MASTERDATA: this._masterDataFieldsArray,
    COMPOSITION: this._compositionFieldsArray,
  };

  constructor() {
  }

  getSearchFieldListViaKey(key: string): string[] {
    return this._searchFieldDict[key];
  }

  getAllMynContainerList() {
    return this._allMynContainerList;
  }

  getMynContainerList() {
    return this._mynContainerList;
  }

  getMynList() {
    return this._mynList;
  }

  getMynMap() {
    return this._mynMap;
  }

  getMynMapViaKey(key: any) {
    return this._mynMap[key];
  }

  getMynSearchResultList() {
    return this._mynSearchResultList;
  }

  getSearchParameter(): MynSearchParameter|null {
    return this._searchParameter;
  }

  getLoadFullContainerIdList(): string[] {
    return this._loadFullContainerIdList;
  }

  setAllMynContainerList(mynContainerList: any){
    this._allMynContainerList = mynContainerList;
  }

  setMynContainerList(mynContainerList: any) {
    this._mynContainerList = mynContainerList;
  }

  setMynList(mynList: any) {
    this._mynList = mynList;
  }

  setMynMap(mynMap: any) {
    this._mynMap = mynMap;
  }

  setMynSearchResultList(searchResultList: any) {
    this._mynSearchResultList = searchResultList;
  }

  setSearchParameter(searchParameter: any) {
    this._searchParameter = searchParameter;
  }

  setLoadFullContainerIdList(loadFullContainerIDList: string[]) {
    this._loadFullContainerIdList = loadFullContainerIDList;
  }

}
