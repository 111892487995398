import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-liberal',
  templateUrl: './liberal.component.html',
  styleUrls: ['./liberal.component.css']
})
export class LiberalComponent implements OnInit {
  @Input() isLang!: Function;
  constructor() { }

  ngOnInit(): void {
  }

}
