import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {WaiSettingsService} from "../../../components/wai-menu/wai-settings.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AovService} from "../../../service/aov/aov-service";
import {AovSearchModel} from "../../../service/aov/aov-search-model";
import {environment} from "../../../../environments/environment";
import {Settings} from "../../../model/Settings";

@Component({
  selector: 'aov-index',
  templateUrl: './aov-index.component.html',
  styleUrls: ['./aov-index.component.css']
})
export class AovIndexComponent implements OnInit, OnDestroy {

  @Input() isLang!: Function;

  indexKeys = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J' ,'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  NO_PERSON_KEY = 'NO_NAME';

  allAovMap: any = [];
  aovMap: any[] | undefined;
  aovMapKeyList: any[] = [];
  selectedIndexKey!: string;
  baseUrl!: string;

  settings!: Settings;
  constructor(private searchModel: AovSearchModel,
              private aovService: AovService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public settingsService: WaiSettingsService) {
    this.baseUrl = environment.clientBaseUrl;
    this.selectedIndexKey = this.activatedRoute.snapshot.queryParams['key'];
    this.aovMapKeyList = this.indexKeys.sort();
    this.showAovContainerDict(this.selectedIndexKey);
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit(): void { }

  hasIndexDataForKey(key: string) {
    let result = false;
    if(key && this.allAovMap.hasOwnProperty(key))
    {
      let tempList: any[] = this.allAovMap[key];
      if(null != tempList && 0 < tempList.length){
        result = true;
      }
    }
    return result;
  }

  open(aovContainer: any){
    this.router.navigate(['aov-detail'], { queryParams: { identifier: aovContainer.identifier }, queryParamsHandling:"merge"});
  }

  putIntoBasket(identifier: string) {
    this.aovService.putIntoBasket(identifier);
  }

  showIndexAt(dictKey: string) {
    this.router.navigate(['aov'], { queryParams: { tab: 'aovidx', key: dictKey }, queryParamsHandling:"merge"}, );
  }

  showAovContainerDict(key: string | null) {
    let that = this;
    let personMap = this.searchModel.getAovMap();
    if(key){
      if(personMap && Object.keys(personMap).length > 0) {
        this._aovMapHandler(personMap, key);
      }
      else {
        this.aovService.getAllAovBaseList(function (map: any) {
          that._aovMapHandler(map, key);
        });
      }
    }
  }

  showAovMapContentViaKey(key: string) {
    this.selectedIndexKey = key;
    this.aovMap = [];
    let tempMap: [] = this.searchModel.getAovMapViaKey(key);
    if(tempMap){
      this.aovMap = tempMap.sort(this._sortAovMap);
    }
  }


  _sortAovMap(a: any, b: any) {
    if (a.aovBase.lastName < b.aovBase.lastName) {
      return -1;
    }
    if (a.aovBase.lastName > b.aovBase.lastName) {
      return 1;
    }
    return 0;
  }

  _aovMapHandler(aovMap: any, key: string) {
    this.searchModel.setAovMap(aovMap);
    this.allAovMap = this.searchModel.getAovMap();
    let tempMap = this.allAovMap;
    if(!key){
      this.selectedIndexKey = this.aovMapKeyList[0];
    }
    else{
      this.selectedIndexKey = key.toUpperCase();
    }

    if(this.selectedIndexKey){
      let tempMapSelection: any[] = tempMap[this.selectedIndexKey];
      if(null != tempMapSelection && tempMapSelection.length > 0){
        this.aovMap = tempMapSelection.sort(this._sortAovMap);
      }
    }
    this.settings = this.settingsService.getSettings();
    this.settingsService.setSettings(this.settings);
  }

  ngOnDestroy(): void {
    //this.routeChangeSubscription.unsubscribe();
  }
}
