import {Injectable} from "@angular/core";
import {AovSearchParameter, SearchParameter} from "../../model/SearchParamter";

@Injectable({
  providedIn: 'root',
})
export class AovSearchModel {

  _allAovContainerList =[];
  _aovContainerList = [];
  _aovList = [];
  _aovMap = [];
  _aovSearchResultList = [];
  _searchParameter : AovSearchParameter|null = null;
  _loadFullContainerIdList: string [] = [];

  aovBaseKey = 'MASTERDATA';
  bioKey = 'BIO';
  compositionKey = 'COMPOSITION';
  infoKey = 'INFO';

  tabDict: {[index: string]:any} = {
    'MASTERDATA':'masterdata',
    'BIO':'bio',
    'COMPOSITION':'media',
    'INFO':'info'
  }

  _masterDataFieldsArray = [
    'IDENTIFIER',
    'LASTNAME',
    'FIRSTNAME',
    'TITLELIST',
    'BIRTHDATE',
    // 'DATEOFDEATH',
    'PLACEOFBIRTH',
    'NATIVELANGUAGE',
    'OTHERLANGUAGES',
    'NATIONALITY',
    'GENDER',
    'PRONOUNS',
    'BIO',
    'PHOTOLIST'
  ];
  _bioFieldArray = [
    'ORIENTATIONLIST',
    'ORDINATIONLIST',
    'EDUCATIONLIST',
    'HONORSYEAR',
    'PUBLICATIONSAUTHOREDITOR',
    'ACADEMICAPPOINTMENTS',
    'FOUNDINGS',
    'JEWISHCOMMUNAL',
    'OTHERCAREER'
  ];
  _infoFieldArray = [
    'WEBSITES',
    'MUSICPUBLICATIONS',
    'ORGANIZATIONALMEMBERSHIPS',
    'MUSICALINFLUENCES',
    'ADDITIONALINFLUENCES',
    'MAJORCONCERTSANDAPPEARANCES'
  ];
  _compositionFieldsArray = [
    'IDENTIFIER',
    'SONGTITLE',
    'LYRICS',
    'MISC',
    'BACKGROUND',
    'TAGLIST'
  ];

  searchFieldList = [
    {
      id: 1,
      title: 'MASTERDATA',
      checked: false,
      children:this._masterDataFieldsArray
    },
    {
      id: 2,
      title: 'BIO',
      checked: false,
      children:this._bioFieldArray

    },{
      id: 3,
      title: 'COMPOSITION',
      checked: false,
      children:this._compositionFieldsArray
    },{
      id: 4,
      title: 'INFO',
      checked: false,
      children:this._infoFieldArray
    }
  ];

  _searchFieldDict: {[index: string]:any} = {
    MASTERDATA: this._masterDataFieldsArray,
    BIO: this._bioFieldArray,
    COMPOSITION: this._compositionFieldsArray,
    INFO: this._infoFieldArray,
  };

  constructor() {
  }

  getSearchFieldListViaKey(key: string): string[] {
    return this._searchFieldDict[key];
  }

  getAllAovContainerList() {
    return this._allAovContainerList;
  }

  getAovContainerList() {
    return this._aovContainerList;
  }

  getAovList() {
    return this._aovList;
  }

  getAovMap() {
    return this._aovMap;
  }

  getAovMapViaKey(key: any) {
    return this._aovMap[key];
  }

  getAovSearchResultList() {
    return this._aovSearchResultList;
  }

  getSearchParameter(): AovSearchParameter|null {
    return this._searchParameter;
  }

  getLoadFullContainerIdList(): string[] {
    return this._loadFullContainerIdList;
  }

  setAllAovContainerList(aovContainerList: any){
    this._allAovContainerList = aovContainerList;
  }

  setAovContainerList(aovContainerList: any) {
    this._aovContainerList = aovContainerList;
  }

  setAovList(aovList: any) {
    this._aovList = aovList;
  }

  setAovMap(aovMap: any) {
    this._aovMap = aovMap;
  }

  setAovSearchResultList(searchResultList: any) {
    this._aovSearchResultList = searchResultList;
  }

  setSearchParameter(searchParameter: any) {
    this._searchParameter = searchParameter;
  }

  setLoadFullContainerIdList(loadFullContainerIDList: string[]) {
    this._loadFullContainerIdList = loadFullContainerIDList;
  }
}
