import { Injectable } from "@angular/core";
import { ServiceClient } from "../service-client";
import { BasketService } from "../basket-service";
import { MynModel } from "./myn-service-model";

@Injectable({
  providedIn: 'root',
})
export class MynService {

  NO_PERSON_KEY = 'NO_NAME';
  AllPersonContainerList: any;
  constructor(private serviceClient: ServiceClient,
              private mynModel: MynModel,
              private basketService: BasketService)
  {

  }

  getAllMynList(_handleGetAllPersonListResult: Function) {
    let that = this;
    this.serviceClient.getAllMynList().subscribe((response: any) => {
        return _handleGetAllPersonListResult(response.data);
    },(response) => {
      return that._handleServiceFault(response);
    });
  }

  getAllMynCantorList(callback: Function) {
    let that = this;
    let tempMap = this.mynModel.getMynMap();
    if(null == tempMap || tempMap.length === 0){
      this.serviceClient.getAllMynCantorList().subscribe((response: any) => {
        let map = that._createMynMap(response);
        callback(map);
      }, (response: any) => {
        that._handleServiceFault(response);
      });
    }
    else {
      callback(tempMap);
    }
  }

  getCached(identifier: string) {
    let personContainer = null;
    let tempPersonContainer = null;
    if(this.AllPersonContainerList) {
      for(let i = 0; i < this.AllPersonContainerList.length; i++){
        tempPersonContainer = this.AllPersonContainerList[i];
        if(tempPersonContainer.person.personBase.identifier === identifier){
          personContainer =  tempPersonContainer;
          break;
        }
      }
    }
    return personContainer;
  }

  getMynContainerViaIdentifier(identifier: string, callback: Function) {
    let that = this;
    let identifierList  = [identifier];
    this._getMynContainerViaIdentifierList(identifierList).subscribe((response: any) => {
      callback(response);
    }, (response: any) => {
      that._handleServiceFault(response);
    });
  }

  getMynContainerListViaIdentifierList(identifierList: string[]) {
    return this._getMynContainerViaIdentifierList(identifierList);
  }

  getPersonMap() {
    return this.mynModel.getMynMap();
  }

  performTextSearch(searchStr: string, _handleTextSearchResult: Function) {
    this.serviceClient.performMynTextSearch(searchStr).subscribe((response: any) => {
      _handleTextSearchResult(response);
    },(response: any) => {
      _handleTextSearchResult(response);
    });
  }

  performSearch(searchParameter: any, _handleSearchResult: Function) {
    this.serviceClient.performMynSearch(searchParameter).subscribe((response: any) => {
      _handleSearchResult(response);
    },(response: any) => {
      _handleSearchResult(response);
    });
  }

  putIntoBasket(aovIdentifier: string) {
    let idList = [];
    idList.push(aovIdentifier);
    this._getMynContainerViaIdentifierList(idList).subscribe((response: any) =>{
      if(response){
        let aovContainer = response[0];
        this.basketService.putIntoBasket(aovContainer, aovIdentifier);
      }
    }, (response) => {
      this._handleServiceFault(response)
    });
  }

  _createMynMap(cantorList: any) {
    let map: any = {};
    let dictKey = '';
    this.mynModel.setAllMynBaseList(cantorList);
    for (let i = 0; i < cantorList.length; i++) {
      let lastName = cantorList[i].lastName;
      dictKey = (typeof lastName!='undefined' && lastName) ? lastName.substr(0, 1).toUpperCase() : this.NO_PERSON_KEY;
      if (0 === map.length || null == map[dictKey]){
        map[dictKey] = [];
      }
      map[dictKey].push({'key': i, 'cantor': cantorList[i]});
    }
    this.mynModel.setMynMap(map);
    return map;
  }

  _getMynContainerViaIdentifierList(identifierList: string[]) {
    return this.serviceClient.getMynContainerViaIdentifierList(identifierList);
  }

  _handleServiceFault(response:any) {
    console.log(response);
  }
}


