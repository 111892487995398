import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {WaiSettingsService} from "../../../components/wai-menu/wai-settings.service";
import {WelcomeComponent} from "../welcome/welcome.component";
import {TranslocoService} from "@ngneat/transloco";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  language: string;
  imageWidth: number | undefined;
  imageHeight: number | undefined;
  imageHorizontalCenterPadding: number | undefined;
  imageBoxWidth: number | undefined;
  imageBoxHeight: number | undefined;
  imageBoxTopMargin: number | undefined;

  @ViewChild(WelcomeComponent) welcome: WelcomeComponent | undefined;
  constructor(private settingsService: WaiSettingsService,
              private translocoService : TranslocoService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.language = this.translocoService.getActiveLang();

    const url = this.router.createUrlTree([], { relativeTo: this.activatedRoute, queryParams: {lang: this.language } }).toString();
    this.router.navigateByUrl(url);
  }

  ngOnInit(): void {
    this.setInitialLandingLayout();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this._setLandingLayout();
  }

  applyCursorClass(){
    return this.settingsService.getCursorClass();
  }

  isLang(code: string) {
    return this.translocoService.getActiveLang() === code;
  }

  openMail() {
    window.location.href = 'mailto:ezjm@hmtm-hannover.de';
  }

  setInitialLandingLayout() {
    this._setLandingLayout();
  }

  _setLandingLayout() {
    let viewPortHeight = window.innerHeight;
    let viewPortWidth =window.innerWidth;
    let imageMaxWidth = viewPortWidth - 84;
    let imageMaxHeight = viewPortHeight - 84;

    if(imageMaxWidth < imageMaxHeight){
      this.imageWidth = imageMaxWidth;
      this.imageHeight = this.imageWidth * 1.5;

      this.imageBoxWidth = imageMaxWidth;
      this.imageBoxHeight = imageMaxWidth * 1.5;
      if(this.imageBoxHeight > viewPortHeight){
        this.imageBoxTopMargin = ((this.imageHeight - viewPortHeight) / 2);
      }
    }
    else {
      this.imageWidth = imageMaxWidth;
      this.imageHeight = imageMaxHeight;
      this.imageHorizontalCenterPadding = 0;

      this.imageBoxWidth = imageMaxWidth;
      this.imageBoxHeight = this.imageBoxWidth * 1.5;
    }
  }

}
