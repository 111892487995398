<div #accessibilitySettings class="accessibility-settings" style="display: none;">
  <div class="cancel-button link-box" (click)="showSettings()">
    <span class="cancel-button-link" >
      <svg class="svg-square" viewBox="0 0 24 24">
        <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" />
      </svg>
    </span>
  </div>
  <div class="grid bg-dark-alpha">
    <div class="row">
      <!--  INCREASE FONT -->
      <div class="square" (click)="scaleUpFont()">
        <div class="square-icon">
          <svg class="svg-square" viewBox="0 0 24 24">
            <path class="" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
          </svg>
        </div>
        INCREASE FONT
      </div>
      <!--  DECREASE FONT -->
      <div class="square" (click)="scaleDownFont()">
        <div class="square-icon">
          <svg style="width:28px;height:28px" viewBox="0 0 24 24">
            <path class="" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
          </svg>
        </div>
        DECREASE FONT</div>
      <!--  HIGHLIGHT LINKS -->
      <div class="square" (click)="highLightLinks()" [ngStyle]="getMenuStyles('HIGHLIGHT')">
        <div class="square-icon">
          <svg class="svg-square" viewBox="0 0 24 24">
            <path class="" [ngStyle]="getMenuStyles('HIGHLIGHT')" d="M10.59,13.41C11,13.8 11,14.44 10.59,14.83C10.2,15.22 9.56,15.22 9.17,14.83C7.22,12.88 7.22,9.71 9.17,7.76V7.76L12.71,4.22C14.66,2.27 17.83,2.27 19.78,4.22C21.73,6.17 21.73,9.34 19.78,11.29L18.29,12.78C18.3,11.96 18.17,11.14 17.89,10.36L18.36,9.88C19.54,8.71 19.54,6.81 18.36,5.64C17.19,4.46 15.29,4.46 14.12,5.64L10.59,9.17C9.41,10.34 9.41,12.24 10.59,13.41M13.41,9.17C13.8,8.78 14.44,8.78 14.83,9.17C16.78,11.12 16.78,14.29 14.83,16.24V16.24L11.29,19.78C9.34,21.73 6.17,21.73 4.22,19.78C2.27,17.83 2.27,14.66 4.22,12.71L5.71,11.22C5.7,12.04 5.83,12.86 6.11,13.65L5.64,14.12C4.46,15.29 4.46,17.19 5.64,18.36C6.81,19.54 8.71,19.54 9.88,18.36L13.41,14.83C14.59,13.66 14.59,11.76 13.41,10.59C13,10.2 13,9.56 13.41,9.17Z" />
          </svg>
        </div>
        HIGHLIGHT LINKS</div>
    </div>
    <div class="row">
      <!--  GRAYSCALE COLORS -->
      <div class="square" (click)="greyScaleColors()" [ngStyle]="getMenuStyles('GREYSCALE')">
        <div class="square-icon">
          <svg style="width:28px;height:28px" viewBox="0 0 24 24">
            <path class="" [ngStyle]="getMenuStyles('GREYSCALE')" [ngStyle]="getMenuStyles('GREYSCALE')" d="M17.12,17.12L12.5,12.5L5.27,5.27L4,6.55L7.32,9.87C6.55,11.32 6,12.79 6,14A6,6 0 0,0 12,20C13.5,20 14.9,19.43 15.96,18.5L18.59,21.13L19.86,19.86L17.12,17.12M18,14C18,10 12,3.2 12,3.2C12,3.2 10.67,4.71 9.27,6.72L17.86,15.31C17.95,14.89 18,14.45 18,14Z" />
          </svg>
        </div>
        GRAYSCALE COLORS</div>
      <!--  BLACK BACKGROUND -->
      <div class="square" (click)="setBlackBackground()" [ngStyle]="getMenuStyles('BG_BLACK')">
        <div class="square-icon">
          <svg style="width:28px;height:28px" viewBox="0 0 24 24">
            <path class="" [ngStyle]="getMenuStyles('BG_BLACK')" d="M21,16H3V4H21M21,2H3C1.89,2 1,2.89 1,4V16A2,2 0 0,0 3,18H10V20H8V22H16V20H14V18H21A2,2 0 0,0 23,16V4C23,2.89 22.1,2 21,2Z" />
          </svg>
        </div>
        BLACK BACKGROUND</div>
      <!--  WHITE BACKGROUND -->
      <div class="square" (click)="setWhiteBackground()" [ngStyle]="getMenuStyles('BG_WHITE')">
        <div class="square-icon">
          <svg style="width:28px;height:28px" viewBox="0 0 24 24">
            <path class="" [ngStyle]="getMenuStyles('BG_WHITE')" d="M21,16H3V4H21M21,2H3C1.89,2 1,2.89 1,4V16A2,2 0 0,0 3,18H10V20H8V22H16V20H14V18H21A2,2 0 0,0 23,16V4C23,2.89 22.1,2 21,2Z" />
          </svg>
        </div>
        WHITE BACKGROUND</div>
    </div>
    <div class="row">
      <!--  BIG BLACK ARROW -->
      <div class="square" (click)="setBigBlackArrow()" [ngStyle]="getMenuStyles('ARROW_BLACK')">
        <div class="square-icon">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path class="" [ngStyle]="getMenuStyles('ARROW_BLACK')" d="M13.64,21.97C13.14,22.21 12.54,22 12.31,21.5L10.13,16.76L7.62,18.78C7.45,18.92 7.24,19 7,19A1,1 0 0,1 6,18V3A1,1 0 0,1 7,2C7.24,2 7.47,2.09 7.64,2.23L7.65,2.22L19.14,11.86C19.57,12.22 19.62,12.85 19.27,13.27C19.12,13.45 18.91,13.57 18.7,13.61L15.54,14.23L17.74,18.96C18,19.46 17.76,20.05 17.26,20.28L13.64,21.97Z" />
          </svg>
        </div>
        BIG BLACK ARROW</div>
      <!--  BIG WHITE ARROW -->
      <div class="square" (click)="setBigWhiteArrow()" [ngStyle]="getMenuStyles('ARROW_WHITE')">
        <div class="square-icon">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path class="" [ngStyle]="getMenuStyles('ARROW_WHITE')" d="M10.07,14.27C10.57,14.03 11.16,14.25 11.4,14.75L13.7,19.74L15.5,18.89L13.19,13.91C12.95,13.41 13.17,12.81 13.67,12.58L13.95,12.5L16.25,12.05L8,5.12V15.9L9.82,14.43L10.07,14.27M13.64,21.97C13.14,22.21 12.54,22 12.31,21.5L10.13,16.76L7.62,18.78C7.45,18.92 7.24,19 7,19A1,1 0 0,1 6,18V3A1,1 0 0,1 7,2C7.24,2 7.47,2.09 7.64,2.23L7.65,2.22L19.14,11.86C19.57,12.22 19.62,12.85 19.27,13.27C19.12,13.45 18.91,13.57 18.7,13.61L15.54,14.23L17.74,18.96C18,19.46 17.76,20.05 17.26,20.28L13.64,21.97Z" />
          </svg>
        </div>
        BIG WHITE ARROW</div>
      <!--  RESET SETTINGS -->
      <div class="square" (click)="resetSettings()">
        <div class="square-icon">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path class="" d="M16.56,5.44L15.11,6.89C16.84,7.94 18,9.83 18,12A6,6 0 0,1 12,18A6,6 0 0,1 6,12C6,9.83 7.16,7.94 8.88,6.88L7.44,5.44C5.36,6.88 4,9.28 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12C20,9.28 18.64,6.88 16.56,5.44M13,3H11V13H13" />
          </svg>
        </div>
        RESET SETTINGS
      </div>
    </div>
  </div>
</div>
